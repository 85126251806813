import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import Layout from "../../components/layout/layout";
import classes from "./submitrequest.module.scss";
import { SocketContext } from "../../contexts/SocketContext";
import { VACATION_SUBMIT_NOTIFICATION } from "../../Events";

const VacationRequests = (props) => {
  const auth = useSelector(state => state.auth);
  const socket = useContext(SocketContext)[0];
  const theme = useSelector(state => state.themeReducer.theme);
  const [events, setEvents] = useState([]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [note, setNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [requestId, setRequestId] = useState("");

  const [reload, setReload] = useState(true);
  const [handleNotification, setHandleNotification] = useState(false);

  const calendar = useRef(null);

  useEffect(() => {
    if (start !== "") {
      if (calendar.current) {
        let calendarApi = calendar.current.getApi();
        //console.log(calendarApi);
        calendarApi.gotoDate(start);
      }
    }
  }, [start])

  useEffect(() => {
    if (handleNotification && requestId !== "") {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          let request = {
            id: requestId,
            start: start,
            end: end,
            note: note
          };
          socket.emit(VACATION_SUBMIT_NOTIFICATION, auth.user, request);
          setStart("");
          setEnd("");
          setNote("");
          setSuccessMessage("Vacation Request Submitted");
          setReload(true);
        }
      }
    }
  }, [socket, auth, handleNotification, requestId])

  useEffect(() => {
    if (reload) {
      axios.get("/api/vacations/")
        .then(response => {
          setEvents(response.data);
          setReload(false);
          setHandleNotification(false);
        })
        .catch(err => {
          //console.log(err);
        });
    }
  }, [reload])

  useEffect(() => {
    if (start.length > 0 && end.length > 0) {
      //console.log("Both Inputs filled");
      //console.log(dayjs(start).valueOf() > dayjs(end).valueOf());
      if (dayjs(start).valueOf() > dayjs(end).valueOf()) {
        setErrorMessage("Invalid Start and End Date");
        return;
      }
      else {
        setErrorMessage("");
        let startDate = dayjs(start).toISOString().replace(/T.*$/, '');
        let endDate = dayjs(end).toISOString().replace(/T.*$/, '');
        let newEvent = {
          _id: "new",
          title: "New Request",
          start: startDate,
          end: endDate,
          backgroundColor: `${theme.accent_color}`,
          textColor: `${theme.accent_text_color}`,
          borderColor: "white"
        };
        let newEvents = [...events];
        let replaceIndex = null;
        newEvents.forEach((event, index) => {
          if (event._id === "new") {
            //console.log(index);
            replaceIndex = index;
          }
        });
        if (replaceIndex) {
          newEvents.splice(replaceIndex, 1, newEvent);
          //console.log(newEvents);
          setEvents(newEvents);
        } else {
          newEvents.push(newEvent);
          //console.log(newEvents);
          setEvents(newEvents);
        }
      }
    } else {
      //console.log("!Both inputs filled");
      // Remove new Event
      let newEvents = [...events];
      newEvents = newEvents.filter((item) => item._id !== "new");
      //console.log(newEvents);
      setEvents(newEvents);
    }
  }, [start, end])

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case 'start':
        setStart(e.target.value);
        setSuccessMessage("");
        break;
      case 'end':
        setEnd(e.target.value);
        setSuccessMessage("");
        break;
      case 'note':
        setNote(e.target.value);
        break;
      default:
        break;
    }
  }

  const submitRequest = () => {
    if ((!start.length > 0 && !end.length > 0) || (dayjs(start).valueOf() > dayjs(end).valueOf())) {
      setErrorMessage("Please Enter Valid Dates");
      return;
    }
    let params = {
      start_date: dayjs(start).toISOString().replace(/T.*$/, ''),
      end_date: dayjs(end).toISOString().replace(/T.*$/, ''),
      note: note
    };
    axios.post("/api/vacations/", params)
      .then(response => {
        //console.log(response);
        setRequestId(response.data._id);
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <Layout loaded={true} showSidebar={true} subscription={true}>

      <div className={`row ${classes.Main}`} style={{ margin: "0px" }}>
        <div className={`col-3 ${classes.RequestForm}`}>
          <h2>Submit New Vacation Request</h2>
          <p>Start Date</p>
          <input id="start" type="date" placeholder="MM/DD/YYYY" onChange={onChange} />
          <p>End Date</p>
          <input id="end" type="date" placeholder="MM/DD/YYYY" onChange={onChange} />
          <p style={{ color: "red" }}>{errorMessage}</p>
          <p>Note</p>
          <textarea id="note" value={note} type="textbox" rows="4" onChange={onChange} />
          <button style={buttonStyle} className={classes.Button} type="button" onClick={submitRequest}>Submit</button>
          <p style={{ color: "green" }}>{successMessage}</p>
        </div>
        <div className={`col-9 ${classes.CalendarContainer}`}>
          <FullCalendar
            ref={calendar}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={events}
            eventColor={`${theme.primary_color}`}
            eventTextColor={`${theme.primary_text_color}`}// alternatively, use the `events` setting to fetch from a feed
            /*
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        </div>
      </div>

    </Layout>
  );
}

export default withRouter(VacationRequests);

import React, { useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from './modal.module.scss';

const AddEmployee = (props) => {
  const { show, setShow, theme } = props;
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  const onSubmit = (e) => {
    const params = {
      email: email
    }
    axios.post('/api/employees/add', params)
      .then(response => {
        //console.log(response.data);
        setEmailMessage("Invitation Successfully Sent");
        setTimeout(() => {
          setShow(false);
          setTimeout(() => {
            setEmailMessage("");
            setEmail("");
          }, 500)
        }, 1000);
      })
      .catch(e => {
        if (e.response.data.details) {  
            if (e.response.data.details[0].message === '"email" must be a valid email') {
              setEmailMessage("Please enter a valid email");
            } else setEmailMessage("Something went wrong!");
        } else setEmailMessage("Something went wrong!");
      });
  }

  const onChange = (e) => {
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value);
        setEmailMessage("");
        break;
      default:
        break;
    }
  }

  const cancel = (e) => {
    setShow(false);
    setTimeout(() => {
      setEmail("");
    }, 500);
  }

  return(
    <Modal
      className={classes.Modal}
      show={show}
    >
    <Modal.Body>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-sm-6 offset-sm-3">
          <h2 style={{ marginTop: "10px" }}>Add a New Employee</h2>
        </div>
        <div className="col-sm-2 offset-sm-1">
          <button className={`${classes.Button}`} style={{backgroundColor: "#8c0000", width: "50px" }} onClick={cancel}>X</button>
        </div>
      </div>
      <input className={`form-control`} id="email" onChange={onChange} value={email} placeholder="Please enter the employee's email" />
      <p style={{ textAlign: "center" }}>{emailMessage}</p>
      <button className={`${classes.Button}`} style={{ backgroundColor: `${theme.primary_color}`, margin: "15px 0px 0px 0px" }} onClick={onSubmit}>Submit</button>
    </Modal.Body>
    </Modal>
  );
}

export default withRouter(AddEmployee);

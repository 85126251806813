import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import classes from "./services.module.scss";
import Fade from "../../../components/layout/fade/fade";

const Services = (props) => {
  const {
    dispatch,
    updateChanges,
    theme,
    onboarding,
    setLoaded
  } = props;
  const [type, setType] =  useState("social");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`
  };

  const [social, setSocial] = useState(false);
  const [webdev, setWebdev] = useState(false);
  const [seo, setSeo] = useState(false);
  const [branding, setBranding] = useState(false);
  const [reputation, setReputation] = useState(false);

  const [showFacebook, setShowFacebook] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showPinterest, setShowPinterest] = useState(false);

  const [showGoogle, setShowGoogle] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showWebStore, setShowWebStore] = useState(false);
  const [showHosting, setShowHosting] = useState(false);

  const [showTripadvisor, setShowTripadvisor] = useState(false);
  const [showYelp, setShowYelp] = useState(false);

  useEffect(() => {
    if (onboarding) {
      if (onboarding.social) setSocial(onboarding.social);
      if (onboarding.webdev) setWebdev(onboarding.webdev);
      if (onboarding.seo) setSeo(onboarding.seo);
      if (onboarding.branding) setBranding(onboarding.branding);
      if (onboarding.reputation) setReputation(onboarding.reputation);

      if (onboarding.show_facebook) setShowFacebook(onboarding.show_facebook);
      if (onboarding.show_instagram) setShowInstagram(onboarding.show_instagram);
      if (onboarding.show_twitter) setShowTwitter(onboarding.show_twitter);
      if (onboarding.show_linkedin) setShowLinkedin(onboarding.show_linkedin);
      if (onboarding.show_pinterest) setShowPinterest(onboarding.show_pinterest);

      if (onboarding.show_google) setShowGoogle(onboarding.show_google);
      if (onboarding.show_website) setShowWebsite(onboarding.show_website);
      if (onboarding.show_email) setShowEmail(onboarding.show_email);
      if (onboarding.webstore) setShowWebStore(onboarding.webstore);
      if (onboarding.show_hosting) setShowHosting(onboarding.show_hosting);

      if (onboarding.show_trip) setShowTripadvisor(onboarding.show_trip);
      if (onboarding.show_yelp) setShowYelp(onboarding.show_yelp);
    }
  }, [onboarding])

  const onChange = (e) => {
    switch (e.target.id) {
      case 'facebook':
        setShowFacebook(e.target.checked);
        break;
      case 'instagram':
        setShowInstagram(e.target.checked);
        break;
      case 'twitter':
        setShowTwitter(e.target.checked);
        break;
      case 'linkedin':
        setShowLinkedin(e.target.checked);
        break;
      case 'pinterest':
        setShowPinterest(e.target.checked);
        break;

      case 'google':
        setShowGoogle(e.target.checked);
        break;
      case 'website':
        setShowWebsite(e.target.checked);
        break;
      case 'email':
        setShowEmail(e.target.checked);
        break;
      case 'webstore':
        setShowWebStore(e.target.checked);
        break;
      case 'hosting':
        setShowHosting(e.target.checked);
        break;

      case 'tripadvisor':
        setShowTripadvisor(e.target.checked);
        break;
      case 'yelp':
        setShowYelp(e.target.checked);
        break;
      default:
        break;
    }
  }

  const serviceButtonClick = (e, setService, value, nextType) => {
    e.preventDefault();
    if (nextType === "next-page") {
      setService(value);
      dispatch(updateChanges(["Updated Services"]));
      onSubmit(false);
    } else {
      setService(value);
      setType(nextType);
    }
  }

  const accountButtonClick = (e, nextType) => {
    if (nextType === "next-page") {
      dispatch(updateChanges(["Updated Services"]));
      onSubmit(true);
    } else {
      setType(nextType);
    }
  }

  const completionStats = (page, social, webdev, branding, reputation) => {
    if (page === "accounts_page") {
      if (social || webdev || reputation) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "assets_page") {
      if (social || webdev || branding) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "events_page") {
      if (social) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "emailforms_page") {
      if (webdev) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "social_handles_page") {
      if (webdev) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "social_questions_page") {
      if (social) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    } else if (page === "branding_questions_page") {
      if (branding) return({ required: true, completed: false });
      else return({ required: false, completed: false });
    }
  }

  const onSubmit = (value) => {
    setLoaded(false);
    const params = {
      accounts_page: completionStats("accounts_page", social, webdev, branding, value),
      assets_page: completionStats("assets_page", social, webdev, branding, value),
      events_page: completionStats("events_page", social, webdev, branding, value),
      emailforms_page: completionStats("emailforms_page", social, webdev, branding, value),
      social_handles_page: completionStats("social_handles_page", social, webdev, branding, value),
      social_questions_page: completionStats("social_questions_page", social, webdev, branding, value),
      branding_questions_page: completionStats("branding_questions_page", social, webdev, branding, value),
      social: social,
      webdev: webdev,
      seo: seo,
      branding: branding,
      reputation: value,
      show_facebook: showFacebook,
      show_instagram: showInstagram,
      show_twitter: showTwitter,
      show_linkedin: showLinkedin,
      show_pinterest: showPinterest,
      show_google: showGoogle,
      show_website: showWebsite,
      show_email: showEmail,
      webstore: showWebStore,
      show_hosting: showHosting,
      show_trip: showTripadvisor,
      show_yelp: showYelp
    };
    axios.put('/api/onboarding/services', params)
      .then(response => {
        if (!social && !webdev && !seo && branding && !value) {
          props.history.push('/onboarding/assets');
        } else if (!social && !webdev && !seo && !branding && !value) {
          props.history.push('/onboarding/review');
        } else {
          props.history.push("/onboarding/accounts");
        }
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>

          {type === "social" && (
            <Fade show={true}>
              <h1>
                Are we managing your Social Media?
              </h1>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setSocial, true, "social-questions")}
                  >Yes</button>
                </div>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setSocial, false, "webdev")}
                  >No</button>
                </div>
              </div>
            </Fade>
          )}

          {type === "social-questions" && (
            <Fade show={true}>
              {/* Questions.js for template here */}
              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-10 col-lg-8 ${classes.QuestionHeading}`}>
                  <h1>Social Media Info</h1>
                  <h2>Check all that apply:</h2>
                </div>
              </div>

              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-10 col-lg-8 `}>
                  <form className={classes.Form} noValidate>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="facebook" onChange={onChange} type="checkbox" checked={showFacebook}/>
                      <label className="form-check-label" htmlFor="facebook">
                        My business has a Facebook page
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="instagram" onChange={onChange} type="checkbox" checked={showInstagram}/>
                      <label className="form-check-label" htmlFor="instagram">
                        My business has a Instagram Account
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="twitter" onChange={onChange} type="checkbox" checked={showTwitter}/>
                      <label className="form-check-label" htmlFor="twitter">
                        My business has a Twitter Account
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="linkedin" onChange={onChange} type="checkbox" checked={showLinkedin}/>
                      <label className="form-check-label" htmlFor="linkedin">
                        My business has a LinkedIn Account
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="pinterest" onChange={onChange} type="checkbox" checked={showPinterest}/>
                      <label className="form-check-label" htmlFor="linkedin">
                        My business has a Pinterest Account
                      </label>
                    </div>

                    <div className={`row justify-content-center`}>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "social")}
                        >Back</button>
                      </div>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "webdev")}
                        >Next</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </Fade>
          )}

          {type === "webdev" && (
            <Fade show={true}>
              <h1>
                Are we creating or updating your Website?
              </h1>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setWebdev, true, "seo")}
                  >Yes</button>
                </div>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setWebdev, false, "seo")}
                  >No</button>
                </div>
              </div>
            </Fade>
          )}

          {type === "seo" && (
            <Fade show={true}>
              <h1>
                Are we managing Search Engine Optimization (SEO) for your Website?
              </h1>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setSeo, true, "web-questions")}
                  >Yes</button>
                </div>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setSeo, false, `${webdev ? "web-questions" : "branding"}`)}
                  >No</button>
                </div>
              </div>
            </Fade>
          )}

          {type === "web-questions" && (
            <Fade show={true}>
              {/* Questions.js for template here */}
              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-10 ${classes.QuestionHeading}`}>
                  <h1>{webdev ? "Website Development Info" : "SEO Info"}</h1>
                  <h2>Check all that apply:</h2>
                </div>
              </div>

              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-12`}>
                  <form className={classes.Form} noValidate>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="google" onChange={onChange} type="checkbox" checked={showGoogle}/>
                      <label className="form-check-label" htmlFor="google">
                        My business has a Google Business Account
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="website" onChange={onChange} type="checkbox" checked={showWebsite}/>
                      <label className="form-check-label" htmlFor="website">
                        My business currently has a website
                      </label>
                    </div>

                    {webdev && (
                      <>
                        <div className={`${classes.Question} form-check`}>
                          <input className={`${classes.Checkbox} form-check-input`} id="email" onChange={onChange} type="checkbox" checked={showEmail}/>
                          <label className="form-check-label" htmlFor="email">
                            My business currently uses an Email Service
                          </label>
                        </div>

                        <div className={`${classes.Question} form-check`}>
                          <input className={`${classes.Checkbox} form-check-input`} id="webstore" onChange={onChange} type="checkbox" checked={showWebStore}/>
                          <label className="form-check-label" htmlFor="email">
                            My business currently has or needs a Web Store
                          </label>
                        </div>

                        <div className={`${classes.Question} form-check`}>
                          <input className={`${classes.Checkbox} form-check-input`} id="hosting" onChange={onChange} type="checkbox" checked={showHosting}/>
                          <label className="form-check-label" htmlFor="email">
                            My business currently uses or needs a Hosting Provider
                          </label>
                        </div>
                      </>
                    )}

                    <div className={`row justify-content-center`}>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "webdev")}
                        >Back</button>
                      </div>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "branding")}
                        >Next</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </Fade>
          )}

          {type === "branding" && (
            <Fade show={true}>
              <h1>
                Are we updating your company&apos;s branding?
              </h1>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setBranding, true, "reputation")}
                  >Yes</button>
                </div>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setBranding, false, "reputation")}
                  >No</button>
                </div>
              </div>
            </Fade>
          )}

          {type === "reputation" && (
            <Fade show={true}>
              <h1>
                Are we managing your online reputation?
              </h1>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setReputation, true, "reputation-questions")}
                  >Yes</button>
                </div>
                <div className={`col-sm-4 col-md-4 ${classes.Upload}`}>
                  <button
                    className={`${classes.Button}`}
                    style={buttonStyle}
                    onClick={(e) => serviceButtonClick(e, setReputation, false, "next-page")}
                  >No</button>
                </div>
              </div>
            </Fade>
          )}

          {type === "reputation-questions" && (
            <Fade show={true}>
              {/* Questions.js for template here */}
              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-10 ${classes.QuestionHeading}`}>
                  <h1>Reputation Management Info</h1>
                  <h2>Check all that apply:</h2>
                </div>
              </div>

              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-sm-12 col-lg-10`}>
                  <form className={classes.Form} noValidate>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="google" onChange={onChange} type="checkbox" checked={showGoogle}/>
                      <label className="form-check-label" htmlFor="google">
                        My business has a Google Business Account
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="tripadvisor" onChange={onChange} type="checkbox" checked={showTripadvisor}/>
                      <label className="form-check-label" htmlFor="tripadvisor">
                        My business has a Tripadvisor Account.
                      </label>
                    </div>

                    <div className={`${classes.Question} form-check`}>
                      <input className={`${classes.Checkbox} form-check-input`} id="yelp" onChange={onChange} type="checkbox" checked={showYelp}/>
                      <label className="form-check-label" htmlFor="yelp">
                        My business has a Yelp Account.
                      </label>
                    </div>

                    <div className={`row justify-content-center`}>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "reputation")}
                        >Back</button>
                      </div>
                      <div className={`col-sm-5 ${classes.Upload}`}>
                        <button
                          type="button"
                          className={`${classes.QuestionButton}`}
                          style={buttonStyle}
                          onClick={(e) => accountButtonClick(e, "next-page")}
                        >Next</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </Fade>
          )}

        </div>
      </div>
    </>
  );
}

export default withRouter(Services);

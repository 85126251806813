import React from 'react';
import { withRouter } from "react-router-dom";
//import { useSelector } from 'react-redux';
import Layout from "../../components/layout/layout";
import classes from "./success.module.scss";

const Success = (props) => {
  //const user = useSelector(state => state.auth.user);
  //const theme = useSelector(state => state.themeReducer.theme);

  const onClick = () => {
    props.history.push("/dashboard");
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={true}>
      <div className={`row justify-content-center ${classes.content}`}>
        <div className={`col-6 ${classes.text}`}>

          <h1>Checkout Succeeded <span style={{ color: "green" }} className="material-icons align-middle">check_circle_outline</span></h1>
          <p>You&apos;re all set for your 14 day trial!</p>

          <button onClick={onClick}>Get Started</button>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Success);

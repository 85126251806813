import React from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import classes from "./renew.module.scss";
import prices from "../../assets/prices";

const Trial = (props) => {

  async function soloClick(e, term) {
    try {
      let price_id;
      if (term === "daily") price_id = prices.solo_daily;
      if (term === "monthly") price_id = prices.solo_monthly;
      if (term === "yearly") price_id = prices.solo_yearly;
      let params = {
        price_id
      };
      let sessionUrl = await axios.post("/api/payment/create-return-checkout-session", params);
      window.location.assign(sessionUrl.data);
    } catch(err) {
      //console.log(err);
    }
  }

  async function agencyClick(e, term) {
    try {
      let price_id;
      if (term === "monthly") price_id = prices.agency_monthly;
      if (term === "yearly") price_id = prices.agency_yearly;
      let params = {
        price_id
      };
      let sessionUrl = await axios.post("/api/payment/create-return-checkout-session", params);
      window.location.assign(sessionUrl.data);
    } catch(err) {
      //console.log(err);
    }
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <div className={classes.content}>
        <div className={`row`} style={{ margin: "0px" }}>
          <div className={`col-12 ${classes.text}`}>
            <h1>Renew Your Subscription</h1>
            <p>
              Choose your package below to get started. You can upgrade
              as you grow, or cancel at any time.
            </p>
          </div>
        </div>
        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-5`}>
            <div className={classes.package}>
              <div className={classes.title}>
                <h2>Solopreneur</h2>
              </div>
              <div className={classes.subtext}>
                <h2>$10 per month</h2>
                <h3>or $100 per year</h3>
                <button onClick={(e) => soloClick(e, "monthly")}>Monthly</button>
                <button onClick={(e) => soloClick(e, "yearly")}>Annual</button>
                <button onClick={(e) => soloClick(e, "daily")}>Daily</button>
                <p><span className={`material-icons align-middle`}>check</span>14 day trial</p>
                <p><span className={`material-icons align-middle`}>check</span>Keep track of clients</p>
                <p><span className={`material-icons align-middle`}>check</span>Track Messages</p>
                <p><span className={`material-icons align-middle`}>check</span>Etc.</p>
              </div>
            </div>
          </div>
          <div className={`col-5`}>
            <div className={classes.package}>
              <div className={classes.title}>
                <h2>Agency</h2>
              </div>
              <div className={classes.subtext}>
                <h2>$20 per month</h2>
                <h3>or $200 month-to-month</h3>
                <button onClick={(e) => agencyClick(e, "monthly")}>Monthly</button>
                <button onClick={(e) => agencyClick(e, "yearly")}>Annual</button>
                <p><span className={`material-icons align-middle`}>check</span>14 day trial</p>
                <p><span className={`material-icons align-middle`}>check</span>Keep track of clients</p>
                <p><span className={`material-icons align-middle`}>check</span>Track Messages</p>
                <p><span className={`material-icons align-middle`}>check</span>Employee Access</p>
                <p><span className={`material-icons align-middle`}>check</span>Etc.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Trial);

import React, { useMemo } from 'react';
import { withRouter } from "react-router-dom";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";

const Table = (props) => {
  const columns = useMemo(() => props.columns, [props.columns]);
  const data = useMemo(() => props.data, [props.data]);
  const onClick = props.onClick;

  const buttonStyle = {
    backgroundColor: `#eee`,
    color: `black`,
    float: "left",
    margin: "0px 5px",
    padding: "5px 10px",
    width: "100px",
    border: "none",
    borderRadius: "3px"
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter } = state;

  return (
    <>
      <input value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span className={"material-icons align-middle"}>
                    {column.isSorted ? (column.isSortedDesc ? 'expand_more' : "expand_less") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return(
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.Header === "Options") return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  else return <td onClick={(e) => onClick(e, row)} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {(canNextPage || canPreviousPage) && (
        <div>
          <button style={buttonStyle} onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
          <button style={buttonStyle} onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
        </div>
      )}
    </>
  );
}

export default withRouter(Table);

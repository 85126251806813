import React, { useState, useEffect } from 'react';
import axios from 'axios';
import days from 'dayjs';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Table from "../../../components/table/table";
import classes from "./selectemployee.module.scss";

const SelectEmployee = (props) => {
  //const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const [loaded, setLoaded] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [message, setMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const employeeColumns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Department',
      accessor: 'department'
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      Cell: ({value}) => { return `(${value.slice(0, 3)})${value.slice(3, 6)}-${value.slice(6, 10)}`}
    },
    {
      Header: 'Hire Date',
      accessor: 'date',
      Cell: ({value}) => { return days(value).format('MM/DD/YYYY')}
    }
  ];

  useEffect(() => {
    axios.get('/api/employees/manager')
      .then(employees => {
        //console.log(employees.data);
        setEmployees(employees.data);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
        if (err.response.data.status === 404) setMessage("No Employees Found");
        else setMessage("Something went wrong!");
        setLoaded(true);
      });
  }, [])

  const employeeClick = (e, row) => {
    props.history.push(`/permissions/${row.original._id}`);
  }

  const goBack = () => {
    props.history.push("/dashboard");
  }

  return(
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div className={`row justify-content-center ${classes.Permissions}`}>
        <div className={`col-10`}>

            <div className={`${classes.EmployeeCol}`}>
              <h2>Select an Employee:</h2>
              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-xs-12 col-sm-10 ${classes.TableCol}`}>
                  <Table data={employees} columns={employeeColumns} onClick={employeeClick} />
                  <p style={{ color: "red" }}>{message}</p>
                </div>
              </div>
            </div>
            <button style={buttonStyle} className={classes.Button} onClick={goBack}>Back</button>

        </div>
      </div>
    </Layout>
  );
}

export default withRouter(SelectEmployee);

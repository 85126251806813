import React, { useState } from 'react';
import classes from "./heading.module.scss";

const ChatHeading = (props) => {
	const currentUser = props.user;

	const [showUsers, setShowUsers] = useState(false);

	const userButtonClick = (e) => {
		e.preventDefault();
		setShowUsers(!showUsers);
	}

	const sidebarButtonClick = (e) => {
		setShowUsers(false);
		props.showSidebar(e);
	}

	let title = "";
	if (props.conversation) title = props.conversation.name.replace(`${currentUser.name}`, "").trim();

	return (
		<div>
			{props.conversation && (
				<div style={{ margin: "0px" }} className={`row ${classes.HeadingRow}`}>
					<div className={`col-12 ${classes.HeadingCol}`}>
						<button className='d-md-none' style={{float: "left"}} onClick={sidebarButtonClick}><span style={{ marginTop: "5px" }} className={`material-icons`}>menu</span></button>
						<h1>{title}</h1>
						<button onClick={userButtonClick}><span style={{ marginTop: "3px" }} className={`material-icons`}>person</span></button>
					</div>
				</div>
			)}
			{showUsers && (
				<div className={`${classes.UsersModal}`}>
					<h1 style={{ marginBottom: "15px" }}>Users</h1>
					{props.conversation.display_names.map((user, index) => {
						return <p key={index}>{user}</p>;
					})}
				</div>
			)}
		</div>
	);
}

export default ChatHeading;

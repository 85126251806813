import React from 'react';
import { withRouter } from "react-router-dom";
import classes from "./sidebar.module.scss";

const Sidebar = (props) => {
  let primaryColor = props.primary_color;
  let accentColor = props.accent_color;
  let primaryTextColor = props.primary_text_color;
  let accentTextColor = props.accent_text_color;
  let role = props.role;
  let tier = props.tier;

  const slideIn = (e) => {
    document.getElementById("sidebarExtension").style.left = "50px";
  }

  const slideOut = (e) => {
    document.getElementById("sidebarExtension").style.left = "-130px";
  }

  const dashboardClick = () => {
    props.history.push("/dashboard");
  }

  const employeesClick = () => {
    props.history.push("/employees");
  }

  const clientsClick = () => {
    props.history.push("/clients");
  }

  const messagesClick = () => {
    props.history.push("/messages");
  }

  const accountClick = () => {
    props.history.push("/account");
  }

  const settingsClick = () => {
    props.history.push("/settings/account");
  }

  const buttonStyle = {
    backgroundColor: `${primaryColor}`,
    color: `${primaryTextColor}`
  };

  const sidebarStyle = {
    backgroundColor: `${accentColor}`
  };

  const accentTextStyle = {
    color: `${accentTextColor}`
  }

  return(
    <div>
      <div style={sidebarStyle} onMouseEnter={slideIn} className={classes.sidebar}>
        <div className={classes.navbuttons}>
          <button style={buttonStyle} onClick={dashboardClick}><span className={`material-icons align-middle`}>speed</span></button>
          <button style={buttonStyle}><span onClick={messagesClick} className={`material-icons align-middle`}>mail_outline</span></button>
          {(role === "Manager" || role === "Employee") && (<button style={buttonStyle} onClick={clientsClick}><span className={`material-icons align-middle`}>people</span></button>)}
          {(role === "Manager" && tier === "agency") && (<button style={buttonStyle} onClick={employeesClick}><span className={`material-icons align-middle`}>engineering</span></button>)}
          {/*role === "Employee" && (<button style={buttonStyle} onClick={vacationClick}><span className={`material-icons align-middle`}>flight</span></button>)*/}
          {role === "Client" && (
            <>
              <button style={buttonStyle} onClick={accountClick}><span className={`material-icons align-middle`}>account_circle</span></button>
            </>
          )}
        </div>

        <div className={classes.bottombuttons}>
          <button style={buttonStyle}><span className={`material-icons align-middle`}>help_outline</span></button>
          <button style={buttonStyle} onClick={settingsClick}><span className={`material-icons align-middle`}>settings</span></button>
        </div>
      </div>
      <div style={sidebarStyle} id="sidebarExtension" className={classes.sidebarExtension} onMouseLeave={slideOut}>
        <div className={classes.navbuttonDescriptions}>
          <p style={accentTextStyle} onClick={dashboardClick}>Dashboard</p>
          <p style={accentTextStyle} onClick={messagesClick}>Messages</p>
          {(role === "Manager" || role === "Employee") && (<p style={accentTextStyle} onClick={clientsClick}>Clients</p>)}
          {(role === "Manager" && tier === "agency") && (<p style={accentTextStyle} onClick={employeesClick}>Employees</p>)}
          {/*role === "Employee" && (<p style={accentTextStyle} onClick={vacationClick}>Vacation</p>)*/}
          {role === "Client" && (
            <>
              <p style={accentTextStyle} onClick={accountClick}>Account</p>
            </>
          )}
        </div>
        <div className={classes.bottombuttonDescriptions}>
          <p style={accentTextStyle}>FAQ</p>
          <p style={accentTextStyle} onClick={settingsClick}>Settings</p>
        </div>
      </div>
    </div>

  );
}

export default withRouter(Sidebar);

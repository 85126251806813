import React, { createContext, useState,  useEffect, useContext } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SocketContext } from "./SocketContext";
import { NOTIFICATION_RECEIVED, NOTIFICATION_UPDATE } from "../Events";

const NotificationContext = createContext(null);

export { NotificationContext };

const NotificationProvider = ({children}) => {
  const socket = useContext(SocketContext)[0];
  const auth = useSelector(state => state.auth);
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState(null);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (reload) {
      if (auth.isAuthenticated === true) {
        axios.get('/api/notifications/')
          .then(notifications => {
            //console.log(notifications.data);
            setNotifications(notifications.data);
            setReload(false);
          })
          .catch(err => {
            //console.log(err);
          });
      }
    }
  }, [auth.isAuthenticated, auth.user.id, reload]);

  useEffect(() => {
    if (auth.user.id && socket) {
      let notificationReceivedEvent = `${NOTIFICATION_RECEIVED}-${auth.user.id}`;
      let notificationUpdateEvent = `${NOTIFICATION_UPDATE}-${auth.user.id}`;
      //console.log(notificationReceivedEvent);
      socket.on(notificationReceivedEvent, (notification) => {
        //console.log("New Notification: ", notification);
        setNewNotification(notification);
      });

      socket.on(notificationUpdateEvent, () => {
        //console.log("New Notification: ", notification);
        setReload(true);
      });
    }
  }, [socket, auth.user.id]);

  useEffect(() => {
    if (newNotification) {
      let newNotifications = [...notifications];
      newNotifications.unshift(newNotification);
      setNotifications(newNotifications);
      setNewNotification(null);
    }
  }, [newNotification, notifications]);

  return (
    <NotificationContext.Provider value={[notifications, setNotifications]}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;

import React from 'react';
import classes from "./navbar.module.scss";

const Navbar = (props) => {
  return(
    <div className={classes.navbar}>
      <div className={classes.dropdown}>
        <p>Why the Honey Pot?</p><span className="material-icons align-middle">expand_more</span>
      </div>
      <div className={classes.dropdown}>
        <p>Packages</p><span className="material-icons align-middle">expand_more</span>
      </div>
      <div className={classes.dropdown}>
        <p>Pricing</p><span className="material-icons align-middle">expand_more</span>
      </div>
    </div>
  );
}

export default Navbar;

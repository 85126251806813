import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import classes from './manager.module.scss';
import Table from "../../../components/table/table";
import OptionsModal from "./modals/optionsModal";

const ClientList = (props) => {
  const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const [clients, setClients] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showOptions, setShowOptions] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (props.match.path === "/clients/archive") setShowArchive(true);
    else setShowArchive(false);
  }, [props.match.path])

  useEffect(() => {
    if (reload) {
      let archive = props.match.path === "/clients/archive" ? "true" : false;
      axios.get(`/api/clients/manager/${archive}`)
        .then(response => {
          setClients(response.data);
          setReload(false);
          setLoaded(true);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setErrorMessage("No Clients Found");
            setClients([]);
            setLoaded(true);
          } else {
            setErrorMessage("Something went wrong!");
            setLoaded(true);
          }
        });
    }
  }, [props.match.path, reload, user.role]);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const clientColumns = [
    {
      Header: 'Business Name',
      accessor: 'business_name'
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      Cell: ({value}) => { return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`}
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Contact Name',
      accessor: 'name'
    },
    {
      Header: 'Options',
      accessor: '_id',
      Cell: ({value}) => { return <button style={buttonStyle} className={classes.Button} onClick={(e) => optionsClick(e, value)}><span className={`material-icons align-middle`}>more_horiz</span></button>}
    }
  ];

  const goBack = (e) => {
    props.history.push("/dashboard");
  }

  const onClick = (e, row) => {
    if (showArchive) props.history.push(`/clients/archive/${row.original._id}`);
    else props.history.push(`/clients/${row.original._id}`);
  }

  const optionsClick = (e, id) => {
    setShowOptions(true);
    setSelectedClient(id);
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>

      <OptionsModal
        show={showOptions}
        setShow={setShowOptions}
        client={selectedClient}
        setClient={setSelectedClient}
        showArchive={showArchive}
        setReload={setReload}
      />

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10`}>
          <h1 className={classes.Heading}>{showArchive && "Archived "}Clients</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10  ${classes.TableCol}`}>
          <Table data={clients} columns={clientColumns} onClick={onClick} />
          <p className={classes.error}>{errorMessage}</p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-12 col-sm-4 ${classes.ButtonCol}`}>
          <button
            style={{
              width: "100%",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "20px",
              backgroundColor: `${theme.primary_color}`
            }}
            onClick={goBack}
            className={`${classes.BackButton}`}
          >Back</button>
        </div>
      </div>

    </Layout>
  );
}

export default withRouter(ClientList);

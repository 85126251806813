import React from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from './modal.module.scss';
import Fade from "../../../../components/layout/fade/fade";

const Options = (props) => {
  const { show, setShow, deleteUser } = props;

  return(
    <Modal
        className={classes.Modal}
        show={show}
      >
      <Modal.Body>
        <Fade size={"col-xs-12"} show={true}>
          <h4>Are you sure?</h4>
          <p style={{color: "red"}}>This cannot be undone!</p>
          <div className="row justify-content-center">
            <div className="col-xs-12 col-md-4">
              <button
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                  marginRight: "2rem",
                  backgroundColor: `grey`
                }}
                onClick={() => setShow(false)}
                className={`${classes.Button}`}
              >Cancel</button>
            </div>
            <div className="col-xs-12 col-md-4">
              <button
                style={{
                  width: "100%",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem",
                  backgroundColor: `grey`
                }}
                onClick={deleteUser}
                className={`${classes.Button}`}
              >Confirm</button>
            </div>
          </div>
        </Fade>
      </Modal.Body>
    </Modal>
  );
}

export default withRouter(Options);

import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import classes from "./socialhandles.module.scss";
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const SocialHandles = (props) => {
  const {
    auth,
    socket,
    changes,
    resetChanges,
    updateChanges,
    dispatch,
    theme,
    onboarding,
    nextRoute,
    setLoaded
  } = props;
  const [newChanges, setNewChanges] = useState(changes);
  const [completed, setCompleted] = useState(false);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, newChanges);
          dispatch(resetChanges());
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, newChanges])

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [youtube, setYoutube] = useState("");
  const [other, setOther] = useState("");

  useEffect(() => {
    if (onboarding.social_handles_page) setCompleted(onboarding.social_handles_page.completed);
    if (onboarding.social_handles) {
      let handles = onboarding.social_handles;
      setFacebook(handles.facebook);
      setInstagram(handles.instagram);
      setTwitter(handles.twitter);
      setLinkedin(handles.linkedin);
      setPinterest(handles.pinterest);
      setYoutube(handles.youtube);
      setOther(handles.other);
    }
  }, [onboarding])

  const addToChanges = (update) => {
    if (!newChanges.includes(update)) {
      const addChanges = [...newChanges];
      addChanges.push(update);
      setNewChanges(addChanges);
    }
  }

  const onChange = (e) => {
    setCompleted(true);
    addToChanges("Updated Website Social Media Handles");
    switch (e.target.id) {
      case 'facebook':
        setFacebook(e.target.value);
        break;
      case 'instagram':
        setInstagram(e.target.value);
        break;
      case 'twitter':
        setTwitter(e.target.value);
        break;
      case 'linkedin':
        setLinkedin(e.target.value);
        break;
      case 'pinterest':
        setPinterest(e.target.value);
        break;
      case 'youtube':
        setYoutube(e.target.value);
        break;
      case 'other':
        setOther(e.target.value);
        break;
      default:
        break;
    }
  }

  const onBack = (e) => {
    e.preventDefault();
    const params = {
      completed: completed,
      handles: {
        facebook,
        instagram,
        twitter,
        linkedin,
        pinterest,
        youtube,
        other
      }
    };
    axios.put('/api/onboarding/links/social', params)
      .then(response => {
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    dispatch(updateChanges(newChanges));
    const params = {
      completed: completed,
      handles: {
        facebook,
        instagram,
        twitter,
        linkedin,
        pinterest,
        youtube,
        other
      }
    };
    axios.put('/api/onboarding/links/social', params)
      .then(response => {
        //console.log(response.data);
        props.history.push(nextRoute);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <div>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>Links to Social Handles</h1>
          <h2>
            Please include links to all social platforms you would<br/> like to link
            to from your website
          </h2>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate>

            <div className={`${classes.Label}`}>
              <h2>Facebook</h2>
              <input id="facebook" value={facebook} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Instagram</h2>
              <input id="instagram" value={instagram} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Twitter</h2>
              <input id="twitter" value={twitter} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>LinkedIn</h2>
              <input id="linkedin" value={linkedin} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Pinterest</h2>
              <input id="pinterest" value={pinterest} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Youtube</h2>
              <input id="youtube" value={youtube} type="text" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Other</h2>
              <input id="other" value={other} type="text" onChange={onChange} />
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onBack}>Save & Exit</button>
              </div>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SocialHandles);

import React, { useState } from 'react';
import axios from 'axios';
import { MESSAGE_SENT } from "../../../Events";
import Modal from 'react-bootstrap/Modal';
import Spinner from '../../../components/layout/spinner/spinner';
import classes from './modal.module.scss';

const Attachment = (props) => {
  const user = props.user;
  const socket = props.socket;
  const theme = props.theme;
  const conversation = props.conversation;
  const show = props.show;
  const setShow = props.setShow;

  const [file, setFile] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fileUploadError, setFileUploadError] = useState("");

  const showAttachmentToggle = (e) => {
    setShow(false);
    setTimeout(() => {
      setFile("");
      setFileUploadError("");
      setShowSpinner(false);
      setShowSuccess(false);
    }, 1000);
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case 'file':
        setFile(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      default:
        break;
    }
  }

  async function sendFileMessage(e) {
    setFileUploadError("");
    setShowSpinner(true);
    //console.log(file);
    if (conversation) {
      try {
        let fileUpload = new FormData();
        fileUpload.append('file', file);
        let fileResponse = await axios.post(`/api/files/message/${user.id}`, fileUpload);
        //console.log(fileResponse.data);
        if (fileResponse.data === "No File Uploaded") {
          setShowSpinner(false);
          setFileUploadError("Please select a file");
          return;
        }
        const params = {
          conversation_id: conversation._id,
          file_name: fileResponse.data.filename,
          file_location: fileResponse.data.location,
          file_type: fileResponse.data.file_type,
          type: 'file'
        };
        let messageResponse = await axios.post('/api/messages/file', params);
        //console.log(messageResponse.data);
        socket.emit(MESSAGE_SENT, conversation, messageResponse.data);
        setShowSpinner(false);
        setShowSuccess(true);
        setTimeout(() => {
          showAttachmentToggle();
          return;
        }, 500);
      } catch(err) {
        //console.log(err);
        setShowSpinner(false);
        setFileUploadError("Something went wrong!");
        return;
      }
    } else {
      //console.log("No conversation selected");
      setShowSpinner(false);
      setFileUploadError("Please select a conversation");
      return;
    }
  }

  return(
    <Modal
      className={classes.Modal}
      show={show}
    >
      <Modal.Body>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-sm-6 offset-sm-3">
            <h2 style={{ marginTop: "10px" }}>Send Attachment:</h2>
          </div>
          <div className="col-sm-2 offset-sm-1">
            <button className={`${classes.Button}`} style={{backgroundColor: "#8c0000", width: "50px" }} onClick={showAttachmentToggle}>X</button>
          </div>
        </div>
        <input style={{ width: "100%" }} className={`form-control`} type="file" name="file" onChange={onChange}/>
        <div className={`${classes.FileUploadIndicator}`}>
          {showSpinner && (
            <Spinner color={theme.primary_color} width={"30px"} height={"30px"}/>
          )}
          {showSuccess && (
            <span style={{ color: "green" }} className="material-icons">check_circle_outline</span>
          )}
          <p style={{ color: "red", textAlign: "center" }}>{fileUploadError}</p>
        </div>
        <button
          className={`${classes.Button}`}
          style={{ backgroundColor: `${theme.primary_color}`, margin: "15px 0px 0px 0px" }}
          onClick={sendFileMessage}>
            Send
        </button>
      </Modal.Body>
    </Modal>
  );
}

export default Attachment;

import React from 'react';
import days from 'dayjs';
import classes from './notification.module.scss';

const notification = (props) => {
  const message = props.message;
  const date = props.date;
  const primary_color = props.primary_color;
  const primary_text_color = props.primary_text_color;
  const count = props.count;
  let displayCount = count > 1 ? count : <></>;

  return (
    <div className={`row ${classes.Notification}`}>
      <div className={`col ${classes.NotificationCol}`} style={{ backgroundColor: "white" }}>

        <div className={`row ${classes.NotificationMain}`}>

          <div className={`col-10 ${classes.NotificationMessage}`} onClick={(e) => props.click(e, props.notification)}>
            <p>{message.length > 66 ? `${message.slice(0, 66)}...` : message }</p>
          </div>

          {!props.viewed && (
            <div className={`col-2 ${classes.NotificationIndicator}`} onClick={(e) => props.click(e, props.notification)}>
              <div style={{ backgroundColor: `${primary_color}`, color: `${primary_text_color}` }} className={`${classes.Number}`}>{displayCount}</div>
            </div>
          )}
        </div>

        <div className={`row ${classes.NotificationBottom}`}>
          <div className={`col-9 ${classes.Date}`}>
            <p>{days(date).format('h:mma | MMM DD, YYYY')}</p>
          </div>

          <div className={`col-3 ${classes.Delete}`} onClick={(e) => props.delete(e, props.notificationId)}>
            <p>Delete</p>
          </div>
        </div>

      </div>
    </div>
  );
}

export default notification;

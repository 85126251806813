import React from 'react';
import classes from "./sidebar.module.scss";

const SidebarOption = (props) => {
  const { theme } = props;
  return (
    <div className={`row ${classes.SidebarOption}`}>
      <div className={`col-10 ${classes.SidebarName}`}>
        <p id={props.id} onClick={(e) => props.select(props.id)}>{props.displayName}</p>
      </div>
      <div className={`col-2 ${classes.SidebarName}`}>
        {props.unread_count !== 0 ?
          <div style={{ backgroundColor: `${theme.primary_color}` }} className={classes.Count}>
            <p style={{ color: `${theme.primary_text_color}`}}>{props.unread_count}</p>
          </div>
         : <></>}
      </div>
    </div>
  );
}

export default SidebarOption;

import React from 'react';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import Navbar from "../../components/navbar/navbar";
import classes from "./trial.module.scss";

const Trial = (props) => {

  const soloClick = (e, term) => {
    props.history.push(`/register/solo/${term}`);
  }

  const agencyClick = (e, term) => {
    props.history.push(`/register/agency/${term}`);
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <Navbar/>
      <div className={classes.content}>
        <div className={`row`} style={{ margin: "0px" }}>
          <div className={`col-12 ${classes.text}`}>
            <h1>Try the Honey Pot for free with a 14&#8209;day trial</h1>
            <p>
              Choose your package below to get started. You can upgrade
              as you grow, or cancel at any time.
            </p>
          </div>
        </div>
        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-5`}>
            <div className={classes.package}>
              <div className={classes.title}>
                <h2>Solopreneur</h2>
              </div>
              <div className={`row ${classes.selection}`}>
                <div className={`col`}>
                  <h2>Monthly</h2>
                  <p>$10/month</p>
                  <button onClick={(e) => soloClick(e, "monthly")}>Start your free trial</button>
                </div>
                <div className={`col`}>
                  <h2>Annual</h2>
                  <p>$100/year</p>
                  <button onClick={(e) => soloClick(e, "annual")}>Start your free trial</button>
                </div>
              </div>
              <div className={classes.subtext}>
                <p><span className={`material-icons align-middle`}>check</span>14 day trial</p>
                <p><span className={`material-icons align-middle`}>check</span>Keep track of clients</p>
                <p><span className={`material-icons align-middle`}>check</span>Track Messages</p>
                <p><span className={`material-icons align-middle`}>check</span>Etc.</p>
              </div>
            </div>
          </div>
          <div className={`col-5`}>
            <div className={classes.package}>
              <div className={classes.title}>
                <h2>Agency</h2>
              </div>
              <div className={`row ${classes.selection}`}>
                <div className={`col`}>
                  <h2>Monthly</h2>
                  <p>$20/month</p>
                  <button onClick={(e) => agencyClick(e, "monthly")}>Start your free trial</button>
                </div>
                <div className={`col`}>
                  <h2>Annual</h2>
                  <p>$200/year</p>
                  <button onClick={(e) => agencyClick(e, "annual")}>Start your free trial</button>
                </div>
              </div>
              <div className={classes.subtext}>
                <p><span className={`material-icons align-middle`}>check</span>14 day trial</p>
                <p><span className={`material-icons align-middle`}>check</span>Keep track of clients</p>
                <p><span className={`material-icons align-middle`}>check</span>Track Messages</p>
                <p><span className={`material-icons align-middle`}>check</span>Employee Access</p>
                <p><span className={`material-icons align-middle`}>check</span>Etc.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Trial);

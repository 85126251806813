import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import Fade from "../../components/layout/fade/fade";
import classes from './employeedetails.module.scss';
import Table from "../../components/table/table";

const EmployeeDetails = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [employee, setEmployee] = useState("");
  const [settings, setSettings] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [vacations, setVacations] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const color = theme.primary_color;
  
  const [error, setError] = useState(false);
  const [response, setResponse] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [hireDate, setHireDate] = useState("");

  useEffect(() => {
    if (props.match.params.id) {
      axios.get(`/api/employees/details/${props.match.params.id}`)
        .then(response => {
          //console.log(response.data);
          setEmployee(response.data.employee);
          setName(response.data.employee.name);
          setEmail(response.data.employee.email);
          setDepartment(response.data.employee.department);
          setPhone(response.data.employee.phone);
          setBirthday(response.data.employee.birthday);
          setHireDate(response.data.employee.hireDate);
          if (response.data.settings) setSettings(response.data.settings);
          setPermissions(response.data.permissions);
          setVacations(response.data.vacations);
          setLoaded(true);
        })
        .catch(err => setErrorMessage("There was an issue loading employee data!"));
    }
  }, [props.match.params.id]);

  const permissionColumns = [
    {
      Header: 'Business Name',
      accessor: 'client_business'
    },
    {
      Header: 'Contact Name',
      accessor: 'client_name'
    },
    {
      Header: 'Social',
      accessor: 'social',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Web Dev.',
      accessor: 'web',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Branding',
      accessor: 'branding',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Reputation',
      accessor: 'reputation',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    }
  ];

  const vacationColumns = [
    {
      Header: 'Start',
      accessor: 'start'
    },
    {
      Header: 'End',
      accessor: 'end'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => { return <p style={{ margin: "0px", backgroundColor: `${value === "Approved"?"green":"#b30000"}`, color: `white` }} className={classes.requeststatus}>{value}</p>}
    },
    {
      Header: 'Details',
      accessor: '_id',
      Cell: ({value}) => { return <button style={menuButtonStyle} className={classes.TableButton} onClick={(e) => vacationClick(e, value)}>Calendar View</button>}
    },
  ];

  const vacationClick = (e, id) => {
    props.history.push(`/calendar/manage/${id}`);
  }

  const [generalView, setGeneralView] = useState(true);
  const [editView, setEditView] = useState(false);
  const [formsView, setFormsView] = useState(false);
  const [permissionsView, setPermissionsView] = useState(false);
  const [vacationView, setVacationView] = useState(false);

  const viewController = e => {
    switch(e.target.id) {
      case 'generalView':
        setGeneralView(true);
        setEditView(false);
        setFormsView(false);
        setPermissionsView(false);
        setVacationView(false);
        break;
      case 'editView':
        setGeneralView(false);
        setEditView(true);
        setFormsView(false);
        setPermissionsView(false);
        setVacationView(false);
        break;
      case 'formsView':
        setGeneralView(false);
        setEditView(false);
        setFormsView(true);
        setPermissionsView(false);
        setVacationView(false);
        break;
      case 'permissionsView':
        setGeneralView(false);
        setEditView(false);
        setFormsView(false);
        setPermissionsView(true);
        setVacationView(false);
        break;
      case 'vacationView':
        setGeneralView(false);
        setEditView(false);
        setFormsView(false);
        setPermissionsView(false);
        setVacationView(true);
        break;
      default:
        break;
    }
  }

  const goBack = e => {
    props.history.push('/employees');
  }

  const onChange = (e) => {
    setError(false);
    setResponse("");
    switch (e.target.id) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'department':
        setDepartment(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'birthday':
        setBirthday(e.target.value);
        break;
      case 'hireDate':
        setHireDate(e.target.value);
        break;
      default:
        break;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      setResponse("Please enter at least an email for the employee.");
      return;
    }

    let params = {
      name: name,
      email: email,
      department: department,
      phone: phone,
      birthday: birthday,
      hire_date: hireDate
    };
    axios.post('/api/employees/update/' + employee.id, params)
      .then(response => {
        setGeneralView(true);
        setEditView(false);
      })
      .catch(err => {
        setError(true);
        if (err.response.data === "\"email\" must be a valid email") {
          setResponse("Please enter a valid email");
        } else if (err.response.data === "User does not exist") {
          setResponse("User does not exist");
        } else setResponse("Something went wrong! Please refresh and try again.");
      });
  }

  const menuButtonStyle = {
    border: "none",
    borderRadius: "3px",
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const editPermissionsClick = (e) => {
    props.history.push(`/permissions/${employee._id}`);
  }

  let address = "";
  if (employee.address) address = employee.address;

  //-----------------------Set Up----------------------------------------------

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <p>{errorMessage}</p>
      {loaded && (
        <div>
        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 ${classes.HeadingCol}`}>
            <h1><span className={`${classes.BoldText}`}>{name}</span> | {department}</h1>
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-xs-12 ${classes.ViewButtonsCol}`}>
            <button onClick={viewController} id="generalView" style={{ border: `solid ${color} 1px`, color: `${color}`}} className={`${classes.ViewButton}`}>General</button>
            <button onClick={viewController} id="formsView" style={{ border: `solid ${color} 1px`, color: `${color}`}} className={`${classes.ViewButton}`}>Forms</button>
            <button onClick={viewController} id="permissionsView" style={{ border: `solid ${color} 1px`, color: `${color}`}} className={`${classes.ViewButton}`}>Permissions</button>
            <button onClick={viewController} id="vacationView" style={{ border: `solid ${color} 1px`, color: `${color}`}} className={`${classes.ViewButton}`}>Vacation</button>
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 col-sm-9 ${classes.ViewCol}`}>
            {generalView && (
              <Fade size={"col-xs-12"} show={true}>
                <h2 style={{ display: "inline-block" }}>General Info</h2> 
                <button className={classes.EditButton} style={menuButtonStyle} onClick={viewController} id="editView">Edit</button>
                <p>Department: {department}</p>
                <p>Email Address: {email}</p>
                <p>Phone Number: {`${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6, 10)}`}</p>
                <p>Address: {address !== "" && (`${address.line1}, ${address.city}, ${address.state} ${address.zip}`)}</p>
                <p>Birthday: {birthday}</p>
                <p>Hire Date: {hireDate}</p>
              </Fade>
            )}

            {editView && (
              <Fade size={"col-xs-12"} show={true}>
                <h2 style={{ display: "inline-block" }}>Edit General Info</h2> 
                <p>Name: <input className={`form-control`} type="text" id="name" value={name} onChange={onChange} placeholder="Employee Name" /></p>
                <p>Department: <input className={`form-control`} type="text" id="department" value={department} onChange={onChange} placeholder="Employee Department" /></p>
                <p>Email Address: <input className={`form-control`} type="text" id="email" value={email} onChange={onChange} placeholder="Employee Email Address" /></p>
                <p>Phone Number: <input className={`form-control`} type="text" id="phone" value={phone} onChange={onChange} placeholder="Ex. 1234567890" /></p>
                <p>Birthday: <input className={`form-control`} type="text" id="birthday" value={birthday} onChange={onChange} placeholder="mm/dd/yyyy" /></p>
                <p>Hire Date: <input className="form-control" type="text" id="hireDate" value={hireDate} onChange={onChange} placeholder="mm/dd/yyyy" /></p>
                <button type="submit" className={`${classes.ConfigureButton}`} style={menuButtonStyle} onClick={onSubmit}>Save</button>
              </Fade>
            )}

            {formsView && (
              <Fade size={"col-xs-12"} show={true}>
                <h2>Employee Forms</h2>
                {employee.employee_onboarding_files && (
                  <div className={classes.Assets}>
                    {employee.employee_onboarding_files.map((file, index) => {
                      return (
                        <div key={index} style={menuButtonStyle} className={`${classes.File}`}>
                          <a href={file.location} style={{ color: `${theme.primary_text_color}` }} download>{file.filename}</a>
                        </div>
                      )
                    })}
                    {employee.employee_onboarding_files.length < 1 && (<p style={{ color: "red" }}>No files uploaded</p>)}
                  </div>
                )}
              </Fade>
            )}

            {permissionsView && (
              <>
                {settings !== "" && (
                  <Fade size={"col-xs-12"} show={true}>
                    <h2 style={{ display: "inline-block" }}>Employee Permissions</h2>
                    <button className={classes.EditButton} style={menuButtonStyle} onClick={editPermissionsClick}>Edit</button>

                    <div className={`row justify-content-center`} style={{ margin: "0px", width: "90%" }}>
                      <div className={`col-12 ${classes.permissionsCol}`}>
                        <h3>General Permissions</h3>
                        <div className={classes.generalPermissionsLine}>
                          Vacation Requests:
                          <div style={{ display: "inline-block", margin: "0px 0px 0px 20px", backgroundColor: `${settings.employee_permissions.vacation?"green":"#b30000"}`, color: `white` }} className={classes.textPermission}>{settings.employee_permissions.vacation ? "Yes" : "No"}</div>
                        </div>
                        <div className={classes.generalPermissionsLine}>
                          Add New Clients:
                          <div style={{ display: "inline-block", margin: "0px 0px 0px 20px", backgroundColor: `${settings.employee_permissions.add_new_clients?"green":"#b30000"}`, color: `white` }} className={classes.textPermission}>{settings.employee_permissions.add_new_clients ? "Yes" : "No"}</div>
                        </div>
                      </div>
                    </div>

                    <div className={`row justify-content-center`} style={{ margin: "0px", width: "90%" }}>
                      <div className={`col-12 ${classes.permissionsCol}`}>
                        <h3>Client Permissions</h3>
                      </div>
                    </div>

                    <div className={`row justify-content-center`} style={{ margin: "0px", width: "90%" }}>
                      <div className={`col-12 ${classes.TableCol}`}>
                        <Table data={permissions} columns={permissionColumns} onClick={() => {}} />
                        <p className={classes.error}></p>
                      </div>
                    </div>
                  </Fade>
                )}
                {settings === "" && (
                  <>
                    <p style={{ color: "red" }}>Employee&apos;s permissions not configured</p>
                    <button className={classes.ConfigureButton} style={menuButtonStyle} onClick={editPermissionsClick}>Configure</button>
                  </>
                )}
              </>
            )}

            {vacationView && (
              <Fade size={"col-xs-12"} show={true}>
                <h2>Vacation Requests</h2>
                <div className={`row justify-content-center`} style={{ margin: "0px", width: "90%" }}>
                  <div className={`col-12 ${classes.TableCol}`}>
                    <Table data={vacations} columns={vacationColumns} onClick={() => {}} />
                    <p className={classes.error}></p>
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 ${classes.BackButtonCol}`}>
            <button onClick={goBack} style={{ backgroundColor: `${color}`}} className={`${classes.Button}`}>Back</button>
          </div>
        </div>
        </div>
      )}
    </Layout>
  );
}

export default withRouter(EmployeeDetails);

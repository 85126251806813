import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from './modal.module.scss';

const CreateGroup = (props) => {
  const {
    theme,
    user,
    show,
    setShow,
    createConversation,
    messageUsers,
  } = props;

  const [groupName, setGroupName] = useState("");
  const [groupUser, setGroupUser] = useState("");
  const [groupUsernames, setGroupUsernames] = useState([`${user.name}`]);
  const [groupIds, setGroupIds] = useState([`${user.id}`]);
  const [groupUserError, setGroupUserError] = useState("");
  const [groupConversationError, setGroupConversationError] = useState("");

  const addGroupUser = () => {
    let newUser = null;
    // Find User in messageUsers
    messageUsers.forEach((user) => {
      if (user.name === groupUser) {
        newUser = user;
      }
    });
    if (newUser) {
      if (!groupUsernames.includes(groupUser)) {
        const newGroupUsers = [...groupUsernames];
        newGroupUsers.push(groupUser);
        setGroupUsernames(newGroupUsers);
        const newGroupIds = [...groupIds];
        newGroupIds.push(newUser._id.toString());
        setGroupIds(newGroupIds);
        setGroupUser("");
        setGroupUserError("");
      } else {
        setGroupUser("");
      }
    } else {
      setGroupUserError("User not found");
    }
  }

  const showCreateGroupToggle = () => {
    setShow(false);
    setGroupUsernames([`${user.name}`]);
    setGroupIds([`${user.id}`]);
    setGroupName("");
    setGroupUserError("");
    setGroupConversationError("");
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setGroupName(e.target.value);
        setGroupConversationError("");
        break;
      case 'user':
        setGroupUser(e.target.value);
        setGroupUserError("");
        setGroupConversationError("");
        break;
      default:
        break;
    }
  }

  const createNewGroupConversation = () => {
    if (groupName === "") {
      setGroupConversationError("Please select a name");
    } else if (groupUsernames.length < 2) {
      setGroupConversationError("Please select more group members");
    } else {
      let admins = [];
      if (user.role === "Manager") admins = [user.id];
      else admins = [user.id, user.manager_id];

      let params = {
        name: groupName,
        user_ids: groupIds,
        display_names: groupUsernames,
        admin_ids: admins,
        type: 'Group'
      }
      createConversation(params);
      setGroupName("");
      setGroupUser("");
      setGroupUsernames([`${user.name}`]);
      setGroupIds([`${user.id}`]);
      setGroupUserError("");
      setGroupConversationError("");
      setShow(false);
    }
  }

  return(
    <Modal
      className={classes.Modal}
      show={show}
    >
      <Modal.Body>
        <div className={`row justify-content-center`}>
          <div className={`col-sm-12`}>
            <div className="row">
              <div className="col-sm-6 offset-sm-3">
                <h2>Create a new Group:</h2>
              </div>
              <div className="col-sm-2 offset-sm-1">
                <button className={`${classes.Button}`} style={{backgroundColor: "#8c0000", width: "50px" }} onClick={showCreateGroupToggle}>X</button>
              </div>
            </div>
            <p>Group Name:</p>
            <input name="name" type="text" value={groupName} onChange={onChange} className={`form-control col-12`}/>
            <p>Add Users:</p>
            <input
              name="user"
              type="text"
              value={groupUser}
              onChange={onChange}
              list="addUsersList"
              className={`form-control col-12`}
              autoComplete="off"/>
            <p style={{ color: "red", textAlign: "center" }}>{groupUserError}</p>
            <button
              className={`${classes.Button}`}
              style={{ backgroundColor: `${theme.primary_color}`, marginTop: "10px" }}
              onClick={addGroupUser}>
                Add
            </button>
            <datalist id="addUsersList">
              {messageUsers.length > 0 && (
                messageUsers.map((user, index) => {
                  //let displayName = "";
                  //if (user.role === "Client") displayName = user.billing.businessName;
                  //else displayName = user.name;
                  return <option key={index} value={user.name}>{user.name}</option>
                })
              )}
            </datalist>
            <p>Users:</p>
            {groupUsernames.length > 0 && (
              groupUsernames.map((user, index) => {
                return <p key={index} style={{ margin: "5px" }}>&bull; {user}</p>
              })
            )}
            <p style={{ color: "red", textAlign: "center" }}>{groupConversationError}</p>
            <button
              className={`${classes.Button}`}
              style={{ backgroundColor: `${theme.primary_color}`, marginTop: "10px" }}
              onClick={createNewGroupConversation}>
                Create
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default withRouter(CreateGroup);

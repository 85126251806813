import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classes from "./sidebar.module.scss";
import SidebarOption from "./sidebarOption";

const Sidebar = (props) => {
  const theme = props.theme;
  const user = props.user;
  const selectConversation = props.select;

  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilterButtons, setSelectedFilterButtons] = useState(["Client", "Employee", "Manager"]);

  const [showDM, setShowDM] = useState(true);
  const [showGroups, setShowGroups] = useState(true);
  const [directConversations, setDirectConversations] = useState([]);
  const [groupConversations, setGroupConversations] = useState([]);

  useEffect(() => {
    let conversations = props.conversations;
    setDirectConversations(conversations.filter((conversation) => conversation.type === "Direct"));
    setGroupConversations(conversations.filter((conversation) => conversation.type === "Group"));
  }, [props.conversations]);

  const groupArrowClick = (e) => {
    if (showGroups) setGroupConversations([]);
    else setGroupConversations(props.conversations.filter((conversation) => conversation.type === "Group"));
    setShowGroups(!showGroups);
  }

  const dmArrowClick = (e) => {
    if (showDM) setDirectConversations([]);
    else setDirectConversations(props.conversations.filter((conversation) => conversation.type === "Direct"));
    setShowDM(!showDM);
  }

  const filterButtonClick = (e) => {
    let button = document.getElementById(e.target.id);
    if (button.classList.contains(classes.Selected)) {
      //Remove id from selectedFilterButtons
      let newSelectedFilterButtons = selectedFilterButtons.filter((button) => button !== e.target.id);
      setSelectedFilterButtons(newSelectedFilterButtons);
    } else {
      //Add id to selectedFilterButtons
      let newSelectedFilterButtons = [...selectedFilterButtons];
      newSelectedFilterButtons.push(e.target.id);
      setSelectedFilterButtons(newSelectedFilterButtons);
    }
  }

  useEffect(() => {
    function filterDirectConversations() {
      let newDirectConversations = props.conversations.filter((conversation) => {
        if (conversation.type === "Direct") {
          if (selectedFilterButtons.includes(conversation.role)) return true;
          else return false;
        } else return false;
      });
      setDirectConversations(newDirectConversations);
    }

    if (showDM) {
      let idArray = ["Client", "Employee", "Manager"];
      if (showFilter) {
        idArray.forEach((type) => {
          let button = document.getElementById(type);
          if (selectedFilterButtons.includes(type)) button.classList.add(classes.Selected);
          else button.classList.remove(classes.Selected);
        });
        filterDirectConversations();
      }
    }
  }, [showFilter, selectedFilterButtons, showDM, props.conversations]);

  return (
    <div>

      {/* Top Buttons */}
      <div className={`row ${classes.ControlsRow} ${classes.SidebarRow}`}>
        <div className={`col-12 ${classes.ControlsCol}`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <button
                className={`${classes.Button}`}
                style={{ backgroundColor: `${theme.primary_color}`, padding: "8px 18px 0px 18px", marginTop: "5px", display: "inline" }}
                onClick={props.goBack}>
                  <span className={`material-icons`}>arrow_back</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Group Messages */}
      <div className={`row ${classes.GroupMessageRow} ${classes.SidebarRow}`}>
  			<div className={`col-12 ${classes.GroupMessageCol}`}>
          <h2 onClick={groupArrowClick}>
            {showGroups && (
              <span className={`material-icons ${classes.Arrow}`}>expand_more</span>
            )}
            {!showGroups && (
              <span className={`material-icons ${classes.Arrow}`}>chevron_right</span>
            )}
            Groups
          </h2>
          {groupConversations.length > 0 && groupConversations.map((conversation, index) => {
            return <SidebarOption
                      key={index}
                      select={selectConversation}
                      displayName={conversation.name.length > 22 ? `${conversation.name.slice(0, 22)}...` : conversation.name }
                      id={conversation._id}
                      unread_count={conversation.unread_count}
                      theme={theme}
                    />
          })}
  			</div>
  		</div>

      {/* Direct Messages */}
      <div className={`row ${classes.DirectMessageRow} ${classes.SidebarRow}`}>
  			<div className={`col-12 ${classes.DirectMessageCol}`}>
          <h2 onClick={dmArrowClick}>
            {showDM && (
              <span className={`material-icons ${classes.Arrow}`}>expand_more</span>
            )}
            {!showDM && (
              <span className={`material-icons ${classes.Arrow}`}>chevron_right</span>
            )}
            Direct Messages
          </h2>
          {(user.role === "Manager" || user.role === "Employee") && (
            <div style={{ display: "inline" }}>
              {showDM && (
                <button onClick={(e) => setShowFilter(!showFilter)} className={`${classes.OpenFilterButton}`}>
                  <span className={`material-icons`}>filter_list</span>
                </button>
              )}
              {(showDM && showFilter) && (
                <div>
                  <button id="Client" onClick={filterButtonClick} className={`${classes.FilterButton} ${classes.Selected}`}>Clients</button>
                  <button id="Employee" onClick={filterButtonClick} className={`${classes.FilterButton} ${classes.Selected}`}>Employees</button>
                  <button id="Manager" onClick={filterButtonClick} className={`${classes.FilterButton} ${classes.Selected}`}>Managers</button>
                </div>
              )}
            </div>
          )}
          {directConversations.length > 0 && directConversations.map((conversation, index) => {
            let displayName = conversation.name.replace(`${user.name}`, "").trim();
            return <SidebarOption
                      key={index}
                      select={selectConversation}
                      id={conversation._id}
                      displayName={displayName.length > 22 ? `${displayName.slice(0, 22)}...` : displayName }
                      unread_count={conversation.unread_count}
                      theme={theme}
                    />;
          })}
  			</div>
  		</div>

    </div>
  );
}

export default withRouter(Sidebar);

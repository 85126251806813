import React, { useState } from 'react';
import classes from "./heading.module.scss";

const ChatHeading = (props) => {
	const theme = props.theme;
	const currentUser = props.user;

	const [showUsers, setShowUsers] = useState(false);
	const [showUserInput, setShowUserInput] = useState(false);
	const [user, setUser] = useState("");

	const [showOptions, setShowOptions] = useState(false);

	const userButtonClick = (e) => {
		e.preventDefault();
		setShowOptions(false);
		setShowUsers(!showUsers);
		setShowUserInput(false);
	}

	const addUserClick = (e) => {
		setShowUserInput(true);
	}

	const onChange = (e) => {
		switch (e.target.name) {
			case 'user':
				setUser(e.target.value);
				props.setAddUserError("");
				break;
			default:
				break;
		}
	}

	const optionsButtonClick = (e) => {
		e.preventDefault();
		setShowUsers(false);
		setShowOptions(!showOptions);
	}

	const leaveGroupMessageClick = (e) => {
		setShowOptions(false);
		props.leaveGroupMessage(e);
	}

	const deleteGroupConversation = (e) => {
		setShowOptions(false);
		props.deleteGroupConversation(e, props.conversation._id);
	}

	const sidebarButtonClick = (e) => {
		setShowOptions(false);
		setShowUsers(false);
		props.showSidebar(e);
	}

	let title = "";
	if (props.conversation) title = props.conversation.name.replace(`${currentUser.name}`, "").trim();

	return (
		<div>
			{props.conversation && (
				<div style={{ margin: "0px" }} className={`row ${classes.HeadingRow}`}>
					<div className={`col-12 ${classes.HeadingCol}`}>
						<button className='d-md-none' style={{float: "left"}} onClick={sidebarButtonClick}><span style={{ marginTop: "5px" }} className={`material-icons`}>menu</span></button>
						<h1>{title}</h1>
						<button onClick={optionsButtonClick}><span style={{ marginTop: "5px" }} className={`material-icons`}>more_horiz</span></button>
						<button onClick={userButtonClick}><span style={{ marginTop: "3px" }} className={`material-icons`}>person</span></button>
					</div>
				</div>
			)}
			{showUsers && (
				<div className={`${classes.UsersModal}`}>
					<h1 style={{ marginBottom: "15px" }}>Users</h1>
					{props.conversation.display_names.map((user, index) => {
						if (props.conversation.type === "Group") {
							if (props.conversation.admin_ids.includes(currentUser.id)) {
								return <p key={index}><span className={`material-icons ${classes.RemoveButton}`} onClick={(e) => props.removeGroupMessageUser(e, user)}>cancel</span> {user}</p>;
							} else {
								return <p key={index}>{user}</p>;
							}
						} else {
							return <p key={index}>{user}</p>;
						}
					})}
					{props.conversation.type === "Group" && (
						<div>
							{!showUserInput && (
								<p onClick={addUserClick} style={{ color: "blue", cursor: "pointer" }}>+ Add user</p>
							)}
							{showUserInput && (
								<div>
									<input
	                  name="user"
	                  type="text"
	                  value={user}
	                  onChange={onChange}
	                  list="addUsersList"
	                  className={`form-control col-12`}
	                  autoComplete="off"/>
	                <p style={{ color: "red", textAlign: "center" }}>{props.addUserToGroupError}</p>
	                <button
	                  className={`${classes.Button}`}
	                  style={{ backgroundColor: `${theme.primary_color}`, marginTop: "10px" }}
	                  onClick={(e) => {props.addUser(e, user, setUser)}}>
	                    Add
	                </button>
	                <datalist id="addUsersList">
										{props.userList.length > 0 && (
											props.userList.map((user, index) => {
												let displayName = user.name;
	                      return <option key={index}>{displayName}</option>
											})
										)}
	                </datalist>
								</div>
							)}
						</div>
					)}
				</div>
			)}
			{showOptions && (
				<div className={`${classes.UsersModal}`}>
					<h1>Options</h1>
					{props.conversation.type === "Group" && (
						<div>
							<div className={`${classes.Option}`}>
								<button style={{ backgroundColor: "white", color: "red", borderRadius: "3px" }} onClick={leaveGroupMessageClick}>
									<p style={{ color: "red" }} >Leave Group</p>
									<span className={`material-icons`}>exit_to_app</span>
								</button>
							</div>
							{props.conversation.admin_ids.includes(currentUser.id) && (
								<div className={`${classes.Option}`}>
									<button style={{ backgroundColor: "red", color: "white", borderRadius: "3px" }} onClick={deleteGroupConversation}>
										<p style={{ color: "white" }} >Delete Group</p>
										<span className={`material-icons`}>delete_forever</span>
									</button>
								</div>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default ChatHeading;

import React, { useState } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/layout";
import classes from "./onboardingcontact.module.scss";

const OnboardingContact = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [line1, setLine1] = useState("");
  const [line1ErrorMessage, setLine1ErrorMessage] = useState("");
  const [city, setCity] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [state, setState] = useState("");
  const [stateErrorMessage, setStateErrorMessage] = useState("");
  const [zip, setZip] = useState("");
  const [zipErrorMessage, setZipErrorMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case 'line1':
        setLine1(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'state':
        setState(e.target.value);
        break;
      case 'zip':
        setZip(e.target.value);
        break;
      default:
        break;
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    const params = {
      line1: line1,
      city: city,
      state: state,
      zip: zip
    };
    axios.put("/api/employees/contact", params)
      .then(response => {
        //console.log(response);
        props.history.push('/onboarding/files');
      })
      .catch(err => {
        //TODO: update error messages
      })
  };

  return (
    <Layout loaded={true} subscription={true}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            Provide your <b>Info</b> below
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate onSubmit={onSubmit}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2>Address:</h2>
                <input
                  onChange={onChange}
                  value={line1}
                  id="line1"
                  type="text"
                  placeholder="Address"
                />
                <p className={`${classes.ErrorMessage}`}>{line1ErrorMessage}</p>
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-4 ${classes.FormInput}`}>
                <h2>City:</h2>
                <input
                  onChange={onChange}
                  value={city}
                  id="city"
                  type="text"
                  placeholder="City"
                />
                <p className={`${classes.ErrorMessage}`}>{cityErrorMessage}</p>
              </div>

              <div className={`col-sm-12 col-md-4 ${classes.FormInput}`}>
                <h2>State:</h2>
                <input
                  onChange={onChange}
                  value={state}
                  id="state"
                  type="text"
                  placeholder="State"
                />
                <p className={`${classes.ErrorMessage}`}>{stateErrorMessage}</p>
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2>Zip Code:</h2>
                <input
                  onChange={onChange}
                  value={zip}
                  id="zip"
                  type="text"
                  placeholder="Zip Code"
                />
                <p className={`${classes.ErrorMessage}`}>{zipErrorMessage}</p>
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-4 col-md-3 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={buttonStyle} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(OnboardingContact);

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const EmployeeRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => ((auth.isAuthenticated === true) && (auth.user.role === "Employee")) ?
      (<Component {...props} /> ) : ( <Redirect to="/dashboard" /> )
    }
  />
);

EmployeeRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(EmployeeRoute);

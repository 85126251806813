import React, { createContext, useState,  useEffect } from 'react'
import { useSelector } from 'react-redux';
import { USER_CONNECTED, VERIFY_USER } from "../Events";
import io from 'socket.io-client';

const SocketContext = createContext(null);

export { SocketContext };

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if (auth.isAuthenticated === true) {
      if (!socket) {
        const newSocket = io.connect();
        setSocket(newSocket);
      }
    }
  }, [auth.isAuthenticated, socket]);

  useEffect(() => {
    function setUser({user, isUser}) {
      //console.log(user);
      if (!isUser) socket.emit(USER_CONNECTED, user);
    }

    if (socket) {
      socket.on('connect', () => {
        //console.log("Connected ID: ", socket.id);
      });

      //socket.on(USER_CONNECTED, (connectedUsers) => { //console.log(connectedUsers) });

      if (auth.user.id !== null) {
        //console.log("Auth User: ", auth.user);
        socket.emit(VERIFY_USER, auth.user.id, setUser);
      }
    }
  }, [socket, auth.user.id]);

  return (
    <SocketContext.Provider value={[socket, setSocket]}>
      {children}
    </SocketContext.Provider>
  );
}

export default SocketProvider;

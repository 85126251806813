import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Spinner from "../../../components/layout/spinner/spinner";
import classes from "./employees.module.scss";
import Fade from "../../../components/layout/fade/fade";
import QuestionHelp from "../../../components/questionhelp";

const Employees = (props) => {
  const {
    theme,
    user
  } = props;
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState("");
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [reload, setReload] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    if (reload) {
      axios.get("/api/settings/")
        .then(response => {
          //console.log(response);
          if (response.data.employee_onboarding_files) {
            setFiles(response.data.employee_onboarding_files);
            setReload(false);
            setLoaded(true);
          } else {
            setReload(false);
            setLoaded(true);
          }
        })
        .catch(err => {
          //console.log(err);
        })
    }
  }, [reload])

  const onChange = (e) => {
    switch (e.target.id) {
      case 'new_file':
        setNewFile(e.target.files[0]);
        setFileErrorMessage("");
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      default:
        break;
    }
  }

  async function uploadFile(e) {
    try {
      if (newFile === "") {
        setFileErrorMessage("No File Selected");
        return;
      }
      setShowSpinner(true);

      let fileUpload = new FormData();
      fileUpload.append('file', newFile);
      let fileUploadResponse = await axios.post(`/api/files/${user._id.toString()}`, fileUpload);
      let settingsParams = {
        filename: fileUploadResponse.data.filename,
        location: fileUploadResponse.data.location
      };
      await axios.put('/api/settings/employee/file', settingsParams);
      //console.log(settingsResponse);
      let newFileList = [...files];
      newFileList.push({ filename: fileUploadResponse.data.filename, location: fileUploadResponse.data.location });
      setFiles(newFileList);
      setShowSpinner(false);
      setShowSuccess(true);

    } catch(err) {
      //console.log(err);
      setFileErrorMessage("Something went wrong!");
    }
  }

  async function deleteFile(e, file) {
    try {
      await axios.delete(`/api/settings/employee/file/${file.filename}`);
      //console.log(settingsResponse.data);
      axios.delete(`/api/files/${file.filename}`)
        .then(response => {
          setReload(true);
        })
        .catch(err => {
          //console.log(err);
        });
    } catch(err) {
      //console.log(err);
    }
  }

  return (
    <Fade show={true}>
      {!loaded && (
        <div className={classes.spinner}>
          <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
        </div>
      )}
      {loaded && (
        <div className={`row`}>
          <div className={`col ${classes.SettingsContent}`}>

            <div className={`row ${classes.ThemeModule}`}>
              <div className={`col-9`}>
                <h1>Employees</h1>
              </div>
            </div>

            <div className={`row ${classes.ThemeModule}`}>
              <div className={`row`}>
                <div className={`col-10`}>
                  <h2>New Employee Onboarding Files:</h2>

                  {files.map((file, index) => {
                    return (
                      <div key={index} className={`${classes.File}`}>
                        <a href={file.location} download>{file.filename}</a>
                        <button onClick={(e) => deleteFile(e, file)}><span className={`material-icons align-middle`}>close</span></button>
                      </div>
                    )
                  })}
                  {files.length < 1 && (<p style={{ color: "red" }}>No files uploaded</p>)}

                </div>
              </div>
            </div>

            <div className={`row ${classes.ThemeModule}`}>
              <h2>
                Upload New Onboarding File
                <QuestionHelp message="Upload any forms new employees need to fill out (w9s, NDAs, etc)."/>
              </h2>
              <div className={`row`} style={{margin:"0px"}}>
                <div className={`col-9`} style={{padding:"0px"}}>
                  <input className={`form-control ${classes.FileInput}`} type="file" id="new_file" onChange={onChange}/>
                </div>
                <div className={`col-3`} style={{padding:"0px"}}>
                  <button
                    className={`${classes.UploadButton}`}
                    style={buttonStyle}
                    type="button"
                    onClick={uploadFile}
                  >Upload</button>
                </div>
              </div>

              <div className={`row`}>
                <div className={`col-xs-12 col-sm-2 ${classes.FileUploadIndicator}`}>
                  <p className={classes.ErrorMessage}>{fileErrorMessage}</p>
                  {showSpinner && (
                    <Spinner color={theme.primary_color} width={"30px"} height={"30px"}/>
                  )}
                  {showSuccess && (
                    <span style={{ color: "green" }} className="material-icons">check_circle_outline</span>
                  )}
                </div>
              </div>

            </div>

          </div>
        </div>
      )}
    </Fade>
  );
}

export default withRouter(Employees);

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import Table from "../../components/table/table";
import classes from "./employeeunread.module.scss";

const EmployeeMessages = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [employees, setEmployees] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const employeeColumns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Department',
      accessor: 'department'
    },
    {
      Header: 'Unread Messages',
      accessor: 'unread_count'
    },
    {
      Header: '',
      accessor: '_id',
      Cell: ({value}) => { return <button style={buttonStyle} className={classes.SelectButton} onClick={(e) => onClick(e, value)}>View Inbox</button> }
    }
  ];

  useEffect(() => {
    axios.get('/api/employees/unread-messages')
      .then(response => {
        setEmployees(response.data);
        setLoaded(true);
      })
      .catch(err => {
        if (err.response.status === 404) {
          setErrorMessage("No Employees Found");
          setLoaded(true);
        } else {
          setErrorMessage("Something went wrong!");
          setLoaded(true);
        }
      })
  }, []);

  const goBack = (e) => {
    e.preventDefault();
    props.history.push("/dashboard");
  }

  const onClick = (e, id) => {
    props.history.push(`/messages/employee/${id}`);
  }


  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10`}>
          <h1 className={classes.Heading}>Employee Messages</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10 ${classes.TableCol}`}>
          <Table data={employees} columns={employeeColumns} onClick={() => {}} />
          <p className={classes.error}>{errorMessage}</p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-xs-12 col-sm-4 ${classes.ButtonCol}`}>
          <button
            style={buttonStyle}
            onClick={goBack}
            className={`${classes.Button}`}
          >Back</button>
        </div>
      </div>
    </Layout>
  )
}

export default withRouter(EmployeeMessages);

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Spinner from "../../../components/layout/spinner/spinner";
import classes from "./assets.module.scss";
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const Assets = (props) => {
  const user = useSelector(state => state.auth.user);
  const {
    auth,
    socket,
    changes,
    resetChanges,
    updateChanges,
    dispatch,
    onboarding,
    theme,
    nextRoute,
    setLoaded
  } = props;
  const [newChanges, setNewChanges] = useState(changes);
  const [completed, setCompleted] = useState(false);

  const [logoUpload, setLogoUpload] = useState("");
  const [logoFiles, setLogoFiles] = useState("");

  const [mailingListUpload, setMailingListUpload] = useState("");
  const [mailingListFiles, setMailingListFiles] = useState("");

  const [brandingGuideUpload, setBrandingGuideUpload] = useState("");
  const [brandingGuideFiles, setBrandingGuideFiles] = useState("");

  const [fontsUpload, setFontsUpload] = useState("");
  const [fontFiles, setFontFiles] = useState("");

  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, newChanges);
          dispatch(resetChanges());
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, newChanges])

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    if (onboarding.assets_page) setCompleted(onboarding.assets_page.completed);
    if (onboarding.assets) {
      setLogoFiles(onboarding.assets.logo);
      setMailingListFiles(onboarding.assets.mailing_list);
      setBrandingGuideFiles(onboarding.assets.branding);
      setFontFiles(onboarding.assets.fonts);
    }
  }, [onboarding]);

  const onChange = e => {
    setCompleted(true);
    switch (e.target.name) {
      case 'logo':
        setLogoUpload(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      case 'mailingList':
        setMailingListUpload(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      case 'brandingGuide':
        setBrandingGuideUpload(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      case 'fonts':
        setFontsUpload(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        break;
      default:
        break;
    }
  }

  const uploadFile = (e, file, fileList, setFileList, change) => {
    if (file === "") {
      setFileErrorMessage("No File Selected");
      return;
    }
    setShowSpinner(true);

    // Update Changes
    if (!newChanges.includes(change)) {
      let addToChanges = [...newChanges];
      addToChanges.push(change);
      setNewChanges(addToChanges);
    }

    let fileUpload = new FormData();
    fileUpload.append('file', file);
    axios.post(`/api/files/${user.id}`, fileUpload)
      .then(response => {
        let newFileList = [...fileList];
        newFileList.push({ filename: response.data.filename, location: response.data.location });

        setFileList(newFileList);
        setShowSpinner(false);
        setShowSuccess(true);
      })
      .catch(err => {
        //console.log(err);
        setFileErrorMessage(err.data);
      });
  }

  const onSubmit = e => {
    setLoaded(false);
    dispatch(updateChanges(newChanges));
    e.preventDefault();
    const params = {
      completed: completed,
      assets: {
        logo: logoFiles,
        mailing_list: mailingListFiles,
        branding: brandingGuideFiles,
        fonts: fontFiles
      }
    };
    axios.put(`/api/onboarding/assets`, params)
      .then(response => {
        //console.log(response.data);
        props.history.push(nextRoute);
      })
      .catch(err => {
        //console.log(err);
        setFileErrorMessage(err.data);
      });
  }

  const goBack = e => {
    e.preventDefault();
    const params = {
      completed: completed,
      assets: {
        logo: logoFiles,
        mailing_list: mailingListFiles,
        branding: brandingGuideFiles,
        fonts: fontFiles
      }
    };
    axios.put(`/api/onboarding/assets`, params)
      .then(response => {
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err);
        setFileErrorMessage(err.data);
      });
  }

  //----------------------------------------------------------------------------

  return (
    <div>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            Upload your <b>Assets</b> below
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate>

            <p><b>Logo Files:</b> {logoFiles.length > 0 ? (
              <>
                {logoFiles.map((file, index) => {
                  return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                })}
              </>
            ) : "No Files Uploaded"}</p>

            <p><b>Mailing List Files:</b> {mailingListFiles.length > 0 ? (
              <>
                {mailingListFiles.map((file, index) => {
                  return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                })}
              </>
            ) : "No Files Uploaded"}</p>

            <p><b>Branding Guide Files:</b> {brandingGuideFiles.length > 0 ? (
              <>
                {brandingGuideFiles.map((file, index) => {
                  return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                })}
              </>
            ) : "No Files Uploaded"}</p>

            <p><b>Font Files:</b> {fontFiles.length > 0 ? (
              <>
                {fontFiles.map((file, index) => {
                  return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                })}
              </>
            ) : "No Files Uploaded"}</p>

            <hr/>

            <label className="form-label">Upload New Logo File:</label>
            <div className={`row`} style={{margin:"0px"}}>
              <div className={`col-9`} style={{padding:"0px"}}>
                <input className={`form-control ${classes.FileInput}`} type="file" name="logo" onChange={onChange}/>
              </div>
              <div className={`col-3`} style={{padding:"0px"}}>
                <button
                  className={`${classes.UploadButton}`}
                  style={buttonStyle}
                  type="button"
                  onClick={(e) => uploadFile(e, logoUpload, logoFiles, setLogoFiles, "Added New Logo File")}
                >Upload</button>
              </div>
            </div>

            <label className="form-label">Upload New Mailing List File:</label>
            <div className={`row`} style={{margin:"0px"}}>
              <div className={`col-9`} style={{padding:"0px"}}>
                <input className={`form-control ${classes.FileInput}`} type="file" name="mailingList" onChange={onChange}/>
              </div>
              <div className={`col-3`} style={{padding:"0px"}}>
                <button
                  className={`${classes.UploadButton}`}
                  style={buttonStyle}
                  type="button"
                  onClick={(e) => uploadFile(e, mailingListUpload, mailingListFiles, setMailingListFiles, "Added New Mailing List File")}
                >Upload</button>
              </div>
            </div>

            <label className="form-label">Upload New Branding Guide File:</label>
            <div className={`row`} style={{margin:"0px"}}>
              <div className={`col-9`} style={{padding:"0px"}}>
                <input className={`form-control ${classes.FileInput}`} type="file" name="brandingGuide" onChange={onChange}/>
              </div>
              <div className={`col-3`} style={{padding:"0px"}}>
                <button
                  className={`${classes.UploadButton}`}
                  style={buttonStyle}
                  type="button"
                  onClick={(e) => uploadFile(e, brandingGuideUpload, brandingGuideFiles, setBrandingGuideFiles, "Added New Branding Guide File")}
                >Upload</button>
              </div>
            </div>

            <label className="form-label">Upload New Fonts File:</label>
            <div className={`row`} style={{margin:"0px"}}>
              <div className={`col-9`} style={{padding:"0px"}}>
                <input className={`form-control ${classes.FileInput}`} type="file" name="fonts" onChange={onChange}/>
              </div>
              <div className={`col-3`} style={{padding:"0px"}}>
                <button
                  className={`${classes.UploadButton}`}
                  style={buttonStyle}
                  type="button"
                  onClick={(e) => uploadFile(e, fontsUpload, fontFiles, setFontFiles, "Added New Font File")}
                >Upload</button>
              </div>
            </div>

            <p className={classes.ErrorMessage}>{fileErrorMessage}</p>
            <div className={`row`}>
              <div className={`col-xs-12 col-sm-2 ${classes.FileUploadIndicator}`}>
                {showSpinner && (
                  <Spinner color={theme.primary_color} width={"30px"} height={"30px"}/>
                )}
                {showSuccess && (
                  <span style={{ color: "green" }} className="material-icons">check_circle_outline</span>
                )}
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={goBack}>Save & Exit</button>
              </div>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Assets);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import classes from "./client.module.scss";
import Layout from "../../../components/layout/layout";

const ClientView = (props) => {
  const auth = props.user;
  const theme = props.theme;
  const [user, setUser] = useState(null);
  const [onboarding, setOnboarding] = useState("");
  const [requiredTasks, setRequiredTasks] = useState(1);
  const [completeTasks, setCompleteTasks] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios.get("/api/clients/dashboard")
      .then(response => {
        //console.log(response.data);
        setOnboarding(response.data.onboarding);
        setRequiredTasks(response.data.required);
        setCompleteTasks(response.data.completed);
        setUnreadCount(response.data.unreadCount);
        setLoaded(true);
        // Detect if they have started onboarding yet. Route them to onboarding automatically if they haven't.
        // The set variables above don't work here for some reason. Use response.data instead.
        if (response.data.required == 0 && response.data.completed == 0) props.history.push('/onboarding/services');
      })
      .catch(err => {
        //console.log(err);
      });
  }, [])

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`
  };

  const messagesClick = (e) => {
    props.history.push('/messages');
  }

  const onboardingClick = (e) => {
    if (onboarding) {
      if (onboarding.social || onboarding.webdev || onboarding.reputation || onboarding.seo || onboarding.branding) {
        let { social, webdev, reputation } = onboarding;
        if (social || webdev || reputation) props.history.push('/onboarding/accounts');
        else props.history.push('/onboarding/assets');
      } else props.history.push('/onboarding/services');
    } else props.history.push('/onboarding/services');
  }

  const accountClick = (e) => {
    props.history.push('/account');
  }

  const securityClick = (e) => {
    props.history.push("/settings/security");
  }

  const settingsClick = (e) => {
    props.history.push("/settings/account");
  }

  const brandingFormClick = (e) => {
    props.history.push("/form/branding");
  }

  const socialFormClick = (e) => {
    props.history.push("/form/social");
  }

  return(
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div className={`${classes.content}`}>
        <h1>Dashboard</h1>
        <div className={`row`}>
          <div className={`col`}>
            <div className={classes.half_module}>
              <h2>Messages</h2>
              <p>You have <b>{unreadCount}</b> unread messages</p>
              <button style={buttonStyle} onClick={messagesClick}>Inbox</button>
            </div>
          </div>
          <div className={`col`}>
            <div className={`${classes.half_module}`}>
              <h2>Account</h2>
              <p onClick={settingsClick} className={classes.pointer}>Contact Info</p>
              <p onClick={accountClick} className={classes.pointer}>Billing Info</p>
              <p onClick={securityClick} className={classes.pointer}>Security</p>
            </div>
          </div>
        </div>
        <div className={`row`}>
          <div className={`col`}>
            <div className={`${classes.half_module}`}>
              <h2>Forms</h2>
              {onboarding.social && <p onClick={socialFormClick} className={classes.pointer}>Target Persona Questionnaire</p>}
              {onboarding.branding && <p onClick={brandingFormClick} className={classes.pointer}>Branding Questionnaire</p>}
              {(!onboarding.social && !onboarding.branding) && <p style={{ color: "red" }}>You have no forms to view.</p>}
            </div>
          </div>
          <div className={`col`}>
            <div className={`${classes.half_module}`}>
              <h2>Onboarding</h2>
              <table className={classes.vacation_table}>
                <tbody>
                  <tr>
                    <th>Completed</th>
                    <th>Incomplete</th>
                  </tr>
                  <tr>
                    <td>{completeTasks}</td>
                    <td>{requiredTasks - completeTasks}</td>
                  </tr>
                </tbody>
              </table>
              <button onClick={onboardingClick} style={buttonStyle}>Complete Onboarding</button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(ClientView);

import React from 'react';
import days from 'dayjs';
import classes from "./messages.module.scss";

const Messages = (props) => {
  const {
    user,
    theme,
    messages
  } = props;

  return (
      <div>
        {messages.length > 0 && messages.map((message, index) => {
          let messageClass = "";
          let style = {};
          if (user._id === message.sender_id) {
            messageClass = classes.UserMessage;
            style = { backgroundColor: `${theme.primary_color}`, color: `${theme.primary_text_color}`};
          } else {
            messageClass = classes.Message;
            style = { backgroundColor: `${theme.accent_color}`, color: `${theme.accent_text_color}`};
          }
          return (
            <div key={index} className={`row`} style={{ padding: "0px", margin: "0px" }}>
              <div key={index} className={`col-12`} style={{ padding: "0px", margin: "0px" }}>
                <div className={messageClass} style={style}>
                  <div className={`${classes.SenderTag}`}>
                    <h3 className={`${classes.MessageUsername}`}>
                      {`${message.sender_name}`}<span className={`${classes.MessageTime}`}>{days(message.date).format('h:mmA M/D/YY')}</span>
                    </h3>
                  </div>
                  <div className={`${classes.Content}`}>
                    {message.type === "text" && (
                      <p className={`${classes.MessageText}`}>{message.message}</p>
                    )}
                    {message.type === "file" && (
                      <div>
                        {message.file_type.includes('image') && (
                          <img src={message.file_location} className={`${classes.Image}`} alt={message.file_name}/>
                        )}
                        {!message.file_type.includes('image') && (
                          <a href={message.file_location} download>{message.file_name}</a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
  );
}

export default Messages;

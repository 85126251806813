const NationalDays = {
  january: [
    "January 1 - New Year's Day",
    "January 4 - National Trivia Day",
    "January 4 - National Spaghetti Day",
    "January 17 - MLK Jr. Day",
    "January 20 - National Cheese Lover's Day",
    "January 21 - Squirrel Appreciation Day",
    "January 24 - Beer Can Appreciation Day",
    "January 24 - National Compliment Day",
    "January 27 - National Chocolate Cake Day",
    "January 28 - Have Fun At Work Day",
    "January 31 - National Hot Chocolate Day",

    "New Year's Resolution Week (First Week in January)",
    "National Mocktail Week (Second Week in January)",
    "National Pizza Week (Week of January 9)",

    "National Soup Month",
    "National Slow Cooking Month",
    "National Sunday Supper Month"
  ],

  february: [
    "Feb 1 - National Freedom Day",
    "Feb 2 - Groundhog Day",
    "Feb 4 - Homemade Soup Day",
    "Feb 4 - National Wear Red Day",
    "Feb 9 - National Pizza Day",
    "Feb 13 - Super Bowl",
    "Feb 13 - Galentine's Day",
    "Feb 14 - Valentine's Day",
    "Feb 15 - President's Day",
    "Feb 16 - Fat Tuesday",
    "Feb 17 - National Random Acts of Kindness Day",
    "Feb 18 - National Drink Wine Day",
    "Feb 19 - National Caregivers Day",
    "Feb 20 - National Love Your Pet Day",
    "Feb 21 - President's Day",
    "Feb 22 - National Margarita Day",

    "Random Acts of Kindness Week - the week of Feb. 17",
    "National FFA Week",

    "Black History Month",
    "American Heart Month/ National Heart Month"
  ],

  march: [
    "March 2 - World Teen Mental Wellness Day",
    "March 3 - National Mulled Wine",
    "March 4 - Dress in Blue Day Raise Awareness for colon cancer",
    "March 4 - Employee Appreciation Day",
    "March 8 - International Women's Day",
    "March 13 - Daylight Savings Time",
    "March 14 - Pi Day",
    "March 14 - National Napping Day (day after return of Daylight Savings Day)",
    "March 17 - St. Patrick's Day",
    "March 20 - First day of spring",
    "March 22 - National Ag Day",
    "March 23 - National Chip & Dip Day",
    "March 23 - National Puppy Day",
    "March 24 - National Cocktail Day",
    "March 29 - #MomAndPopBusinessOwnersDay",

    "International Women's Week (week of March 8)",

    "National Noodle Month",
    "NBA March Madness"
  ],

  april: [
    "April 1 - April Fools Day",
    "April 2 - Good Friday",
    "April 7 - National Beer Day",
    "April 11 - National Pet Day",
    "April 15 - Tax Day",
    "April 15 - Good Friday",
    "April 15 - National Clean Out Your Medicine Cabinet",
    "April 17 - Easter",
    "April 22 - Earth Day",
    "April 29 - National Arbor Day",
    "April 30 - Adopt a Shelter Pet Day",

    "National Alcohol Awareness Month",
    "National Cannabis Awareness Month",
    "Stress Awareness Month"
  ],

  may: [
    "May 3 - Teacher Appreciation Day",
    "May 4 - Star Wars Day",
    "May 5 - Cinco de Mayo",
    "May 6 - National Nurses Day",
    "May 8 - Mother's Day",
    "May 10 - National Shrimp Day",
    "May 16 - Mimosa Day",
    "May 25 - National Wine Day",
    "May 30 - Memorial Day",

    "Small Business Week - First Week In May",
    "National Pet Week - First Sunday through Monday of May",
    "American Craft Beer Week - Second Full Week in May",

    "Mental Health Awareness Month"
  ],

  june: [
    "June 4 - National Bubbly Day",
    "June 8 - National Best Friends Day",
    "June 11 - National Rosé Day",
    "June 14 - Flag Day",
    "June 19 - Juneteenth",
    "June 20 - Father's Day",
    "June 21 - First Day of Summer",
    "June 21 - National Selfie Day",
    "June 30 - Social Media Day",

    "National Gardening Week - First Full Week",
    "Men's Health Week - June 14- 20",

    "PRIDE"
  ],

  july: [
    "July 4 - Independence Day",
    "July 6 - National Fried Chicken Day",
    "July 10 - Pina Colada Day",
    "July 10 - National Kitten Day",
    "July 11- Mojito Day",
    "July 17 - World Emoji Day",
    "July 18 - National Ice Cream Day",
    "July 21 - Get To Know Your Customers Day",
    "July 25 - Wine and Cheese Day",
    "July 27 - National Love is Kind Day",
    "July 28 - National Milk Chocolate Day",
    "July 29 - National Chicken Wing Day",

    "National Ice Cream Month"
  ],

  august: [
    "August 1 - National Friendship Day",
    "August 1 - National Sister's Day",
    "August 4 - National Chocolate Chip Cookie Day",
    "August 4 - National IPA Day",
    "August 5 - International Beer Day",
    "August 9 - National Book Lovers Day",
    "August 16 - Tell a Joke Day",
    "August 17 - National Nonprofit Day",
    "August 20 - World Honey Bee Day",
    "August 26 - National Dog Day",
    "August 28 - National Red Wine Day",
    "August 31 - National Eat Outside Day",

    "Drive Sober or Get Pulled Over National Weeks- Two weeks before Labor Day",

    "Black Businesses Month",
    "Family Fun Month",
    "Back to School Month",
    "National Golf Month",
    "National Wellness Month",
    "National Sandwich Month"
  ],

  september: [
    "September 5 - Labor Day",
    "September 6 - Read a Book Day",
    "September 7 - National Beer Lover's Day",
    "September 11 - Patriot day",
    "September 11 - Grandparents Day",
    "September 14 - National Sober Day",
    "September 19 - Talk Like A Pirate Day (have some fun with this one!)",
    "September 22 - First Day of Fall",
    "September 28 - Ask a Stupid Question Day",
    "September 28 - National Drink Beer Day",
    "September 29 - National Coffee Day",
    "September 30 - National Hot Mulled Cider Day",

    "National Suicide Prevention Week - Second Full Week of Sept.",
    "National Fall Foliage Week - Begins Last Sunday of September",

    "National Recovery Month",
    "Self-Care Awareness Month",
    "National Suicide Prevention Month"
  ],

  october: [
    "October 1 - World Vegetarian Day",
    "October 4 - National Golf Lover's Day",
    "October 4 - National Taco",
    "October 5 - World Teachers Day",
    "October 5 - Do Something Nice Day",
    "October 6 - National Noodle Day",
    "October 10 - World Mental Health Day",
    "October 14 - National Dessert Day",
    "October 15 - Information Overload Day",
    "October 15 - Sweetest Day",
    "October 17- National Pasta Day",
    "October 20 - Get To Know Your Customers Day",
    "October 22 - National Make A Difference Day",
    "October 27 - National American Beer Day",
    "October 28 - Chocolate Day",
    "October 29 - National Cat Day",
    "October 31- Halloween",

    "Mental Illness Awareness Week - First full week in October",

    "Adopt a Shelter Dog month",
    "Breast Cancer Awareness Month"
  ],

  november: [
    "November 2 - National Stress Awareness Day",
    "November 3 - National Sandwich Day",
    "November 6 - Daylight Savings Time Ends",
    "November 8 - Voting Day",
    "November 11 - Veterans Day",
    "November 13 - World Kindness Day",
    "November 24 - Thanksgiving Day",
    "November 25 - Black Friday",
    "November 26 - Small Business Saturday",
    "November 28 - Cyber Monday",
    "November 29 - Giving Tuesday",

    "World Kindness Week November 13",

    "National Alzheimer's Disease Awareness",
    "National Gratitude Month",
    "NoSHAVEmber"
  ],

  december: [
    "December 1 - National Pie Day",
    "December 4 - National Cookie Day",
    "December 7 - Pearl Harbor Remembrance Day",
    "December 11 - National App Day",
    "December 18 - Hanukkah Begins",
    "December 20 - National Sangria Day",
    "December 21 - First Day of Winter",
    "December 24 - Christmas Eve",
    "December 25 - Christmas Day",
    "December 31 - New Year's Eve & also is #NationalChampagneDay",

    "Kwanzaa is December 26- January 1",

    "National Drunk & Drugged Driving Prevention Month"
  ]
}

export default NationalDays;

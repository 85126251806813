import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Fade from "../../../components/layout/fade/fade";
import classes from "./controller.module.scss";
import prices from "../../../assets/prices";

import Account from "./account";
import Notifications from "./notifications";
import Security from "./security";
import Employees from "./employees";
import Billing from "./billing";
import Theme from "./theme";

const Controller = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const auth = useSelector(state => state.auth.user);
  const [user, setUser] = useState(null);
  console.log(JSON.stringify(user));
  const [loaded, setLoaded] = useState(false);
  const [tier, setTier] = useState("solo");
  let path = props.match.url;
  const links = [
    { text: "Back", link: "/dashboard"},
    { text: "Your Account", link: "/settings/account"},
    { text: "Notifications", link: "/settings/notifications"},
    { text: "Login & Security", link: "/settings/security"},
    { text: "Employees", link: "/settings/employees"},
    { text: "Billing", link: "/settings/billing"},
    { text: "Theme", link: "/settings/theme"}
  ];

  /* TODO: Get settings from DB. If client/employee get the manager's settings */
  useEffect(() => {
    let route = auth.role === "Manager" ? "/api/managers" : auth.role === "Employee" ? "/api/employees" : `/api/clients/${auth.id}`;
    axios.get(route)
      .then(response => {
        setUser(response.data);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }, [auth.id, auth.role])

  useEffect(() => {
      axios.get("/api/payment/manager")
        .then(response => {
          if (response.data.price_id == prices.solo_monthly || response.data.price_id == prices.solo_yearly)
              setTier("solo");
          else if (response.data.price_id == prices.agency_monthly || response.data.price_id == prices.agency_yearly)
              setTier("agency");

        })
        .catch(err => {
          //console.log(err);
          setTier("error");
        })
  }, [])

  const linkStyle = {
    color: `${theme.accent_text_color}`
  };

  const currentLinkStyle = {
    color: `${theme.primary_color}`
  };

  const includeLink = (link) => {
    if (link === "/dashboard") return true;
    if (link === "/settings/account") return true;
    if (link === "/settings/notifications") return true;
    if (link === "/settings/client") {
      if (auth.role === "Manager") return true;
      else return false;
    }
    if (link === "/settings/security") return true;
    if (link === "/settings/employees") {
      if (auth.role === "Manager" && tier === "agency") return true;
      else return false;
    }
    if (link === "/settings/billing") {
      if (auth.role === "Manager") return true;
      else return false;
    }
    if (link === "/settings/theme") {
      if (auth.role === "Manager" && tier === "agency") return true;
      else return false;
    }
  }

  const linkClick = (e, link) => {
    props.history.push(link);
  }

  return (
    <Layout loaded={loaded} showSidebar={false} subscription={true}>

      <div className={classes.Sidebar}>
        <div className={classes.Links}>
          {links.map((item, index) => {
            if (!includeLink(item.link)) {
              return <span key={index}></span>;
            }
            else {
              if (path === item.link) {
                return <p key={index} className={classes.Link} style={currentLinkStyle} onClick={(e) => linkClick(e, item.link)}>{item.text}</p>
              } else {
                return <p key={index} className={classes.Link} style={linkStyle} onClick={(e) => linkClick(e, item.link)}>{item.text}</p>
              }
            }
          })}
        </div>
      </div>

      <div className={classes.Main}>
        <Fade show={true}>
          {path === "/settings/account" && (
            <Account theme={theme} user={user} setUser={setUser} />
          )}
          {path === "/settings/notifications" && (
            <Notifications theme={theme} user={user} />
          )}
          {path === "/settings/security" && (
            <Security theme={theme} user={user} />
          )}
          {path === "/settings/employees" && (
            <Employees theme={theme} user={user} />
          )}
          {path === "/settings/billing" && (
            <Billing theme={theme} user={user} />
          )}
          {path === "/settings/theme" && (
            <Theme theme={theme} user={user} />
          )}
        </Fade>
      </div>
    </Layout>
  );
}

export default withRouter(Controller);

import React, { useState } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter, Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import classes from "./addemployee.module.scss";

const AddEmployee = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [hireDate, setHireDate] = useState("");

  const onChange = (e) => {
    setError(false);
    setResponse("");
    switch (e.target.id) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'department':
        setDepartment(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'birthday':
        setBirthday(e.target.value);
        break;
      case 'hireDate':
        setHireDate(e.target.value);
        break;
      default:
        break;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    // if (!name || !email || !department || !phone || !birthday || !hireDate) {
    if (!name || !email) {
      setError(true);
      setResponse("Please enter a name and email for the employee.");
      return;
    }

    let params = {
      name: name,
      email: email,
      department: department,
      phone: phone,
      birthday: birthday,
      hire_date: hireDate
    };
    axios.post('/api/employees/', params)
      .then(response => {
        //console.log(response);
        setResponse("Account Created. Your employee will receive an email with login instructions");
      })
      .catch(err => {
        //console.log(err);
        setError(true);
        if (err.response.data === "\"email\" must be a valid email") {
          setResponse("Please enter a valid email");
        } else if (err.response.data === "Email already in use") {
          setResponse("Email already in use");
        } else setResponse("Something went wrong! Please refresh and try again.");
      });
  }

  const goBack = (e) => {
    props.history.push("/dashboard");
  }

  const accentTextStyle = {
    color: `${theme.accent_text_color}`
  };

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  return(
    <Layout loaded={true} showSidebar={true} subscription={true}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            Add a New Employee
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate onSubmit={onSubmit}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Name:</h2>
                <input className={`form-control`} type="text" id="name" value={name} onChange={onChange} placeholder="Employee Name" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Email Address:</h2>
                <input className={`form-control`} type="text" id="email" value={email} onChange={onChange} placeholder="Employee Email Address" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Department:</h2>
                <input className={`form-control`} type="text" id="department" value={department} onChange={onChange} placeholder="Employee Department" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Phone Number:</h2>
                <input className={`form-control`} type="text" id="phone" value={phone} onChange={onChange} placeholder="Ex. 1234567890" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Birthday:</h2>
                <input className={`form-control`} type="text" id="birthday" value={birthday} onChange={onChange} placeholder="mm/dd/yyyy" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Hire Date:</h2>
                <input className="form-control" type="text" id="hireDate" value={hireDate} onChange={onChange} placeholder="mm/dd/yyyy" />
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.TextInput}`}>
                <h2 style={accentTextStyle}>Onboarding Files:</h2>
                <p><Link to={"/settings/employees"}>Configure Onboarding Files for Employees</Link> (w9s, NDAs, etc.)</p>
              </div>
            </div>

            {error && <p className={classes.error}>{response}</p>}
            {!error && <p className={classes.success}>{response}</p>}


            <div className={`row justify-content-center`}>
              <div className={`col-sm-4 col-md-3 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={buttonStyle} onClick={goBack}>Back</button>
              </div>
              <div className={`col-sm-4 col-md-3 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={buttonStyle} onClick={onSubmit}>Create</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(AddEmployee);

import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Spinner from "../../components/layout/spinner/spinner";
import Layout from "../../components/layout/layout";
import classes from "./onboardingfiles.module.scss";
import { SocketContext } from "../../contexts/SocketContext";
import { ONBOARDING_NOTIFICATION } from "../../Events";

const OnboardingFiles = (props) => {
  const auth = useSelector(state => state.auth);
  const socket = useContext(SocketContext)[0];
  const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const [loaded, setLoaded] = useState(false);
  const [reload, setReload] = useState(true);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, ['Updated Contact Info, Finished Employee Forms']);
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, props.history])

  const [downloadFiles, setDownloadFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newFile, setNewFile] = useState("");

  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    axios.get("/api/settings/employee")
      .then(response => {
        if (response.data.employee_onboarding_files) {
          setDownloadFiles(response.data.employee_onboarding_files);
        }
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
        setLoaded(true);
      });
  }, [])

  useEffect(() => {
    if (reload) {
      axios.get("/api/employees/files")
        .then(response => {
          setUploadedFiles(response.data.employee_onboarding_files);
          setReload(false);
        })
        .catch(err => {
          //console.log(err.response.data);
        });
    }
  }, [reload])

  const onChange = e => {
    switch (e.target.id) {
      case 'newFile':
        setNewFile(e.target.files[0]);
        setShowSpinner(false);
        setShowSuccess(false);
        setFileErrorMessage("");
        break;
      default:
        break;
    }
  }

  async function uploadFile(e) {
    try {
      if (newFile === "") {
        setFileErrorMessage("No File Selected");
        return;
      }
      setShowSpinner(true);

      let fileUpload = new FormData();
      fileUpload.append('file', newFile);
      let fileUploadResponse = await axios.post(`/api/files/${user.id}`, fileUpload);
      let fileParams = {
        filename: fileUploadResponse.data.filename,
        location: fileUploadResponse.data.location
      };
      await axios.put('/api/employees/onboarding/files', fileParams);
      let newFileList = [...uploadedFiles];
      newFileList.push({ filename: fileUploadResponse.data.filename, location: fileUploadResponse.data.location });
      setUploadedFiles(newFileList);
      setShowSpinner(false);
      setShowSuccess(true);

    } catch(err) {
      //console.log(err);
      setFileErrorMessage("Something went wrong!");
    }
  }

  async function deleteFile(e, file) {
    try {
      await axios.delete(`/api/employees/onboarding/files/${file.filename}`);
      await axios.delete(`/api/files/${file.filename}`);
      setReload(true);
    } catch(err) {
      //console.log(err);
    }
  }

  const onSubmit = e => {
    setHandleNotification(true);
  }

  //----------------------------------------------------------------------------

  return (
    <Layout loaded={loaded} showSidebar={false} subscription={true}>
      <div className={`row`} style={{ margin: "0px" }}>
        <div className={`col ${classes.Main}`}>

          <div className={`row justify-content-center`} style={{ margin: "0px" }}>
            <div className={`col-8`}>
              <h1>Onboarding Files</h1>
              <p>Please download the following files, sign them, and upload them to your account</p>
            </div>
          </div>

          <div className={`row justify-content-center`} style={{ margin: "0px" }}>
            <div className={`col-10 col-md-8 col-lg-6 ${classes.Module}`}>

              <div className={`row`}>
                <div className={`col ${classes.Section} ${classes.FileList}`}>
                  <h2>Download Files:</h2>
                  {downloadFiles.map((file, index) => {
                    return (
                      <div style={buttonStyle} key={index} className={`${classes.File}`}>
                        <a href={file.location} style={{ color: `${theme.primary_text_color}` }} download>{file.filename}</a>
                      </div>
                    )
                  })}
                  {downloadFiles.length < 1 && (<p style={{ color: "red" }}>No files required for Onboarding. Continue to Dashboard</p>)}
                </div>
              </div>

              <hr/>

              <div className={`row`}>
                <div className={`col ${classes.Section} ${classes.FileList}`}>
                  <h2>Uploaded Files:</h2>
                  {uploadedFiles.map((file, index) => {
                    return (
                      <div style={buttonStyle} key={index} className={`${classes.File}`}>
                        <a href={file.location} style={{ color: `${theme.primary_text_color}` }} download>{file.filename}</a>
                        <button style={buttonStyle} onClick={(e) => deleteFile(e, file)}><span className={`material-icons`}>close</span></button>
                      </div>
                    )
                  })}
                  {uploadedFiles.length < 1 && (<p style={{ color: "red" }}>You have no uploaded files</p>)}
                </div>
              </div>

              <hr/>

              <div className={`row`}>
                <div className={`col ${classes.Section}`}>
                  <h2>Upload New File:</h2>

                  <div className={`row`} style={{margin:"0px"}}>
                    <div className={`col-9`} style={{padding:"0px"}}>
                      <input className={`form-control ${classes.FileInput}`} type="file" id="newFile" onChange={onChange}/>
                    </div>
                    <div className={`col-3`} style={{padding:"0px"}}>
                      <button
                        className={`${classes.UploadButton}`}
                        style={buttonStyle}
                        type="button"
                        onClick={(e) => uploadFile(e)}
                      >Upload</button>
                    </div>
                  </div>

                  <p className={classes.ErrorMessage}>{fileErrorMessage}</p>
                  <div className={`row`}>
                    <div className={`col-xs-12 col-sm-2 ${classes.FileUploadIndicator}`}>
                      {showSpinner && (
                        <Spinner color={theme.primary_color} width={"30px"} height={"30px"}/>
                      )}
                      {showSuccess && (
                        <span style={{ color: "green" }} className="material-icons">check_circle_outline</span>
                      )}
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>

          <div className={`row justify-content-center`}>
            <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
              <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Next</button>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default withRouter(OnboardingFiles);

import { SET_THEME } from "./types";

const initialState = {
  theme: {
    primary_color: "#ddd",
    accent_color: "#ddd",
    thumbnail_logo: {
        filename: "default_logo.jpg",
        location: "https://jusb-honeypot.s3.us-east-2.amazonaws.com/default_toolbar_logo.jpg"
    },
    homepage_logo: {
        filename: "default_logo.jpg",
        location: "https://jusb-honeypot.s3.us-east-2.amazonaws.com/default_homepage_logo.jpg"
    }
  }
};

export default function StateFunction(state = initialState, action) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
}

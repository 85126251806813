import React from 'react';
import { withRouter } from "react-router-dom";
import classes from "./questionhelp.module.scss";

const QuestionHelp = (props) => {
  const { message } = props;
  let popupId = `${Math.random().toString().slice(0,8)}popup`;

  const enter = () => {
    document.getElementById(popupId).style.visibility = "visible";
  }

  const exit = () => {
    document.getElementById(popupId).style.visibility = "hidden";
  }

  return (
    <div className={classes.Circle}>
      <div id={popupId} className={classes.Popup}>
        <p style={{ fontSize: "12px" }}>{message}</p>
      </div>
      <span
        className={`material-icons align-middle`}
        id="help"
        onMouseEnter={enter}
        onMouseLeave={exit}
      >
        help
      </span>
    </div>
  );
}

export default withRouter(QuestionHelp);

import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../../components/layout/layout";
import Sidebar from "./sidebar/sidebar";
import ChatHeading from "./conversation/heading";
import Messages from "./conversation/messages";
import { SocketContext } from "../../../contexts/SocketContext";
import { MESSAGE_RECIEVED } from "../../../Events";
import classes from "./messageLayout.module.scss";

const MessageLayout = (props) => {
  //General assets
  const socket = useContext(SocketContext)[0];
  const theme = useSelector(state => state.themeReducer.theme);
  const [loaded, setLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [user, setUser] = useState("");

  //Messaging options
  const [directMessageUsers, setDirectMessageUsers] = useState([]);
  const [conversationList, setConversationList] = useState([]);

  //Messages
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  //--------------------------General Setup-----------------------------------

  //Get all direct message users and conversations
  useEffect(() => {
    if (user === "") {
      axios.get(`/api/conversations/userlist/${props.match.params.employee_id}`)
        .then(userListResponse => {
          //console.log(userListResponse.data);
          let directMessageUsers = userListResponse.data.users;
          let conversations = userListResponse.data.conversations;
          let user = userListResponse.data.employee;

          // Adding role to direct conversations
          let newConversations = [];
          for (let conversation of conversations) {
            if (conversation.type === "Direct") {
              let userId = conversation.user_ids.filter((id) => id !== user._id)[0];
              for (const directUser of directMessageUsers) {
                if (directUser._id === userId) {
                  newConversations.push({...conversation, role: directUser.role });
                }
              }
            } else newConversations.push(conversation);
          }

          setDirectMessageUsers(directMessageUsers);
          setConversationList(newConversations);
          setUser(user);
          setLoaded(true);
        })
        .catch(err => {
          //console.log(err)
        });
    }
  }, [user, props.match.params.employee_id]);

  //Creating Message socket events
  useEffect(() => {
    if (socket !== null && firstLoad) {
      if (conversationList.length > 0) {
        for (let conversation of conversationList) {
          const messageEvent = `${MESSAGE_RECIEVED}-${conversation._id}`;
      		//const typingEvent = `${TYPING}-${conversation._id}`;

      		//socket.on(typingEvent, updateTypingInChat(conversation._id));
      		socket.on(messageEvent, (message) => {
            setNewMessage(message);
          });
        }
      }
    }
  }, [conversationList, socket]);

  //Adding a new message to a conversation
  useEffect(() => {
    if (newMessage !== "" && selectedConversation) {
      if (selectedConversation._id === newMessage.conversation_id) {
        let newMessageList = [...messageList];
        newMessageList.push(newMessage);
        setMessageList(newMessageList);
        setNewMessage("");
      } else {
        // Increment unread_count for conversation
        let newConversationList = [];
        for (const conversation of conversationList) {
          if (conversation._id === newMessage.conversation_id) {
            newConversationList.push({...conversation, unread_count: conversation.unread_count+1});
          } else newConversationList.push(conversation);
        }
        setConversationList(newConversationList);
        setNewMessage("");
      }
    } else if (newMessage !== "") {
      // Increment unread_count for conversation
      let newConversationList = [];
      for (const conversation of conversationList) {
        if (conversation._id === newMessage.conversation_id) {
          newConversationList.push({...conversation, unread_count: conversation.unread_count+1});
        } else newConversationList.push(conversation);
      }
      setConversationList(newConversationList);
      setNewMessage("");
    }
  }, [newMessage, messageList, selectedConversation]);

  //For message notification clicks
  useEffect(() => {
    if (firstLoad) {
      if (props.match.params.id) {
        conversationList.forEach((conversation) => {
          if (conversation._id === props.match.params.id) {
            setSelectedConversation(conversation);
            getMessages(conversation._id);
            setFirstLoad(false);
          }
        });
      }
    } else {
      setFirstLoad(false);
    }
  }, [conversationList, props.match.params.id]);

  const goBack = (e) => {
    props.history.push("/employee-unread-messages");
  }

  const selectConversation = (conversation_id) => {
    conversationList.forEach((conversation) => {
      if (conversation._id === conversation_id) {
        setSelectedConversation(conversation);
        getMessages(conversation._id);
        props.history.push(`/messages/employee/${user._id}/${conversation._id}`);
      }
    });
  }

  const getMessages = (conversation_id) => {
    axios.get(`/api/messages/employee/${conversation_id}`)
      .then(response => {
        setMessageList(response.data);
        if (window.innerWidth <= 768) toggleSidebar();
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const showSidebar = (e) => {
    setCollapsed(!collapsed);
  }

  //---------------------------Messages scroll----------------------------

  const messageContainer = useRef(null);

  const scrollToMyRef = () => {
    //console.log("Scroll height: ", messageContainer.current.scrollHeight);
    //console.log("Client height: ", messageContainer.current.clientHeight);
    const scroll = messageContainer.current.scrollHeight - messageContainer.current.clientHeight;
    messageContainer.current.scrollTo(0, scroll);
  }

  useEffect(() => {
    if (loaded) scrollToMyRef();
  }, [messageList, messageContainer, loaded]);

  return (
    <Layout loaded={loaded} showSidebar={false} subscription={true}>
      <div className={`row ${classes.Content}`} style={{ margin: "0px" }}>

        {!collapsed && (
        <div className={`${classes.SidebarCol}`}>
          <Sidebar
            user={user}
            theme={theme}
            goBack={goBack}
            selected={selectedConversation}
            select={selectConversation}
            conversations={conversationList}
          />
        </div>
          )}

        <div className={`${classes.ConversationCol}`}>
          <div className={` ${collapsed ? classes.ConversationRowExpand : classes.ConversationRow}`}>
            <div className={`${classes.HeadingCol}`}>
              <ChatHeading
                user={user}
                theme={theme}
                conversation={selectedConversation}
                userList={directMessageUsers}
                showSidebar={showSidebar}
              />
            </div>
            <div ref={messageContainer} className={`${collapsed ? classes.MessagesColExpand : classes.MessagesCol}`}>
              <Messages
                messages={messageList}
                user={user}
                theme={theme}
              />
            </div>
          </div>
        </div>

      </div>

    </Layout>
  );
}

export default withRouter(MessageLayout);

import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { SET_THEME } from "../../../reducers/types";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import classes from "./theme.module.scss";
import Fade from "../../../components/layout/fade/fade";
import QuestionHelp from "../../../components/questionhelp";

const Theme = (props) => {
  const {
    theme,
    user
  } = props;
  const dispatch = useDispatch();

  const [thumbnailLogo, setThumbnailLogo] = useState("");
  const [primary, setPrimary] = useState(theme.primary_color);
  const [accent, setAccent] = useState(theme.accent_color);
  const [primaryText, setPrimaryText] = useState(theme.primary_text_color);
  const [accentText, setAccentText] = useState(theme.accent_text_color);
  const [errorMessage, setErrorMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${primary}`,
    color: `${primaryText}`
  };

  const primaryStyle = {
    backgroundColor: `${primary}`,
    color: `${primaryText}`
  };

  const accentStyle = {
    backgroundColor: `${accent}`,
    color: `${accentText}`
  }

  const setTheme = theme => {
    return {
      type: SET_THEME,
      payload: theme
    };
  };

  const onChange = (e) => {
    switch (e.target.id) {
      case 'thumbnail':
        setThumbnailLogo(e.target.files[0]);
        break;
      case 'primary':
        setPrimary(e.target.value);
        setErrorMessage(hexValidator(e.target.value));
        break;
      case 'accent':
        setAccent(e.target.value);
        setErrorMessage(hexValidator(e.target.value));
        break;
      case 'primary_text':
        setPrimaryText(e.target.value);
        setErrorMessage(hexValidator(e.target.value));
        break;
      case 'accent_text':
        setAccentText(e.target.value);
        setErrorMessage(hexValidator(e.target.value));
        break;
      default:
        break;
    }
  }

  const hexValidator = (hex) => {
    if (hex === "" || hex === null) {
      return("Please enter a valid hex color code");
    } else if (hex[0] !== '#') {
      return("Invalid color code. Please start with a #");
    } else if (hex.length !== 7 && hex.length !== 4) {
      return("Invalid color code. Please enter a # followed by 3 or 6 characters");
    } else {
      return("");
    }
  }

  async function onSubmit(e) {
    try {
      e.preventDefault();
      let thumbnailData = {
        filename: theme.thumbnail_logo.filename,
        location: theme.thumbnail_logo.location
      };
      if (thumbnailLogo !== "") {
        try {
          // Delete old thumbnail file
          await axios.delete(`/api/files/${theme.thumbnail_logo.filename}`);
        } catch(err) {
          //console.log(err);
        }
        // Upload new file to s3 and database
        let thumbnailUpload = new FormData();
        thumbnailUpload.append('file', thumbnailLogo);
        const thumbnailResponse = await axios.post(`/api/files/${user._id}`, thumbnailUpload);
        //console.log(thumbnailResponse);
        thumbnailData.filename = thumbnailResponse.data.filename;
        thumbnailData.location = thumbnailResponse.data.location;
      }

      const themeUpdate = {
        primary_color: primary,
        accent_color: accent,
        primary_text_color: primaryText,
        accent_text_color: accentText,
        thumbnail_logo: thumbnailData
      };
      const themeResponse = await axios.put("/api/themes/update", themeUpdate);
      //console.log(themeResponse);
      let newTheme = {
        primary_color: themeResponse.data.primary_color,
        accent_color: themeResponse.data.accent_color,
        primary_text_color: themeResponse.data.primary_text_color,
        accent_text_color: themeResponse.data.accent_text_color,
        thumbnail_logo: {
            filename: themeResponse.data.thumbnail_logo.filename,
            location: themeResponse.data.thumbnail_logo.location
        }
      }
      dispatch(setTheme(newTheme));
    } catch(err) {
      //console.log(err);
    }
  }

  return (
    <Fade show={true}>
      <div className={`row`}>
        <div className={`col ${classes.SettingsContent}`}>

          <div className={`row ${classes.ThemeModule}`}>
            <div className={`col-9`}>
              <h1>Theme</h1>
            </div>
          </div>

          <div className={`row ${classes.ThemeModule}`}>
            <div className={`row`}>
              <div className={`col-9`}>
                <h2>
                  Colors
                  <QuestionHelp message="Enter Color values in hex format."/>
                </h2>
              </div>
            </div>
            <div className={`row`}>
              <div className={`col-3`}>
                <p>Primary Color</p>
                <input type="text" id="primary" placeholder="ex. #000000" onChange={onChange} value={primary} />
              </div>
              <div className={`col-3`}>
                <p>Primary Text Color</p>
                <input type="text" id="primary_text" placeholder="ex. #000000" onChange={onChange} value={primaryText} />
              </div>
              <div className={`col-3`}>
                <p>Accent Color</p>
                <input type="text" id="accent" placeholder="ex. #000000" onChange={onChange} value={accent} />
              </div>
              <div className={`col-3`}>
                <p>Accent Text Color</p>
                <input type="text" id="accent_text" placeholder="ex. #000000" onChange={onChange} value={accentText} />
              </div>
            </div>
            <div className={`row`}>
              <div className={`col-12`}>
                <p style={{ color: "red" }}>{errorMessage}</p>
              </div>
            </div>
            <div className={`row justify-content-around`}>
              <div style={primaryStyle} className={`col-4 ${classes.ThemeExample}`}>
                <h1>Primary Color &amp; Primary Text Color</h1>
              </div>
              <div style={accentStyle} className={`col-4 ${classes.ThemeExample}`}>
                <h1>Accent Color &amp; Accent Text Color</h1>
              </div>
            </div>
          </div>

          <div className={`row ${classes.ThemeModule}`}>
            <div className={`row`}>
              <div className={`col-9`}>
                <h2>
                  Logo
                  <QuestionHelp message="Logo displayed in the upper left corner. Please upload a 60x60 .jpg or .png file."/>
                </h2>
              </div>
            </div>
            <div className={`row`}>
              <div className={`col-9`}>
                <p>Logo File: <a href={theme.thumbnail_logo.location} download>{theme.thumbnail_logo.filename}</a></p>
              </div>
            </div>
            <div className={`row`}>
              <div className={`col-9`}>
                <input className={`form-control ${classes.FileInput}`} type="file" id="thumbnail" onChange={onChange} />
              </div>
            </div>
          </div>

          <div className={`row`}>
            <div className={`col ${classes.ButtonCol}`}>
              <button type="button" style={buttonStyle} onClick={onSubmit}>Save Changes</button>
            </div>
          </div>

        </div>
      </div>
    </Fade>
  );
}

export default withRouter(Theme);

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import classes from './review.module.scss';
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const Review = (props) => {
  const user = useSelector(state => state.auth.user);
  const {
    auth,
    socket,
    changes,
    resetChanges,
    dispatch,
    onboarding,
    theme,
    nextRoute
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, changes);
          dispatch(resetChanges());
          props.history.push(nextRoute);
        }
      }
    }
  }, [socket, auth, handleNotification, changes])

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const onFinish = e => {
    setHandleNotification(true);
  }

  const onSubmit = e => {
    setShowModal(true);
  }

  return (
    <div>
      <Modal
        className={classes.Modal}
        show={showModal}
      >
        <Modal.Body>
          <h3>Thank you!</h3>
          <p>Your submissions were successfully uploaded</p>
          <button className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`, width: "70%"}} onClick={onFinish}>All Done</button>
        </Modal.Body>
      </Modal>

      <div>
        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
            <h1>
              Review your <b>Submissions</b>
            </h1>
          </div>
        </div>

        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-sm-10 col-xl-8`}>
            <div className={classes.Form}>
              <h5><b>{user.name}</b></h5>

              <div className={classes.ServiceList}>
                Services:
                {onboarding.social ? <span>Social&nbsp;Media</span> : <></>}
                {onboarding.webdev ? <span>Web&nbsp;Development</span> : <></>}
                {onboarding.seo ? <span>SEO</span> : <></>}
                {onboarding.branding ? <span>Branding/Rebranding</span> : <></>}
                {onboarding.reputation ? <span>Reputation&nbsp;Management</span> : <></>}
              </div>

              {(onboarding.social || onboarding.webdev || onboarding.seo || onboarding.reputation) && (
                <div className={classes.AccountList}>Accounts: <br/>
                  <div className={classes.Accounts}>
                    {onboarding.social && (
                      <>
                        {onboarding.show_facebook && (<p>Facebook Completed: {onboarding.facebook.completed?<span>Yes</span>:<></>}</p>)}

                        {onboarding.show_instagram && (<p>Instagram Handle: {onboarding.instagram.login}</p>)}
                        {onboarding.show_instagram && (<p>Instagram Password: {onboarding.instagram.password}</p>)}

                        {onboarding.show_twitter && (<p>Twitter Handle: {onboarding.twitter.login}</p>)}
                        {onboarding.show_twitter && (<p>Twitter Password: {onboarding.twitter.password}</p>)}

                        {onboarding.show_linkedin && (<p>LinkedIn Completed: {onboarding.linkedin.completed?<span>Yes</span>:<></>}</p>)}

                        {onboarding.show_pinterest && (<p>Pinterest Username: {onboarding.pinterest.login}</p>)}
                        {onboarding.show_pinterest && (<p>Pinterest Password: {onboarding.pinterest.password}</p>)}
                      </>
                    )}
                    {(onboarding.webdev || onboarding.seo) && (
                      <>
                        {onboarding.show_google && (<p>Google Business Completed: {onboarding.google.completed?<span>Yes</span>:<></>}</p>)}

                        {onboarding.show_website && (<p>Website Hosting Provider: {onboarding.website.hostingName}</p>)}
                        {onboarding.show_website && (<p>Website Username: {onboarding.website.login}</p>)}
                        {onboarding.show_website && (<p>Website Password: {onboarding.website.password}</p>)}

                        {onboarding.show_email && (<p>Email Service Link: {onboarding.email.link}</p>)}
                        {onboarding.show_email && (<p>Email Service Username: {onboarding.email.login}</p>)}
                        {onboarding.show_email && (<p>Email Service Password: {onboarding.email.password}</p>)}
                      </>
                    )}
                    {onboarding.reputation && (
                      <>
                        {onboarding.show_trip && (<p>TripAdvisor Username: {onboarding.trip.login}</p>)}
                        {onboarding.show_trip && (<p>TripAdvisor Password: {onboarding.trip.password}</p>)}

                        {onboarding.show_yelp && (<p>Yelp Username: {onboarding.yelp.login}</p>)}
                        {onboarding.show_yelp && (<p>Yelp Password: {onboarding.yelp.password}</p>)}
                      </>
                    )}
                  </div>
                </div>
              )}

              {onboarding.social && (
                <div className={classes.AccountList}>National Days/Months: <br/>
                  <div className={classes.Accounts}>
                    {onboarding.january_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.february_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.march_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.april_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.may_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.june_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.july_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.august_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.september_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.october_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.november_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {onboarding.december_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                    {(onboarding.custom_dates.length > 0 && (onboarding.custom_dates[0].date !== "" && onboarding.custom_dates[0].name !== "")) && (
                      onboarding.custom_dates.map((date, index) => {
                        return <p key={index}>{date.date} - {date.name}</p>;
                      })
                    )}
                  </div>
                </div>
              )}

              {onboarding.webdev && (
                <div className={classes.AccountList}>Website Form Emails: <br/>
                  <div className={classes.Accounts}>
                    <p>General Inquiries: {onboarding.form_emails.general}</p>
                    {onboarding.webstore && (<p>Shopping Cart: {onboarding.form_emails.webstore}</p>)}
                    <p>Leads: {onboarding.form_emails.leads}</p>
                    <p>Event RSVP/Tickets: {onboarding.form_emails.events}</p>
                  </div>
                </div>
              )}

              {onboarding.webdev && (
                <div className={classes.AccountList}>Links to Social Handles: <br/>
                  <div className={classes.Accounts}>
                    <p>Facebook: {onboarding.social_handles.facebook}</p>
                    <p>Instagram: {onboarding.social_handles.instagram}</p>
                    <p>Twitter: {onboarding.social_handles.twitter}</p>
                    <p>Linkedin: {onboarding.social_handles.linkedin}</p>
                    <p>Pinterest: {onboarding.social_handles.pinterest}</p>
                    <p>Youtube: {onboarding.social_handles.youtube}</p>
                    <p>Other: {onboarding.social_handles.other}</p>
                  </div>
                </div>
              )}

              {onboarding.questionnaires.social && (
                <div className={classes.BrandingQuestionnaire}>
                  <p>Social Media Questionnaire:</p>
                  <div className={classes.BrandingQuestionnaireList}>
                  <h6>What accounts do members of your target market follow and what are their top five most visited?</h6>
                  <p>{onboarding.questionnaires.social.one}</p>
                  <h6>Which social networks are they most active on?</h6>
                  <p>{onboarding.questionnaires.social.two}</p>
                  <h6>What emojis do they use?</h6>
                  <p>{onboarding.questionnaires.social.three}</p>
                  <h6>Are they passive scrollers or engaged and involved?</h6>
                  <p>{onboarding.questionnaires.social.four}</p>
                  <h6>
                    Do they <b>want</b> to follow brands that are: Civically engaged? Opinionated and outspoken?
                    Passive and neutral? Engaged in a social impact mission? Champions of the environment?
                  </h6>
                  <p>{onboarding.questionnaires.social.five}</p>
                  <h6>What acronyms do they use?</h6>
                  <p>{onboarding.questionnaires.social.six}</p>
                  <h6>When they&apos;re on the internet what are they doing?</h6>
                  <p>{onboarding.questionnaires.social.seven}</p>
                  <h6>
                    Are they the type that bookmarks blogs to return to weekly or the type that scrolls
                      to unwind at the end of the day??
                  </h6>
                  <p>{onboarding.questionnaires.social.eight}</p>
                  <h6>What is their current point of view on your industry or product?</h6>
                  <p>{onboarding.questionnaires.social.nine}</p>
                  <h6>What hashtags do they follow and engage with?</h6>
                  <p>{onboarding.questionnaires.social.ten}</p>
                  <h6>What are their psychographics?</h6>
                  <p>{onboarding.questionnaires.social.eleven}</p>
                  <h6>What factors do members of your target market have in common?</h6>
                  <p>{onboarding.questionnaires.social.twelve}</p>
                  <h6>What motivates them to take action? (Any action)?</h6>
                  <p>{onboarding.questionnaires.social.thirteen}</p>
                  <h6>What does &quot;fulfillment&quot; look like for them?</h6>
                  <p>{onboarding.questionnaires.social.fourteen}</p>
                  <h6>Do they have any major fears or anxieties that drive their behavior?</h6>
                  <p>{onboarding.questionnaires.social.fifteen}</p>
                  </div>
                </div>
              )}
              {onboarding.questionnaires.branding && (
                <div className={classes.BrandingQuestionnaire}>
                  <p>Branding Questionnaire:</p>
                  <div className={classes.BrandingQuestionnaireList}>
                    <h6>What is the name of your company?</h6>
                    <p>{onboarding.questionnaires.branding.one}</p>
                    <h6>Which words in your company name do you want to emphasize?</h6>
                    <p>{onboarding.questionnaires.branding.two}</p>
                    <h6>Do you want to include your tagline in your logo?</h6>
                    <p>{onboarding.questionnaires.branding.three}</p>
                    <h6>What is your company&apos;s field of business?</h6>
                    <p>{onboarding.questionnaires.branding.four}</p>
                    <h6>What age group of customers are you targeting?</h6>
                    <p>{onboarding.questionnaires.branding.five}</p>
                    <h6>What distinguishes you from other companies in this field?</h6>
                    <p>{onboarding.questionnaires.branding.six}</p>
                    <h6>Who are your competitors and what do you think about their logos?</h6>
                    <p>{onboarding.questionnaires.branding.seven}</p>
                    <h6>What is your overall business aesthetic?</h6>
                    <p>{onboarding.questionnaires.branding.eight}</p>
                    <h6>What colors do you want and not want in your logo?</h6>
                    <p>{onboarding.questionnaires.branding.nine}</p>
                    <h6>What logo layout do you prefer?</h6>
                    <p>{onboarding.questionnaires.branding.ten}</p>
                    <h6>Graphically speaking, what do you want your logo to contain?</h6>
                    <p>{onboarding.questionnaires.branding.eleven}</p>
                    <h6>What do you want to do with your logo?</h6>
                    <p>{onboarding.questionnaires.branding.twelve}</p>
                    <h6>If you have an existing logo, do you want to keep any elements of it?</h6>
                    <p>{onboarding.questionnaires.branding.thirteen}</p>
                    <h6>Which companies have your favorite logos?</h6>
                    <p>{onboarding.questionnaires.branding.fourteen}</p>
                    <h6>Do you have any other ideas of your own logo?</h6>
                    <p>{onboarding.questionnaires.branding.fifteen}</p>
                  </div>
                </div>
              )}
              <div className={classes.Assets}>
                <p><b>Logo Files:</b> {onboarding.assets.logo.length > 0 ? (
                  <>
                    {onboarding.assets.logo.map((file, index) => {
                      return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 18 ? file.filename : `${file.filename.slice(0,18)}...`}</a>;
                    })}
                  </>
                ) : "No Files Uploaded"}</p>

                <p><b>Mailing List Files:</b> {onboarding.assets.mailing_list.length > 0 ? (
                  <>
                    {onboarding.assets.mailing_list.map((file, index) => {
                      return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 18 ? file.filename : `${file.filename.slice(0,18)}...`}</a>;
                    })}
                  </>
                ) : "No Files Uploaded"}</p>

                <p><b>Branding Guide Files:</b> {onboarding.assets.branding.length > 0 ? (
                  <>
                    {onboarding.assets.branding.map((file, index) => {
                      return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 18 ? file.filename : `${file.filename.slice(0,18)}...`}</a>;
                    })}
                  </>
                ) : "No Files Uploaded"}</p>

                <p><b>Font Files:</b> {onboarding.assets.fonts.length > 0 ? (
                  <>
                    {onboarding.assets.fonts.map((file, index) => {
                      return <a key={index} href={file.location} style={buttonStyle} download>{file.filename.length < 18 ? file.filename : `${file.filename.slice(0,18)}...`}</a>;
                    })}
                  </>
                ) : "No Files Uploaded"}</p>
              </div>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-6 col-md-4 ${classes.Upload}`}>
                  <button type="submit" className={`${classes.Button}`} style={buttonStyle} onClick={onSubmit}>Save & Exit</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default withRouter(Review);

import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../reducers/authActions";
import { NotificationContext } from "../../contexts/NotificationContext";
import { SocketContext } from "../../contexts/SocketContext";
import { LOGOUT } from "../../Events";
import classes from './layout.module.css';
import prices from "../../assets/prices";

// Components
import Header from "./header/header";
import Sidebar from "./sidebar/sidebar";
import Notification from "./notification/notification";
import Fade from "./fade/fade";
import Spinner from "./spinner/spinner";

const Layout = (props) => {
  const auth = useSelector(state => state.auth);
  const theme = useSelector(state => state.themeReducer.theme);
  const [notifications, setNotifications] = useContext(NotificationContext);
  const socket = useContext(SocketContext)[0];
  const dispatch = useDispatch();

  let checkSubscription = props.subscription;
  let loaded = props.loaded;
  const [subscription, setSubscription] = useState("verifying");

  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [tier, setTier] = useState("solo");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    if (checkSubscription) {
      axios.get("/api/payment/manager")
        .then(response => {
          setSubscription(response.data.subscription_status);
          if (response.data.price_id == prices.solo_monthly || response.data.price_id == prices.solo_yearly)
              setTier("solo");
          else if (response.data.price_id == prices.agency_monthly || response.data.price_id == prices.agency_yearly)
              setTier("agency");
        })
        .catch(err => {
          //console.log(err);
          setSubscription("error");
          setTier("error");
        })
    }
  }, [auth, checkSubscription])

  useEffect(() => {
    if (notifications) {
      setNotificationsList(notifications);
    }
  }, [notifications]);

  useEffect(() => {
    let count = 0;
    notificationsList.forEach((notification) => {
      if (!notification.viewed) count++;
    });
    setNewNotificationCount(count);
  }, [notificationsList])

  const onLogoutClick = e => {
    e.preventDefault();
    let route = "/";
    if (socket) socket.emit(LOGOUT);
    dispatch(logoutUser());
    props.history.push(route);
  };

  async function manageSubscription(e) {
    try {
      let params = {
        route: `${props.match.url}`
      };
      let portalUrl = await axios.post("/api/payment/create-portal-session", params);
      window.location.assign(portalUrl.data);
    } catch(err) {
      //console.log(err);
    }
  }

  const renewSubscription = () => {
    props.history.push("/subscription/renew");
  }

  // Notification Functions
  const deleteNotification = (e, id) => {
    e.preventDefault();
    //console.log("Deleted: ", id);
    axios.delete(`/api/notifications/delete/${id}`)
      .then(notification => {
        //console.log("Notification ID: ", id);
        let newNotifications = [...notificationsList];
        //console.log(newNotifications);
        newNotifications = newNotifications.filter(note => note._id !== id);
        //console.log(newNotifications);
        setNotifications(newNotifications);
        setNotificationsList(newNotifications);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const onNotificationClick = (e, notification) => {
    setShowNotifications(false);

    if (!notification.viewed) {
      const params = {
        id: notification._id
      };
      axios.post('/api/notifications/viewed', params)
        .then(response => {
          let replaceIndex = null;
          notificationsList.forEach((note, index) => {
            if (notification._id === note._id) {
              replaceIndex = index;
            }
          });
          if (replaceIndex !== null) {
            let newNotifications = [...notificationsList];
            newNotifications[replaceIndex] = response.data;
            //setNotificationsList(newNotifications);
            setNotifications(newNotifications);
          }
        })
        .catch(err => {
          //console.log(err);
        });
    }

    props.history.push(notification.link);
  }

  const clearNotifications = (e) => {
    axios.delete("/api/notifications/all")
      .then(notifications => {
        setNotifications([]);
      })
      .catch(err => {
        console.log(err);
      })
  }

  return(
    <div className={`${classes.page}`} style={{ height: `${loaded?"calc(100vh - 70px)":"calc(100vh - 200px)"}`}}>

      {(props.match.path !== "/" && props.match.path !== "/login" && props.match.path !== "/login/x") && (
        <Header
          username={auth.user.name}
          theme={theme}
          logout={onLogoutClick}
          showSidebar={props.showSidebar}
          notificationCount={newNotificationCount}
          notificationClick={() => setShowNotifications(!showNotifications)} />
      )}

      {showNotifications && (
        <div style={{ backgroundColor: `${theme.accent_color}`, color: `${theme.accent_text_color}` }} className={`${classes.notificationModal}`}>
          <div><h1>Notifications</h1><p className={`${classes.ClearButton}`} onClick={clearNotifications}>Clear All</p></div>
          {notificationsList.length === 0 && <p style={{ color: "red", marginTop: "20px" }}>You have no Notifications</p>}
          {notificationsList.map((notification, index) => {
            return (
              <Notification
                key={index}
                message={notification.message}
                viewed={notification.viewed}
                date={notification.date}
                notificationId={notification._id}
                delete={deleteNotification}
                notification={notification}
                click={onNotificationClick}
                primary_color={theme.primary_color}
                primary_text_color={theme.primary_text_color}
                count={notification.count}
              />
            )
          })}
        </div>
      )}

      {props.showSidebar && (
        <Sidebar tier={tier} role={auth.user.role} primary_color={theme.primary_color} accent_color={theme.accent_color} primary_text_color={theme.primary_text_color} accent_text_color={theme.accent_text_color} />
      )}

      <Fade show={true}>

        {checkSubscription && (
          <>
            {(loaded && (subscription === "active" || subscription === "trialing")) && (
              <div style={{ width: `${props.showSidebar?"calc(100% - 50px)":"100%"}`, marginLeft: `${props.showSidebar?"50px":"0px"}` }} className={`${classes.content}`}>
                {props.children}
              </div>
            )}
            {(loaded && subscription !== "active" && subscription !== "verifying" && subscription !== "trialing") && (
              <div style={{ width: `${props.showSidebar?"calc(100% - 50px)":"100%"}`, marginLeft: `${props.showSidebar?"50px":"0px"}` }} className={classes.subscription}>
                <div className={classes.textbox}>
                  <h1>Your Subscription to the Honey Pot<br/> is no longer active</h1>
                  <p>To login, please update your Subscription</p>
                  {(subscription === "canceled" || subscription === "error") && (<button className={`${classes.button}`} style={buttonStyle} onClick={renewSubscription}>Renew Subscription</button>)}
                  {subscription === "past_due" && (<button className={`${classes.button}`} style={buttonStyle} onClick={manageSubscription}>Manage Subscription</button>)}
                </div>
              </div>
            )}
            {(!loaded || subscription === "verifying") && (
              <div style={{ width: `${props.showSidebar?"calc(100% - 50px)":"100%"}`, marginLeft: `${props.showSidebar?"50px":"0px"}` }} className={classes.spinner}>
                <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
              </div>
            )}
          </>
        )}

        {!checkSubscription && (
          <>
            {loaded && (
              <div style={{ width: `${props.showSidebar?"calc(100% - 50px)":"100%"}`, marginLeft: `${props.showSidebar?"50px":"0px"}` }} className={`${classes.content}`}>
                {props.children}
              </div>
            )}
            {!loaded && (
              <div style={{ width: `${props.showSidebar?"calc(100% - 50px)":"100%"}`, marginLeft: `${props.showSidebar?"50px":"0px"}` }} className={classes.spinner}>
                <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
              </div>
            )}
          </>
        )}

      </Fade>
    </div>
  );
}

export default withRouter(Layout);

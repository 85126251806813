import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import classes from "./nationalDays.module.scss";
import NationalDaysList from "./nationalDaysList";
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const NationalDays = (props) => {
  const {
    auth,
    socket,
    changes,
    resetChanges,
    updateChanges,
    dispatch,
    theme,
    onboarding,
    nextRoute,
    setLoaded
  } = props;
  const [newChanges, setNewChanges] = useState(changes);
  const [completed, setCompleted] = useState(false);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, newChanges);
          dispatch(resetChanges());
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, newChanges])

  // Control Values
  const [januarySwitch, setJanuarySwitch] = useState(false);
  const [februarySwitch, setFebruarySwitch] = useState(false);
  const [marchSwitch, setMarchSwitch] = useState(false);
  const [aprilSwitch, setAprilSwitch] = useState(false);
  const [maySwitch, setMaySwitch] = useState(false);
  const [juneSwitch, setJuneSwitch] = useState(false);
  const [julySwitch, setJulySwitch] = useState(false);
  const [augustSwitch, setAugustSwitch] = useState(false);
  const [septemberSwitch, setSeptemberSwitch] = useState(false);
  const [octoberSwitch, setOctoberSwitch] = useState(false);
  const [novemberSwitch, setNovemberSwitch] = useState(false);
  const [decemberSwitch, setDecemberSwitch] = useState(false);
  const [customDateSwitch, setCustomDateSwitch] = useState(false);

  // Date Arrays
  const [januaryDates, setJanuaryDates] = useState([]);
  const [februaryDates, setFebruaryDates] = useState([]);
  const [marchDates, setMarchDates] = useState([]);
  const [aprilDates, setAprilDates] = useState([]);
  const [mayDates, setMayDates] = useState([]);
  const [juneDates, setJuneDates] = useState([]);
  const [julyDates, setJulyDates] = useState([]);
  const [augustDates, setAugustDates] = useState([]);
  const [septemberDates, setSeptemberDates] = useState([]);
  const [octoberDates, setOctoberDates] = useState([]);
  const [novemberDates, setNovemberDates] = useState([]);
  const [decemberDates, setDecemberDates] = useState([]);
  const [customDates, setCustomDates] = useState({ val: [{ date: "", name: "" }]});

  useEffect(() => {
    if (onboarding) {
      if (onboarding.events_page) setCompleted(onboarding.events_page.completed);
      if (onboarding.january_dates) setJanuaryDates(onboarding.january_dates);
      if (onboarding.february_dates) setFebruaryDates(onboarding.february_dates);
      if (onboarding.march_dates) setMarchDates(onboarding.march_dates);
      if (onboarding.april_dates) setAprilDates(onboarding.april_dates);
      if (onboarding.may_dates) setMayDates(onboarding.may_dates);
      if (onboarding.june_dates) setJuneDates(onboarding.june_dates);
      if (onboarding.july_dates) setJulyDates(onboarding.july_dates);
      if (onboarding.august_dates) setAugustDates(onboarding.august_dates);
      if (onboarding.september_dates) setSeptemberDates(onboarding.september_dates);
      if (onboarding.october_dates) setOctoberDates(onboarding.october_dates);
      if (onboarding.november_dates) setNovemberDates(onboarding.november_dates);
      if (onboarding.december_dates) setDecemberDates(onboarding.december_dates);
      if (onboarding.custom_dates) setCustomDates({ val: onboarding.custom_dates});
    }
  }, [onboarding])

  useEffect(() => { if (januaryDates.length > 0) setJanuarySwitch(true) }, [januaryDates])
  useEffect(() => { if (februaryDates.length > 0) setFebruarySwitch(true) }, [februaryDates])
  useEffect(() => { if (marchDates.length > 0) setMarchSwitch(true) }, [marchDates])
  useEffect(() => { if (aprilDates.length > 0) setAprilSwitch(true) }, [aprilDates])
  useEffect(() => { if (mayDates.length > 0) setMaySwitch(true) }, [mayDates])
  useEffect(() => { if (juneDates.length > 0) setJuneSwitch(true) }, [juneDates])
  useEffect(() => { if (julyDates.length > 0) setJulySwitch(true) }, [julyDates])
  useEffect(() => { if (augustDates.length > 0) setAugustSwitch(true) }, [augustDates])
  useEffect(() => { if (septemberDates.length > 0) setSeptemberSwitch(true) }, [septemberDates])
  useEffect(() => { if (octoberDates.length > 0) setOctoberSwitch(true) }, [octoberDates])
  useEffect(() => { if (novemberDates.length > 0) setNovemberSwitch(true) }, [novemberDates])
  useEffect(() => { if (decemberDates.length > 0) setDecemberSwitch(true) }, [decemberDates])
  useEffect(() => {
    if (customDates.val.length > 0 && (customDates.val[0].date !== "" && customDates.val[0].name !== "")) setCustomDateSwitch(true);
  }, [customDates.val])

  useEffect(() => {
    januaryDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    februaryDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    marchDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    aprilDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    mayDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    juneDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    julyDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    augustDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    septemberDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    octoberDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    novemberDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
    decemberDates.forEach((date) => {
      let element = document.getElementById(date);
      if (element) element.checked = true;
    });
  }, [
    januaryDates,
    februaryDates,
    marchDates,
    aprilDates,
    mayDates,
    juneDates,
    julyDates,
    augustDates,
    septemberDates,
    octoberDates,
    novemberDates,
    decemberDates,
    januarySwitch,
    februarySwitch,
    marchSwitch,
    aprilSwitch,
    maySwitch,
    juneSwitch,
    julySwitch,
    augustSwitch,
    septemberSwitch,
    octoberSwitch,
    novemberSwitch,
    decemberSwitch
  ])

  function createInputs() {
    return customDates.val.map((date, index) =>
      <div key={index} className={`${classes.CustomDateContainer}`}>
        <input
          className={`${classes.CustomDate}`}
          onChange={handleDateChange.bind(index)}
          value={date.date||''}
          type="text"
          placeholder={`Date`}
        />
        <input
          className={`${classes.CustomDateName}`}
          onChange={handleNameChange.bind(index)}
          value={date.name||''}
          type="text"
          placeholder={`Name`}
        />
        <button className={`${classes.DeleteCustomDate}`} style={{ backgroundColor: "#8c0000", color: "white" }} onClick={(e) => removeClick(e, index)}>X</button>
      </div>
    );
  }

  function handleNameChange(event) {
    let values = [...customDates.val];
    values[this].name = event.target.value;
    setCustomDates({ val: values});
  }

  function handleDateChange(event) {
    let values = [...customDates.val];
    values[this].date = event.target.value;
    setCustomDates({ val: values});
  }

  const addClick = (e) => {
    e.preventDefault();
    let newDate = {
      date: "",
      name: "",
    };
    setCustomDates({ val: [...customDates.val, newDate]});
  }

  const removeClick = (e, index) => {
    e.preventDefault();
    let values = [...customDates.val];
    values.splice(index,1);
    //console.log(values);
    setCustomDates({ val: values });
  }

  const onChange = (e) => {
    switch (e.target.id) {
      case 'januarySwitch':
        setJanuarySwitch(!januarySwitch);
        break;
      case 'februarySwitch':
        setFebruarySwitch(!februarySwitch);
        break;
      case 'marchSwitch':
        setMarchSwitch(!marchSwitch);
        break;
      case 'aprilSwitch':
        setAprilSwitch(!aprilSwitch);
        break;
      case 'maySwitch':
        setMaySwitch(!maySwitch);
        break;
      case 'juneSwitch':
        setJuneSwitch(!juneSwitch);
        break;
      case 'julySwitch':
        setJulySwitch(!julySwitch);
        break;
      case 'augustSwitch':
        setAugustSwitch(!augustSwitch);
        break;
      case 'septemberSwitch':
        setSeptemberSwitch(!septemberSwitch);
        break;
      case 'octoberSwitch':
        setOctoberSwitch(!octoberSwitch);
        break;
      case 'novemberSwitch':
        setNovemberSwitch(!novemberSwitch);
        break;
      case 'decemberSwitch':
        setDecemberSwitch(!decemberSwitch);
        break;
      case 'customDateSwitch':
        setCustomDateSwitch(!customDateSwitch);
        break;
      default:
        break;
    }
  }

  const dateChange = (e, index, date, dateList, setDateList) => {
    setCompleted(true);
    if (dateList.includes(date)) {
      let newDateList = dateList.filter((x) => x !== date);
      setDateList(newDateList);
      document.getElementById(date).checked = false;
    } else {
      let newDateList = dateList;
      newDateList.push(date);
      setDateList(newDateList);
      document.getElementById(date).checked = true;
    }
    if (!newChanges.includes("Updated Events List")) {
      let addToChanges = [...newChanges];
      addToChanges.push("Updated Events List");
      setNewChanges(addToChanges);
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    setLoaded(false);
    dispatch(updateChanges(newChanges));
    const params = {
      events_page: {
        required: true,
        completed: completed
      },
      january_dates: januaryDates,
      february_dates: februaryDates,
      march_dates: marchDates,
      april_dates: aprilDates,
      may_dates: mayDates,
      june_dates: juneDates,
      july_dates: julyDates,
      august_dates: augustDates,
      september_dates: septemberDates,
      october_dates: octoberDates,
      november_dates: novemberDates,
      december_dates: decemberDates,
      custom_dates: customDates.val,
    };
    axios.put('/api/onboarding/events', params)
      .then(response => {
        props.history.push(nextRoute);
      })
      .catch(err => {
        //console.log(err)
      });
  };

  const goBack = e => {
    e.preventDefault();
    const params = {
      events_page: {
        required: true,
        completed: completed
      },
      january_dates: januaryDates,
      february_dates: februaryDates,
      march_dates: marchDates,
      april_dates: aprilDates,
      may_dates: mayDates,
      june_dates: juneDates,
      july_dates: julyDates,
      august_dates: augustDates,
      september_dates: septemberDates,
      october_dates: octoberDates,
      november_dates: novemberDates,
      december_dates: decemberDates,
      custom_dates: customDates.val,
    };
    axios.put('/api/onboarding/events', params)
      .then(response => {
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err)
      });
  }

  return (
    <div>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            Select the Events you would <br/> like to <b>Celebrate</b>
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-12 col-md-10`}>
          <form className={classes.Form} noValidate onSubmit={onSubmit}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="januarySwitch" onChange={onChange} type="checkbox" checked={januarySwitch}/>
                  <label className={`form-check-label`} htmlFor="januarySwitch">January</label>
                </div>
              </div>
            </div>
            {januarySwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.january.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, januaryDates, setJanuaryDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="februarySwitch" onChange={onChange} type="checkbox" checked={februarySwitch}/>
                  <label className={`form-check-label`} htmlFor="februarySwitch">February</label>
                </div>
              </div>
            </div>
            {februarySwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.february.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, februaryDates, setFebruaryDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="marchSwitch" onChange={onChange} type="checkbox" checked={marchSwitch}/>
                  <label className={`form-check-label`} htmlFor="marchSwitch">March</label>
                </div>
              </div>
            </div>
            {marchSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.march.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, marchDates, setMarchDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="aprilSwitch" onChange={onChange} type="checkbox" checked={aprilSwitch}/>
                  <label className={`form-check-label`} htmlFor="aprilSwitch">April</label>
                </div>
              </div>
            </div>
            {aprilSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.april.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, aprilDates, setAprilDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="maySwitch" onChange={onChange} type="checkbox" checked={maySwitch}/>
                  <label className={`form-check-label`} htmlFor="maySwitch">May</label>
                </div>
              </div>
            </div>
            {maySwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.may.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, mayDates, setMayDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="juneSwitch" onChange={onChange} type="checkbox" checked={juneSwitch}/>
                  <label className={`form-check-label`} htmlFor="juneSwitch">June</label>
                </div>
              </div>
            </div>
            {juneSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.june.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, juneDates, setJuneDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="julySwitch" onChange={onChange} type="checkbox" checked={julySwitch}/>
                  <label className={`form-check-label`} htmlFor="julySwitch">July</label>
                </div>
              </div>
            </div>
            {julySwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.july.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, julyDates, setJulyDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="augustSwitch" onChange={onChange} type="checkbox" checked={augustSwitch}/>
                  <label className={`form-check-label`} htmlFor="augustSwitch">August</label>
                </div>
              </div>
            </div>
            {augustSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.august.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, augustDates, setAugustDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="septemberSwitch" onChange={onChange} type="checkbox" checked={septemberSwitch}/>
                  <label className={`form-check-label`} htmlFor="septemberSwitch">September</label>
                </div>
              </div>
            </div>
            {septemberSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.september.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, septemberDates, setSeptemberDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="octoberSwitch" onChange={onChange} type="checkbox" checked={octoberSwitch}/>
                  <label className={`form-check-label`}>October</label>
                </div>
              </div>
            </div>
            {octoberSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.october.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, octoberDates, setOctoberDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="novemberSwitch" onChange={onChange} type="checkbox" checked={novemberSwitch}/>
                  <label className={`form-check-label`}>November</label>
                </div>
              </div>
            </div>
            {novemberSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.november.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, novemberDates, setNovemberDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="decemberSwitch" onChange={onChange} type="checkbox" checked={decemberSwitch}/>
                  <label className={`form-check-label`}>December</label>
                </div>
              </div>
            </div>
            {decemberSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>

                  {NationalDaysList.december.map((date, index) => {
                    return (
                      <div key={index} className="form-check">
                        <input
                          className={`${classes.Checkbox} form-check-input`}
                          id={date}
                          onChange={(e) => dateChange(e, index, date, decemberDates, setDecemberDates)}
                          type="checkbox"
                        />
                        <label className="form-check-label">
                          {date}
                        </label>
                      </div>
                    );
                  })}

                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-10 ${classes.MonthLabel}`}>
                <div className={`form-check form-switch ${classes.MonthLabelSwitch}`}>
                  <input className={`form-check-input`} id="customDateSwitch" onChange={onChange} type="checkbox" checked={customDateSwitch}/>
                  <label className={`form-check-label`}>Custom Dates</label>
                </div>
              </div>
            </div>
            {customDateSwitch && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 ${classes.EventsDiv}`}>
                  {createInputs()}
                  <button className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`, width: "180px"}} type="button" onClick={addClick}>+ Add Date</button>
                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={goBack}>Save & Exit</button>
              </div>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(NationalDays);

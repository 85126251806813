import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import classes from "./emailforms.module.scss";
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const EmailForms = (props) => {
  const {
    auth,
    socket,
    changes,
    resetChanges,
    updateChanges,
    dispatch,
    theme,
    onboarding,
    nextRoute,
    setLoaded
  } = props;
  const [newChanges, setNewChanges] = useState(changes);
  const [completed, setCompleted] = useState(false);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, newChanges);
          dispatch(resetChanges());
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, newChanges])

  const [general, setGeneral] = useState("");
  const [webstore, setWebstore] = useState("");
  const [leads, setLeads] = useState("");
  const [events, setEvents] = useState("");

  useEffect(() => {
    if (onboarding.emailforms_page) setCompleted(onboarding.emailforms_page.completed);
    if (onboarding.form_emails) {
      let emails = onboarding.form_emails;
      setGeneral(emails.general);
      setWebstore(emails.webstore);
      setLeads(emails.leads);
      setEvents(emails.events);
    }
  }, [onboarding])

  const addToChanges = (update) => {
    if (!newChanges.includes(update)) {
      const addChanges = [...newChanges];
      addChanges.push(update);
      setNewChanges(addChanges);
    }
  }

  const onChange = (e) => {
    setCompleted(true);
    addToChanges("Updated Website Email Forms");
    switch (e.target.id) {
      case 'general':
        setGeneral(e.target.value);
        break;
      case 'webstore':
        setWebstore(e.target.value);
        break;
      case 'leads':
        setLeads(e.target.value);
        break;
      case 'events':
        setEvents(e.target.value);
        break;
      default:
        break;
    }
  }

  const onBack = (e) => {
    e.preventDefault();
    const params = {
      completed: completed,
      forms: {
        general,
        webstore,
        leads,
        events
      }
    };
    axios.put('/api/onboarding/emails', params)
      .then(response => {
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoaded(false);
    dispatch(updateChanges(newChanges));
    const params = {
      completed: completed,
      forms: {
        general,
        webstore,
        leads,
        events
      }
    };
    axios.put('/api/onboarding/emails', params)
      .then(response => {
        //console.log(response.data);
        props.history.push(nextRoute);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <div>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>Website Form Emails</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate>

            <div className={`${classes.Label}`}>
              <h2>Email Address for General Inquiries</h2>
              <input id="general" value={general} type="text" placeholder="Email" onChange={onChange} />
            </div>

            {onboarding.webstore && (
              <div className={`${classes.Label}`}>
                <h2>Email Address for Shopping Cart</h2>
                <input id="webstore" value={webstore} type="text" placeholder="Email" onChange={onChange} />
              </div>
            )}

            <div className={`${classes.Label}`}>
              <h2>Email Address for Leads</h2>
              <input id="leads" value={leads} type="text" placeholder="Email" onChange={onChange} />
            </div>

            <div className={`${classes.Label}`}>
              <h2>Email Address for Events RSVP/Tickets</h2>
              <input id="events" value={events} type="text" placeholder="Email" onChange={onChange} />
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onBack}>Save & Exit</button>
              </div>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EmailForms);

import { React, useState, useEffect, useContext } from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setTheme } from "../../reducers/themeActions";
import { logoutUser, setCurrentUser } from "../../reducers/authActions";
import Layout from "../../components/layout/layout";
import Navbar from "../../components/navbar/navbar";
import classes from "./register.module.scss";
import prices from "../../assets/prices";

import { SocketContext } from "../../contexts/SocketContext";
import { REGISTER_NOTIFICATION } from "../../Events";

const Register = (props) => {
  const socket = useContext(SocketContext)[0];
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [validLink, setValidLink] = useState(false);
  const [linkMessage, setLinkMessage] = useState("");
  const [managerId, setManagerId] = useState("");
  const [handleNotification, setHandleNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [role, setRole] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthday, setBirthday] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [terms, setTerms] = useState(false);

  const [priceId, setPriceId] = useState("");

  useEffect(() => {
    let product = props.match.params.package;
    let term = props.match.params.term;
    if (props.match.path === "/register/:package/:term") {
      if (product === "solo") {
        if (term === "monthly") setPriceId(prices.solo_monthly);
        if (term === "annual") setPriceId(prices.solo_yearly);
      }
      else if (product === "agency") {
        if (term === "monthly") setPriceId(prices.agency_monthly);
        if (term === "annual") setPriceId(prices.agency_yearly);
      }
    }
  }, [props.match.path, props.match.params.package, props.match.params.term])

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          if (role === "Client") {
            socket.emit(REGISTER_NOTIFICATION, auth.user);
            props.history.push('/client/billing');
          } else {
            props.history.push('/dashboard');
          }
        }
      }
    }
  }, [socket, auth, handleNotification, props.history])

  useEffect(() => {
    if (props.match.path === "/register/client/:id") {
      setRole("Client");
      setLinkMessage("Verifying Link...");
      axios.get(`/api/clients/register/${props.match.params.id}`)
        .then(response => {
          //console.log(response);
          if (response.data.message === "Valid Link") {
            setValidLink(true);
            setLinkMessage("Link Verified");
            setManagerId(response.data.manager_id);
            setEmail(response.data.email);
          }
        })
        .catch(err => {
          setValidLink(false);
          setLinkMessage("Invalid Link");
        })
    } else if (props.match.path === "/register/employee/:id") {
      setRole("Employee");
      setLinkMessage("Verifying Link...");
      axios.get(`/api/employees/register/${props.match.params.id}`)
        .then(response => {
          //console.log(response);
          if (response.data.message === "Valid Link") {
            setValidLink(true);
            setLinkMessage("New Employee Link Verified");
            setManagerId(response.data.manager_id);
            setEmail(response.data.email);
            setBusinessName(response.data.business_name);
          }
        })
        .catch(err => {
          setValidLink(false);
          setLinkMessage("Invalid Link");
        })
    } else {
      setRole("Manager");
    }
  }, [props.match.path, props.match.params.id]);

  const onChange = (e) => {
    setErrorMessage("");
    switch (e.target.id) {
      case 'name':
        setName(e.target.value);
        break;
      case 'businessName':
        setBusinessName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'birthday':
        setBirthday(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      case 'passwordConfirm':
        setPasswordConfirm(e.target.value);
        break;
      case 'terms':
        setTerms(!terms);
        break;
      default:
        break;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    dispatch(logoutUser());

    if (!name || !businessName || !email || !phone ||!password || !passwordConfirm) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    if (role === "Employee" && !birthday) {
      setErrorMessage("Please fill out all fields");
      return;
    }

    if (role === "Client" && !validLink) {
      setLinkMessage("Link is Invalid. Unable to Register");
      return;
    }
    if (role === "Employee" && !validLink) {
      setLinkMessage("Link is Invalid. Contact your manager for a new link.");
      return;
    }
    if (role === "Manager" && priceId === "") {
      console.log("No Price ID");
      setErrorMessage("Invalid Subscription Plan Selected.");
      return;
    }

    let userData = {
      name: name,
      business_name: businessName,
      email: email,
      phone: phone,
      password: password,
      password_confirmation: passwordConfirm,
      role: role
    };
    if (role === "Client" || role === "Employee") userData.manager_id = managerId;
    if (role === "Employee") userData.birthday = birthday;
    try {
      // Register user in database
      let registerResponse = await axios.post("/api/auth/register", userData);
      //console.log(registerResponse.data);
      let loginData = {
        email: registerResponse.data.email,
        password: password
      };
      let loginResponse = await axios.post('/api/auth/login', loginData);
      // Set token to localStorage
      const { token } = loginResponse.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decodedPayload = jwt_decode(token);
      dispatch(setCurrentUser(decodedPayload));

      if (role === "Client" || role === "Employee") {
        let themeId = decodedPayload.manager_id;
        let themeResponse = await axios.get(`/api/themes/${themeId}`);
        let newTheme = {
          primary_color: themeResponse.data.primary_color,
          accent_color: themeResponse.data.accent_color,
          primary_text_color: themeResponse.data.primary_text_color,
          accent_text_color: themeResponse.data.accent_text_color,
          thumbnail_logo: {
              filename: themeResponse.data.thumbnail_logo.filename,
              location: themeResponse.data.thumbnail_logo.location
          }
        }
        dispatch(setTheme(newTheme));
      } else if (role === "Manager") {
        let params = {
          user_id: decodedPayload.id
        };
        let themeResponse = await axios.post(`/api/themes/create`, params);
        let newTheme = {
          primary_color: themeResponse.data.primary_color,
          accent_color: themeResponse.data.accent_color,
          primary_text_color: themeResponse.data.primary_text_color,
          accent_text_color: themeResponse.data.accent_text_color,
          thumbnail_logo: {
              filename: themeResponse.data.thumbnail_logo.filename,
              location: themeResponse.data.thumbnail_logo.location
          }
        }
        dispatch(setTheme(newTheme));
      }

      // Push to billing pages
      if (role === "Client" || role === "Employee") {
        setHandleNotification(true);
      }
      else {
        let params = {
          price_id: priceId,
          email: email,
          id: decodedPayload.id
        };
        //console.log(params);
        let paymentResponse = await axios.post('/api/payment/create-checkout-session', params);
        window.location.assign(paymentResponse.data);
      }
    } catch(err) {
      if (err.response.data === "\"email\" must be a valid email") setErrorMessage("Please Enter a valid Email");
      if (err.response.data === '"password_confirmation" must be [ref:password]') setErrorMessage("Passwords do not match");
      if (err.response.data === 'Email already exists') setErrorMessage("Email has already registered. Please try logging in.");
      else setErrorMessage("Something went wrong! Please refresh and try again");
    }
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <hr />
      <div className={`row ${classes.content}`}>
        <div className={`col-7 ${classes.form}`}>
          {role === "Manager" && (
            <>
              <h1>Start your free 14-day trial</h1>
              {props.match.params.package == "solo" && <p>After the 14 day period you will be charged the Solo Account rate.</p>}
              {props.match.params.package == "agency" && <p>After the 14 day period you will be charged the Agency Account rate.</p>}
            </>
          )}
          {role === "Client" && (
            <>
            <h1>Register</h1>
            <p style={{ color: `${validLink ? "black" : "red" }` }}>{linkMessage}</p>
            </>
          )}
          {role === "Employee" && (
            <>
            <h1>Create Your Employee Account</h1>
            <p style={{ color: `${validLink ? "black" : "red" }` }}>{linkMessage}</p>
            </>
          )}
          <form onSubmit={onSubmit}>
            <input type="text" id="name" onChange={onChange} value={name} placeholder="Full Name" className={`form-control ${classes.textInput}`}/>
            {role === "Employee" && <input disabled type="text" id="businessName" onChange={onChange} value={businessName} placeholder="Business Name" className={`form-control ${classes.textInput}`}/>}
            {(role === "Client" || role === "Manager") && <input type="text" id="businessName" onChange={onChange} value={businessName} placeholder="Business Name" className={`form-control ${classes.textInput}`}/>}
            {(role === "Client" || role === "Employee") && <input disabled type="text" id="email" onChange={onChange} value={email} placeholder="Email" className={`form-control ${classes.textInput}`}/>}
            {role === "Manager" && <input type="text" id="email" onChange={onChange} value={email} placeholder="Email" className={`form-control ${classes.textInput}`}/>}

            <input type="text" id="phone" onChange={onChange} value={phone} placeholder="Phone" className={`form-control ${classes.textInput}`}/>
            {role === "Employee" && <input type="text" id="birthday" onChange={onChange} value={birthday} placeholder="Birthday" className={`form-control ${classes.textInput}`}/>}
            <input type="password" id="password" onChange={onChange} value={password} placeholder="Password" className={`form-control ${classes.textInput}`}/>
            <input type="password" id="passwordConfirm" onChange={onChange} value={passwordConfirm} placeholder="Confirm Password" className={`form-control ${classes.textInput}`}/>
            {(role === "Client" || role === "Manager") && <div className={`form-check`}>
              <input type="checkbox" id="terms" onChange={onChange} value={terms} className={`form-check-input`}/>
              <label className={`form-check-label`}>I agree to the <span>Terms of Service</span></label>
            </div>}
            <p style={{ color: "red", margin: "20px 0px 10px" }}>{errorMessage}</p>
            {role === "Client" && (<button onClick={onSubmit} type="button">Register</button>)}
            {role === "Employee" && (<button onClick={onSubmit} type="button">Create Account</button>)}
            {role === "Manager" && (<button onClick={onSubmit} type="button">Register &amp; Checkout</button>)}
          </form>
        </div>
        <div className={`col-5 ${classes.media}`}>
          <div className={classes.picture}>Picture or Video</div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Register);

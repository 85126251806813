import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import classes from "./notifications.module.scss";
import Fade from "../../../components/layout/fade/fade";
import Spinner from "../../../components/layout/spinner/spinner";

const Notifications = (props) => {
  const {
    theme,
    user
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [all_n, set_all_n] = useState(true);
  const [all_e, set_all_e] = useState(true);

  const [message_n, set_message_n] = useState(true);
  const [message_e, set_message_e] = useState(true);

  const [register_n, set_register_n] = useState(true);
  const [register_e, set_register_e] = useState(true);

  const [onboarding_n, set_onboarding_n] = useState(true);
  const [onboarding_e, set_onboarding_e] = useState(true);

  const [vacation_n, set_vacation_n] = useState(true);
  const [vacation_e, set_vacation_e] = useState(true);

  /*
  const [milestone_n, set_milestone_n] = useState(true);
  const [milestone_e, set_milestone_e] = useState(true);
  */

  useEffect(() => {
    axios.get("/api/settings/")
      .then(response => {
        set_message_n(response.data.message_notifications.notification);
        set_message_e(response.data.message_notifications.email);
        set_register_n(response.data.registration_notifications.notification);
        set_register_e(response.data.registration_notifications.email);
        set_onboarding_n(response.data.onboarding_notifications.notification);
        set_onboarding_e(response.data.onboarding_notifications.email);
        set_vacation_n(response.data.vacation_notifications.notification);
        set_vacation_e(response.data.vacation_notifications.email);
        /*
        set_milestone_n(response.data.milestone_notifications.notification);
        set_milestone_e(response.data.milestone_notifications.email);
        */
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
        setLoaded(true);
      })
  }, [])

  useEffect(() => {
    if (message_n && register_n && onboarding_n && vacation_n) {
      set_all_n(true);
    } else set_all_n(false);
    if (message_e && register_e && onboarding_e && vacation_e) {
      set_all_e(true);
    } else set_all_e(false);
  }, [
    message_n,
    message_e,
    register_n,
    register_e,
    onboarding_n,
    onboarding_e,
    vacation_n,
    vacation_e
  ])

  const onChange = (e) => {
    setSuccessMessage("");
    switch(e.target.id) {
      case 'all_n':
        set_all_n(!all_n);
        set_message_n(!all_n);
        set_register_n(!all_n);
        set_onboarding_n(!all_n);
        set_vacation_n(!all_n);
        break;
      case 'all_e':
        set_all_e(!all_e);
        set_message_e(!all_e);
        set_register_e(!all_e);
        set_onboarding_e(!all_e);
        set_vacation_e(!all_e);
        break;
      case 'message_n':
        set_message_n(!message_n);
        break;
      case 'message_e':
        set_message_e(!message_e);
        break;
      case 'register_n':
        set_register_n(!register_n);
        break;
      case 'register_e':
        set_register_e(!register_e);
        break;
      case 'onboarding_n':
        set_onboarding_n(!onboarding_n);
        break;
      case 'onboarding_e':
        set_onboarding_e(!onboarding_e);
        break;
      case 'vacation_n':
        set_vacation_n(!vacation_n);
        break;
      case 'vacation_e':
        set_vacation_e(!vacation_e);
        break;
      default:
        break;
    }
  }

  const submit = () => {
    const params = {
      user_id: user._id.toString(),
      message_notifications: {
        notification: message_n,
        email: message_e
      },
      registration_notifications: {
        notification: register_n,
        email: register_e
      },
      onboarding_notifications: {
        notification: onboarding_n,
        email: onboarding_e
      },
      vacation_notifications: {
        notification: vacation_n,
        email: vacation_e
      },
      milestone_notifications: {
        notification: false,
        email: false
      }
    };
    axios.put("/api/settings/notifications", params)
      .then(response => {
        setSuccessMessage("Notification Preferences Updated");
      })
      .catch(err => {
        if (err.response.data === "User Settings not found") {
          axios.post("/api/settings/", params)
            .then(newResponse => {
              setSuccessMessage("Notification Preferences Updated");
            })
            .catch(err => {
              //console.log(err);
            });
        }
      });
  }

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  return (
    <Fade show={true}>
      {!loaded && (
        <div className={classes.spinner}>
          <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
        </div>
      )}
      {loaded && (
        <div className={`row`}>
          <div className={`col ${classes.SettingsContent}`}>

            <div className={`row ${classes.NotificationModule}`}>
              <div className={`col-9`}>
                <h1>Notifications</h1>
              </div>
            </div>

            {(user.role === "Manager" || user.role === "Employee") && (
              <div className={`row ${classes.NotificationModule}`}>
                <div className={`col-5`}>
                  <h2>All Notifications</h2>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="all_n" checked={all_n} />
                    <label className={`form-check-label`}>Notifications</label>
                  </div>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="all_e" checked={all_e} />
                    <label className={`form-check-label`}>Emails</label>
                  </div>
                </div>
              </div>
            )}

            <div className={`row ${classes.NotificationModule}`}>
              <div className={`col-5`}>
                <h2>Messages</h2>
                <p>Get Notified when someone sends you a message</p>
              </div>
              <div className={`col-3`}>
                <div className={`form-check form-switch`}>
                  <input className={`form-check-input`} onChange={onChange} type="checkbox" id="message_n" checked={message_n} />
                  <label className={`form-check-label`}>Notifications</label>
                </div>
              </div>
              <div className={`col-3`}>
                <div className={`form-check form-switch`}>
                  <input className={`form-check-input`} onChange={onChange} type="checkbox" id="message_e" checked={message_e} />
                  <label className={`form-check-label`}>Emails</label>
                </div>
              </div>
            </div>

            {user.role === "Manager" && (
              <>
                <div className={`row ${classes.NotificationModule}`}>
                  <div className={`col-5`}>
                    <h2>New Registration</h2>
                    <p>Get notified when a new Client or Employee registers</p>
                  </div>
                  <div className={`col-3`}>
                    <div className={`form-check form-switch`}>
                      <input className={`form-check-input`} onChange={onChange} type="checkbox" id="register_n" checked={register_n} />
                      <label className={`form-check-label`}>Notifications</label>
                    </div>
                  </div>
                  <div className={`col-3`}>
                    <div className={`form-check form-switch`}>
                      <input className={`form-check-input`} onChange={onChange} type="checkbox" id="register_e" checked={register_e} />
                      <label className={`form-check-label`}>Emails</label>
                    </div>
                  </div>
                </div>

                <div className={`row ${classes.NotificationModule}`}>
                  <div className={`col-5`}>
                    <h2>Onboarding</h2>
                    <p>Get Notified when onboarding is completed/updated by Clients or Employees</p>
                  </div>
                  <div className={`col-3`}>
                    <div className={`form-check form-switch`}>
                      <input className={`form-check-input`} onChange={onChange} type="checkbox" id="onboarding_n" checked={onboarding_n} />
                      <label className={`form-check-label`}>Notifications</label>
                    </div>
                  </div>
                  <div className={`col-3`}>
                    <div className={`form-check form-switch`}>
                      <input className={`form-check-input`} onChange={onChange} type="checkbox" id="onboarding_e" checked={onboarding_e} />
                      <label className={`form-check-label`}>Emails</label>
                    </div>
                  </div>
                </div>
              </>
            )}
            {(user.role === "Manager" || user.role === "Employee") && (
              <div className={`row ${classes.NotificationModule}`}>
                <div className={`col-5`}>
                  <h2>Vacation Requests</h2>
                  <p>Get notified when a vacation request is submitted</p>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="vacation_n" checked={vacation_n} />
                    <label className={`form-check-label`}>Notifications</label>
                  </div>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="vacation_e" checked={vacation_e} />
                    <label className={`form-check-label`}>Emails</label>
                  </div>
                </div>
              </div>
            )}

            {/*
            {user.role === "Manager" && (
              <div className={`row ${classes.NotificationModule}`}>
                <div className={`col-5`}>
                  <h2>Milestones</h2>
                  <p>Get a reminder when your team has a birthday or anniversary</p>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="milestone_n" checked={milestone_n} />
                    <label className={`form-check-label`}>Notifications</label>
                  </div>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} onChange={onChange} type="checkbox" id="milestone_e" checked={milestone_e} />
                    <label className={`form-check-label`}>Emails</label>
                  </div>
                </div>
              </div>
            )}
            */}

            <p style={{ color: "green", paddingLeft: "12px" }}>{successMessage}</p>
            <div className={`row ${classes.NotificationModule}`}>
              <div className={`col-5`}>
                <button style={buttonStyle} onClick={submit}>Update</button>
              </div>
            </div>

          </div>
        </div>
      )}
    </Fade>
  );
}

export default withRouter(Notifications);

import React, { useState } from 'react';
import classes from "./input.module.scss";
import Picker from "emoji-picker-react";

const MessageInput = (props) => {
  const theme = props.theme;
  const [message, setMessage] = useState("");
  const sendMessage = props.sendMessage;
  const [showPicker, setShowPicker] = useState(false);

  const pickerClick = (e) => {
    e.preventDefault();
    setShowPicker(!showPicker);
  }

  const onEmojiClick = (e, emojiObject) => {
    let newMessage = message + emojiObject.emoji;
    setMessage(newMessage);
  }

  const onEnterPress = (e) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      sendMessage(e, message, setMessage);
    }
  }

  return (
    <form className={`${classes.MessageInputForm}`}>

      {showPicker && (<Picker pickerStyle={{ float: "right" }} native={true} onEmojiClick={onEmojiClick}/>)}

			<div className={`${classes.InputCol}`}>
        <textarea
          value={message}
          placeholder="...Type Something"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        />
      </div>
      <div className={`d-none d-md-inline-block ${classes.EmojiCol}`}>
        <button
          className={`${classes.Button}`}
          onClick={pickerClick}
          type="button"
          style={{ backgroundColor: `${theme.primary_color}` }}>
            <span className={`material-icons`}>emoji_emotions</span>
        </button>  
      </div>
      <div className={`${classes.AttachmentCol}`}>
        <button
          className={`${classes.Button}`}
          onClick={props.showModal}
          type="button"
          style={{ backgroundColor: `${theme.primary_color}` }}>
            <span className={`material-icons`}>attachment</span>
        </button>
      </div>
      <div className={`${classes.SendCol}`}>
        <button
          disabled={message.length < 1}
          className={`${classes.Button}`}
          type="submit"
          onClick={(e) => sendMessage(e, message, setMessage)}
          style={{ backgroundColor: `${theme.primary_color}` }}>
            SEND
        </button>
			</div>
    </form>
  );
}

export default MessageInput;

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import Layout from "../../../components/layout/layout";
import classes from "./index.module.scss";
import { SocketContext } from "../../../contexts/SocketContext";
import { VACATION_APPROVE_NOTIFICATION } from "../../../Events";

const Calendar = (props) => {
  const auth = useSelector(state => state.auth);
  const socket = useContext(SocketContext)[0];
  const theme = useSelector(state => state.themeReducer.theme);
  const path = props.match.path;
  const [events, setEvents] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [reload, setReload] = useState(true);

  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [note, setNote] = useState("");
  const [status, setStatus] = useState("");
  const [requestUserId, setRequestUserId] = useState("");
  const [decision, setDecision] = useState(null);
  const [requestId, setRequestId] = useState(null);

  const [response, setResponse] = useState("");

  const calendar = useRef(null);
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification && decision) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          let request = {
            id: requestId,
            user_id: requestUserId,
            start: start,
            end: end,
            note: note,
            response: response,
            status: decision
          };
          socket.emit(VACATION_APPROVE_NOTIFICATION, auth.user, request);
          setSuccessMessage("Updated Request");
          setReload(true);
        }
      }
    }
  }, [socket, auth, handleNotification, decision])

  useEffect(() => {
    if (reload) {
      axios.get("/api/vacations/")
        .then(response => {
          setEvents(response.data);
          setReload(false);
          setHandleNotification(false);
        })
        .catch(err => {
          //console.log(err);
        });
    }
  }, [reload])

  useEffect(() => {
    if (path === "/calendar/manage/:id" || path === "/calendar/details/:id") {
      let requestIndex = null;
      events.forEach((request, index) => {
        if (request._id.toString() === props.match.params.id) {
          setName(request.user_name);
          setRequestId(request._id.toString());
          setRequestUserId(request.user_id);
          setStart(request.start);
          setEnd(request.end);
          setNote(request.note);
          setStatus(request.status);
          setResponse(request.response);
          if (request.backgroundColor !== `${theme.primary_color}`) requestIndex = index;
          var endDate = new Date(request.end);
          endDate.setDate(endDate.getDate() + 1);
          request.end = endDate.toString();
        }
      });
      //setEvents(events);
      console.log(JSON.stringify(events));
      if (requestIndex) {
        let newEvents = [...events];
        newEvents[requestIndex] = {...newEvents[requestIndex], backgroundColor: `${theme.primary_color}`, textColor: `${theme.primary_text_color}` };
        setEvents(newEvents);
      }
    }
  }, [path, events, props.match.params.id])

  useEffect(() => {
    if (start !== "") {
      if (calendar.current) {
        let calendarApi = calendar.current.getApi();
        //console.log(calendarApi);
        calendarApi.gotoDate(start);
      }
    }
  }, [start, calendar.current])

  const onChange = (e) => {
    switch (e.target.id) {
      case 'response':
        setResponse(e.target.value);
        break;
      default:
        break;
    }
  }

  const updateRequest = (e, status) => {
    setDecision(status);
    let params = {
      vacation_id: props.match.params.id,
      status: status,
      response: response
    };
    axios.put("/api/vacations/", params)
      .then(response => {
        //console.log(response.data);
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const goBack = (e) => {
    props.history.goBack();
  }

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`,
    margin: "0px 0px 15px 0px"
  };

  return (
    <Layout loaded={true} showSidebar={true} subscription={true}>
      <div className={`row ${classes.Main}`} style={{ margin: "0px" }}>

        {path === "/calendar/manage/:id" && (
          <div className={`col-3 ${classes.SideContent}`}>
            <button style={buttonStyle} className={classes.Button} type="button" onClick={goBack}>Back</button>
            <h2>Manage Request</h2>
            <h3>Name:</h3>
            <p>{name}</p>
            <h3>Start Date:</h3>
            <p>{start}</p>
            <h3>End Date:</h3>
            <p>{end}</p>
            <h3>Note:</h3>
            <textarea value={note} readOnly />
            <h3>Status:</h3>
            <p style={{ color: `${status === "Approved" ? "green" : status === "Pending" ? "black" : "#b30000"}`}}>{status}</p>

            <hr/>

            <h3>Response Note:</h3>
            <textarea id="response" value={response} onChange={onChange} />
            <button style={{ backgroundColor: "green", color: "white" }} className={classes.Button} type="button" onClick={(e) => updateRequest(e, "Approved")}>Approve</button>
            <button style={{ backgroundColor: "red", color: "white" }} className={classes.Button} type="button" onClick={(e) => updateRequest(e, "Denied")}>Deny</button>
            <p style={{ color: "green" }}>{successMessage}</p>
          </div>
        )}

        {path === "/calendar/details/:id" && (
          <div className={`col-3 ${classes.SideContent}`}>
            <button style={buttonStyle} className={classes.Button} type="button" onClick={goBack}>Back</button>
            <h2>Request Details</h2>
            <h3>Name:</h3>
            <p>{name}</p>
            <h3>Start Date:</h3>
            <p>{start}</p>
            <h3>End Date:</h3>
            <p>{end}</p>
            <h3>Note:</h3>
            <textarea value={note} readOnly />

            <hr/>

            <h3>Status:</h3>
            <p style={{ color: `${status === "Approved" ? "green" : status === "Pending" ? "black" : "#b30000"}`}}>{status}</p>
            <h3>Response Note:</h3>
            <textarea id="response" value={response} readOnly />
          </div>
        )}

        <div className={`${path==="/calendar"?"col-12":"col-9"} ${classes.CalendarContainer}`}
              style={{ padding: `${path==="/calendar"?"0px 80px 0px 80px":"0px 20px 0px 20px"}`}}>
          <FullCalendar
            ref={calendar}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={events}
            initialDate={start !== "" ? start : Date.now()}
            /*
            eventClick={eventClick}
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        </div>

      </div>
    </Layout>
  );
}

export default withRouter(Calendar);

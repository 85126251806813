import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setCurrentUser, logoutUser } from "./reducers/authActions";
import './App.css';

import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from "./store";
import SocketProvider from "./contexts/SocketContext";
import NotificationProvider from "./contexts/NotificationContext";

import ManagerRoute from "./utils/managerRoute";
import EmployeeRoute from "./utils/employeeRoute";
import ClientRoute from "./utils/clientRoute";

import resetlink from "./views/general/resetlink";
import resetpassword from "./views/general/resetpassword";
import landing from "./views/sales/landing";
import trial from "./views/sales/trial";
import success from "./views/sales/success";
import renew from "./views/sales/renew";
import register from "./views/general/register";
import dashboard from "./views/general/dashboard";
import login from "./views/general/login";
import clients from "./views/general/clients";
import clientdetails from "./views/general/clientdetails";
import employees from "./views/manager/employees/employees";
import employeedetails from "./views/manager/employeedetails";
import messages from "./views/messages/messageLayout";
import clientbilling from "./views/client/billing";
import onboardingController from "./views/client/onboarding/controller";
import settings from "./views/general/settings/controller";
import addemployee from "./views/manager/addemployee";
import employeepermissions from "./views/manager/permissions/employee";
import clientpermissions from "./views/manager/permissions/clientpermissions";
import selectemployee from "./views/manager/permissions/selectemployee";
import employeeOnboardingFiles from "./views/employee/onboardingfiles";
import submitrequest from "./views/employee/submitrequest";
import calendar from "./views/general/calendar";
import managerequests from "./views/manager/requestlist";
import employeerequests from "./views/employee/requestlist";
import employeecontact from "./views/employee/onboardingcontact";
import employeeunread from './views/manager/employeeunread';
import employeemessages from "./views/manager/employeemessages/messageLayout";
import brandingform from "./views/client/brandingform";
import socialform from "./views/client/socialform";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
// Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./dashboard";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <SocketProvider>
      <NotificationProvider>
        <BrowserRouter>
            <div className="App">
              <Switch>
                <Route exact path="/" component={login} />
                <Route exact path="/reset" component={resetlink} />
                <Route exact path="/reset/:token" component={resetpassword} />

                <Route exact path="/trial" component={trial} />
                <Route exact path="/checkout/success/:receipt_id" component={success} />
                <Route exact path="/register/client/:id" component={register} />
                <Route exact path="/register/employee/:id" component={register} />
                <Route exact path="/register/:package/:term" component={register} />
                <Route exact path="/client/billing" component={clientbilling} />
                <Route exact path="/dashboard" component={dashboard} />
                <Route exact path="/login" component={login} />
                <Route exact path="/login/x" component={login} />
                <Route exact path="/clients" component={clients} />
                <Route exact path="/clients/archive" component={clients} />
                <Route exact path="/clients/:id" component={clientdetails} />
                <Route exact path="/clients/archive/:id" component={clientdetails} />
                <Route exact path="/employees" component={employees} />
                <Route exact path="/employees/archive" component={employees} />
                <Route exact path="/employees/:id" component={employeedetails} />
                <Route exact path="/messages" component={messages} />
                <Route exact path="/messages/:id" component={messages} />
                <Route exact path="/settings" component={settings} />
                <Route exact path="/settings/:page" component={settings} />
                <Route exact path="/submit-request" component={submitrequest} />
                <Route exact path="/calendar" component={calendar} />

                <ClientRoute exact path="/account" component={clientdetails} />
                <ClientRoute exact path="/onboarding/services" component={onboardingController} />
                <ClientRoute exact path="/onboarding/accounts" component={onboardingController} />
                <ClientRoute exact path="/onboarding/assets" component={onboardingController} />
                <ClientRoute exact path="/onboarding/events" component={onboardingController} />
                <ClientRoute exact path="/onboarding/emails" component={onboardingController} />
                <ClientRoute exact path="/onboarding/links/social" component={onboardingController} />
                <ClientRoute exact path="/onboarding/questions/social" component={onboardingController} />
                <ClientRoute exact path="/onboarding/questions/branding" component={onboardingController} />
                <ClientRoute exact path="/onboarding/review" component={onboardingController} />
                <ClientRoute exact path="/form/branding" component={brandingform} />
                <ClientRoute exact path="/form/social" component={socialform} />

                <EmployeeRoute exact path="/onboarding/files" component={employeeOnboardingFiles} />
                <EmployeeRoute exact path="/onboarding/contact" component={employeecontact} />
                <EmployeeRoute exact path="/view-requests" component={employeerequests} />
                <EmployeeRoute exact path="/calendar/details/:id" component={calendar} />

                <ManagerRoute exact path="/employee/add" component={addemployee} />
                <ManagerRoute exact path="/permissions/select" component={selectemployee} />
                <ManagerRoute exact path="/permissions/:id" component={employeepermissions} />
                <ManagerRoute exact path="/permissions/:employee_id/:client_id" component={clientpermissions} />
                <ManagerRoute exact path="/subscription/renew" component={renew} />
                <ManagerRoute exact path="/vacation-requests" component={managerequests} />
                <ManagerRoute exact path="/calendar/manage/:id" component={calendar} />
                <ManagerRoute exact path="/employee-unread-messages" component={employeeunread} />
                <ManagerRoute exact path="/messages/employee/:employee_id" component={employeemessages} />
                <ManagerRoute exact path="/messages/employee/:employee_id/:id" component={employeemessages} />
              </Switch>
            </div>
        </BrowserRouter>
      </NotificationProvider>
      </SocketProvider>
      </PersistGate>
      </Provider>
    );
  }
}

export default App;

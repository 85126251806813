import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from './modal.module.scss';

const EmployeeView = (props) => {
  const { theme,
          setConversation,
          show,
          setShow,
          messageUsers
        } = props;

  const [employeeViewName, setEmployeeViewName] = useState("");
  const [employeeViewError, setEmployeeViewError] = useState("");

  const showEmployeeViewToggle = (e) => {
    setShow(false);
  }

  const employeeViewClick = (e) => {
    if (employeeViewName === "") {
      setEmployeeViewError("Please select an employee");
    } else {
      let employeeId = employeeValidator(employeeViewName);
      if (employeeId === "") {
        setEmployeeViewError("Employee Not Found");
      } else {
        setConversation(null);
        props.history.push(`/messages/employee/${employeeId}`);
      }
    }
  }

  const employeeValidator = (name) => {
    let id = "";
    messageUsers.forEach((user) => {
      if (user.name === employeeViewName && user.role === "Employee") {
        id = user._id.toString();
      }
    });
    return id;
  }

  const onChange = (e) => {
    switch (e.target.name) {
      case 'employeeView':
        setEmployeeViewName(e.target.value);
        setEmployeeViewError("");
        break;
      default:
        break;
    }
  }

  return(
    <Modal
      className={classes.Modal}
      show={show}
    >
    <Modal.Body>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-sm-6 offset-sm-3">
          <h2 style={{ marginTop: "10px" }}>Employee View:</h2>
        </div>
        <div className="col-sm-2 offset-sm-1">
          <button className={`${classes.Button}`} style={{backgroundColor: "#8c0000", width: "50px" }} onClick={showEmployeeViewToggle}>X</button>
        </div>
      </div>
      <input
        style={{ width: "100%" }}
        className={`form-control`}
        type="text"
        value={employeeViewName}
        name="employeeView"
        onChange={onChange}
        list="employeeViewList"
        />
      <datalist id="employeeViewList">
        {messageUsers.length > 0 && (
          messageUsers.map((user, index) => {
            if (user.role === "Employee") {
              return <option key={index} value={user.name}>{user.name}</option>
            } else {
              return null;
            }
          })
        )}
      </datalist>
      <p style={{ textAlign: "center", color: "red" }}>{employeeViewError}</p>
      <button
        className={`${classes.Button}`}
        style={{ backgroundColor: `${theme.primary_color}`, margin: "15px 0px 0px 0px" }}
        onClick={employeeViewClick}>
          Switch view
      </button>
    </Modal.Body>
    </Modal>
  );
}

export default withRouter(EmployeeView);

import React, { useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from "./security.module.scss";
import Fade from "../../../components/layout/fade/fade";

const Security = (props) => {
  const {
    theme,
    user
  } = props;
  const [showModal, setShowModal] = useState(false);
  let resetDate = user.reset_password_date ? dayjs(user.reset_password_date).format('MMMM DD, YYYY') : dayjs(user.date).format('MMMM DD, YYYY');

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setShowModal(true);
    const params = {
      email: user.email
    };
    axios.put('/api/general/reset/link', params)
      .then(response => {
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <Fade show={true}>
      <Modal
        className={classes.Modal}
        show={showModal}
      >
        <Modal.Body>
          <div className="row" style={{ marginBottom: "20px" }}>
            <div className="col-sm-10 offset-sm-1">
              <h2>Password Reset Email Sent!</h2>
              <p>We&apos;ve sent you an email with a link<br/> to reset your password.</p>
              <button style={buttonStyle} onClick={(e) => setShowModal(false)}>Close</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className={`row`}>
        <div className={`col ${classes.SettingsContent}`}>

          <div className={`row ${classes.SecurityModule}`}>
            <div className={`col-9`}>
              <h1>Login &amp; Security</h1>
            </div>
          </div>

          <div className={`row ${classes.SecurityModule}`}>
            <div className={`col-9`}>
              <h2>Password</h2>
              <p>Last Updated: {resetDate}</p>
            </div>
            <div className={`col-3`}>
              <button type="button" style={buttonStyle} onClick={sendEmail}>Update</button>
            </div>
          </div>

          {/*
          <div className={`row ${classes.SecurityModule}`}>
            <div className={`col-9`}>
              <h2>2 Factor Authorization</h2>
              <p>After entering your password, you’ll be asked for a second verification step.</p>
            </div>
            <div className={`col-3`}>
              <button type="button" style={buttonStyle}>Enable</button>
            </div>
          </div>

          <div className={`row ${classes.SecurityModule}`}>
            <div className={`col-9`}>
              <h2>Manage Devices</h2>
              <p>View and Manage Devices You&apos;ve Signed In On</p>
            </div>
            <div className={`col-3`}>
              <button type="button" style={buttonStyle}>Manage</button>
            </div>
          </div>
          */}

        </div>
      </div>
    </Fade>
  );
}

export default withRouter(Security);

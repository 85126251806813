import React from 'react';
import days from 'dayjs';
import classes from "./messages.module.scss";

const Messages = (props) => {
  const {
    user,
    theme,
    messages
  } = props;

  function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex)
       .map(part => {
          if(part.match(urlRegex)) {
             return <a target="_blank" rel="noopener noreferrer" href={part}>{part}</a>;
          }
          return part;
       });
  }

  return (
      <div>
        {messages.length > 0 && messages.map((message, index) => {
          let messageClass = "";
          let style = {};
          let timeColor = "";
          if (user.id === message.sender_id) {
            messageClass = classes.UserMessage;
            style = { backgroundColor: `${theme.primary_color}`, color: `${theme.primary_text_color}`};
            timeColor = `${theme.primary_text_color}`;
          } else {
            messageClass = classes.Message;
            style = { backgroundColor: `${theme.accent_color}`, color: `${theme.accent_text_color}`};
            timeColor = `${theme.accent_text_color}`;
          }
          return (
            <div key={index} className={`row`} style={{ padding: "0px", margin: "0px" }}>
              <div key={index} className={`col-12`} style={{ padding: "0px", margin: "0px" }}>
                <div className={messageClass} style={style}>
                  <div className={`${classes.SenderTag}`}>
                    <h3 className={`${classes.MessageUsername}`}>
                      {`${message.sender_name}`}<span className={`${classes.MessageTime}`} style={{ color: timeColor }}>{days(message.date).format('h:mmA M/D/YY')}</span>
                    </h3>
                  </div>
                  <div className={`${classes.Content}`}>
                    {message.type === "text" && (
                      <p style={{whiteSpace: "pre-wrap"}} className={`${classes.MessageText}`}>{urlify(message.message)}</p>
                    )}
                    {message.type === "file" && (
                      <div>
                        {message.file_type.includes('image') && (
                          <img src={message.file_location} className={`${classes.Image}`} alt={message.file_name}/>
                        )}
                        {!message.file_type.includes('image') && (
                          <a style={{ color: timeColor }} href={message.file_location} download>{message.file_name}</a>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
  );
}

export default Messages;

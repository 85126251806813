import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";
import classes from "./manager.module.scss";
import AddClientModal from "./modals/addClient";
import AddEmployeeModal from "./modals/addEmployee";
import Layout from "../../../components/layout/layout";
import prices from "../../../assets/prices";

const ManagerView = (props) => {
  //const user = props.user;
  const theme = props.theme;
  const [showClientModal, setShowClientModal] = useState(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [employeeUnreadCount, setEmployeeUnreadCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [deniedCount, setDeniedCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [tier, setTier] = useState("solo");
  const [canAddClients, setCanAddClients] = useState(false);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    axios.get('/api/managers/dashboard-data')
      .then(response => {
        //console.log(response.data);
        setUnreadCount(response.data.managerCount);
        setEmployeeUnreadCount(response.data.employeeCount);
        setPendingCount(response.data.pending);
        setApprovedCount(response.data.approved);
        setDeniedCount(response.data.denied);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }, [])

  useEffect(() => {
      axios.get("/api/payment/manager")
        .then(response => {
          if (response.data.price_id == prices.solo_monthly || response.data.price_id == prices.solo_yearly) {
              setTier("solo");
              axios.get("/api/clients/manager/all")
                .then(response => {
                  if (response.data.length >= 10) {
                      console.log("Add Client Tier :::: " + tier);
                      setCanAddClients(false);
                  }
                  else
                      setCanAddClients(true);
                })
                .catch(err => {
                  setCanAddClients(false);
                })
          }
          else if (response.data.price_id == prices.agency_monthly || response.data.price_id == prices.agency_yearly) {
              setTier("agency");
              setCanAddClients(true);
          }
        })
        .catch(err => {
          setTier("error");
        })
  }, [])

  const employeeListClick = (e) => {
    e.preventDefault();
    props.history.push('/employees');
  }

  const archivedEmployeeList = (e) => {
    e.preventDefault();
    props.history.push("/employees/archive");
  }

  const clientListClick = (e) => {
    e.preventDefault();
    props.history.push('/clients');
  }

  const archivedClientListClick = (e) => {
    e.preventDefault();
    props.history.push('/clients/archive');
  }

  const messagesClick = (e) => {
    props.history.push('/messages');
  }

  const preferencesClick = (e) => {
    props.history.push("/settings/account");
  }

  const securityClick = (e) => {
    props.history.push("/settings/security");
  }

  const addEmployeeClick = (e) => {
    // props.history.push("/employee/add");
    setShowEmployeeModal(true);
  }

  async function addClientClick(e) {
    if (canAddClients) 
      setShowClientModal(true);
    else
      alert("You can't add more than 10 clients on the Solo tier. Upgrade to Agency to add more.");
  }

  const permissionsClick = (e) => {
    props.history.push("/permissions/select");
  }

  const vacationClick = (e) => {
    props.history.push("/calendar");
  }

  const requestsClick = (e) => {
    props.history.push("/vacation-requests");
  }

  const employeeMessagesClick = (e) => {
    props.history.push("/employee-unread-messages");
  }

  return(
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div>
        {/** Add Client Modal **/}
        <AddClientModal
          show={showClientModal}
          setShow={setShowClientModal}
          theme={theme} />

        <AddEmployeeModal
          show={showEmployeeModal}
          setShow={setShowEmployeeModal}
          theme={theme} />

        <div className={`${classes.content}`}>
          <h1>Dashboard</h1>
          <div className={`row ${classes.dashboard_module}`}>
            <div className={`col ${classes.inner_module}`}>
              <h2>Manager Messages</h2>
              <p>You have <b>{unreadCount}</b> unread messages</p>
              <button style={buttonStyle} onClick={messagesClick}>Inbox</button>
            </div>
            {tier == "agency" && (
            <div className={`col ${classes.inner_module}`}>
              <h2>Employee Messages</h2>
              <p><b>{employeeUnreadCount}</b> unread messages</p>
              <button style={buttonStyle} onClick={employeeMessagesClick}>Details</button>
            </div>
            )}
          </div>
         {tier == "agency" && (
          <div className={`row ${classes.dashboard_module}`}>
            <div className={`col ${classes.inner_module}`}>
              <h2>Employee Resources</h2>
              <p onClick={employeeListClick} className={classes.pointer}>View Employees</p>
              <p onClick={archivedEmployeeList} className={classes.pointer}>View Archived Employees</p>
              <p onClick={addEmployeeClick} className={classes.pointer}>Create a new Employee Account</p>
              <p onClick={permissionsClick} className={classes.pointer}>Employee Permissions</p>
            </div>
            <div className={`col ${classes.inner_module}`}>
              <h2>Vacation Requests</h2>
              <table className={classes.vacation_table}>
                <tbody>
                  <tr>
                    <th>Pending</th>
                    <th>Approved</th>
                    <th>Declined</th>
                  </tr>
                  <tr>
                    <td>{pendingCount}</td>
                    <td>{approvedCount}</td>
                    <td>{deniedCount}</td>
                  </tr>
                </tbody>
              </table>
              <button style={buttonStyle} onClick={vacationClick}>View Calendar</button>
              <button style={buttonStyle} onClick={requestsClick}>Manage</button>
            </div>
          </div>
          )}
          <div className={`row`}>
            <div className={`col`}>
              <div className={`${classes.half_module}`}>
                <h2>Client List</h2>
                <p onClick={clientListClick} className={classes.pointer}>View Clients</p>
                <p onClick={archivedClientListClick} className={classes.pointer}>View Archived Clients</p>
                <p onClick={addClientClick} className={classes.pointer}>Add New Client</p>
              </div>
            </div>
            <div className={`col`}>
              <div className={`${classes.half_module}`}>
                <h2>Account</h2>
                <p onClick={preferencesClick} className={classes.pointer}>Preferences</p>
                <p onClick={securityClick} className={classes.pointer}>Security</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(ManagerView);

module.exports = {
	USER_CONNECTED:"USER_CONNECTED",
	MESSAGE_RECIEVED:"MESSAGE_RECIEVED",
	MESSAGE_SENT:"MESSAGE_SENT",
	USER_DISCONNECTED:"USER_DISCONNECTED",
	TYPING:"TYPING",
	VERIFY_USER:"VERIFY_USER",
	LOGOUT:"LOGOUT",
	ENTER_MESSAGES: "ENTER_MESSAGES",

	NOTIFICATION_RECEIVED: "NOTIFICATION_RECEIVED",
	NOTIFICATION_UPDATE: "NOTIFICATION_UPDATE",
	REGISTER_NOTIFICATION: "REGISTER_NOTIFICATION",
	ONBOARDING_NOTIFICATION: "CLIENT_ONBOARDING_NOTIFICATION",
	VACATION_SUBMIT_NOTIFICATION: "VACATION_NOTIFICATION",
	VACATION_APPROVE_NOTIFICATION: "VACATION_APPROVE_NOTIFICATION",

	CONVERSATION_SELECTED: "CONVERSATION_SELECTED",
	MESSAGE_NOTIFICATIONS: "MESSAGE_NOTIFICATIONS"
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Table from "../../../components/table/table";
import classes from "./employee.module.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuestionHelp from "../../../components/questionhelp";

const Permissions = (props) => {
  //const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const employeeId = props.match.params.id;
  const [employee, setEmployee] = useState({phone: ""});
  const [loaded, setLoaded] = useState(false);

  const [clients, setClients] = useState([]);
  const [vacation, setVacation] = useState(false);
  const [newClients, setNewClients] = useState(false);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    axios.get(`/api/permissions/employee-page/${employeeId}`)
      .then(response => {
        //console.log(response.data);
        setEmployee(response.data.employee);
        setClients(response.data.clients);
        setVacation(response.data.settings.employee_permissions.vacation);
        setNewClients(response.data.settings.employee_permissions.add_new_clients);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }, [employeeId])

  const clientPermissionColumns = [
    {
      Header: 'Business Name',
      accessor: 'business_name'
    },
    {
      Header: 'Contact Name',
      accessor: 'name'
    },
    {
      Header: 'Social',
      accessor: 'social',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Web Development',
      accessor: 'web',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Branding',
      accessor: 'branding',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: 'Reputation',
      accessor: 'reputation',
      Cell: ({value}) => { return <div style={{ margin: "0px", backgroundColor: `${value}` }} className={classes.permission}></div>}
    },
    {
      Header: '',
      accessor: '_id',
      Cell: ({value}) => { return <button style={buttonStyle} className={classes.EditButton} onClick={(e) => clientEditClick(e, value)}>Edit</button> }
    }
  ];

  const onChange = (e) => {
    switch(e.target.id) {
      case 'vacation':
        setVacation(!vacation);
        break;
      case 'newClients':
        setNewClients(!newClients);
        break;
      default:
        break;
    }
  }

  const clientEditClick = (e, clientId) => {
    //console.log(clientId);
    props.history.push(`/permissions/${employeeId}/${clientId}`);
  }

  const backtoEmployees = () => {
    props.history.push('/permissions/select');
  }

  const saveGeneralPermissions = () => {
    let params = {
      vacation: vacation,
      add_new_clients: newClients
    };
    axios.put(`/api/settings/employee/permissions/${employeeId}`, params)
      .then(response => {
        //console.log(response.data);
        toast.info("General Permissions Saved");
      })
      .catch(err => {
        //console.log(err);
        //TODO: Add error toast
      });
  }

  return(
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div className={`row justify-content-center ${classes.Permissions}`}>

        <div className={`row`}>

          <div className={`col-5`}>
            <div className={classes.EmployeeDetails}>
              <h1>{employee.name}</h1>
              <h2>Department: {employee.department}</h2>
              <p>Email: {employee.email}</p>
              <p>Phone: {`(${employee.phone.slice(0, 3)})${employee.phone.slice(3, 6)}-${employee.phone.slice(6, 10)}`}</p>
            </div>
          </div>

          <div className={`col-7`}>
            <div className={`${classes.GeneralPermissions}`}>
              <h2>General Permissions</h2>
              <hr/>
              <div className={`row ${classes.PermissionsModule}`}>
                <div className={`col-9`}>
                  <p>Vacation Requests</p>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} id='vacation' onChange={onChange} type="checkbox" checked={vacation} />
                  </div>
                </div>
              </div>

              <div className={`row ${classes.PermissionsModule}`}>
                <div className={`col-9`}>
                  <p>Add New Clients</p>
                </div>
                <div className={`col-3`}>
                  <div className={`form-check form-switch`}>
                    <input className={`form-check-input`} id='newClients' onChange={onChange} type="checkbox" checked={newClients} />
                  </div>
                </div>
              </div>
              <button className={classes.SaveButton} style={buttonStyle} onClick={saveGeneralPermissions}>Save</button>
            </div>
          </div>

        </div>

        <div className={`row`}>
          <div className={`col-12`}>

            <div className={classes.clientPermissionCol}>
              <h2>
                Client Permissions
                <QuestionHelp message={"Green - Full Access, Yellow - Limited Access, Red - No Access"} />
              </h2>
              <div className={`row justify-content-center`} style={{ margin: "0px" }}>
                <div className={`col-xs-12 col-sm-10 ${classes.TableCol}`}>
                  <Table data={clients} columns={clientPermissionColumns} onClick={() => {}} />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <button className={classes.BackButton} style={buttonStyle} onClick={backtoEmployees}>Back to Employees</button>

      <ToastContainer style={{ top: "60px" }}/>
    </Layout>
  );
}

export default withRouter(Permissions);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import classes from './manager.module.scss';
import Table from "../../../components/table/table";
import dayjs from 'dayjs';

const ClientList = (props) => {
  const { user, theme } = props;
  const [clients, setClients] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let archive = props.match.path === "/clients/archive" ? "true" : "false";
    axios.get(`/api/clients/employee/${archive}`)
      .then(response => {
        //console.log(response.data);
        setClients(response.data);
        setLoaded(true);
      })
      .catch(err => {
        if (err.response.status === 404) {
          setErrorMessage("No Clients Found");
          setLoaded(true);
        } else {
          setErrorMessage("Something went wrong!");
          setLoaded(true);
        }
      });
  }, [props.match.path, user.role]);

  const clientColumns = [
    {
      Header: 'Business Name',
      accessor: 'business_name'
    },
    {
      Header: 'Services',
      accessor: 'services',
      Cell: ({value}) => {
        let string = "";
        if (value.social) string += "Social ";
        if (value.web) string += "Web Development ";
        if (value.seo) string += "SEO ";
        if (value.branding) string += "Branding ";
        if (value.reputation) string += "Reputation";
        if (!value.social && !value.web && !value.seo && !value.branding && !value.reputation) string += "None";
        string = string.trimEnd().replaceAll(" ", " - ");
        return (
          <p className={classes.services}>
            {string}
          </p>
      )}
    },
    {
      Header: "Date",
      accessor: 'date',
      Cell: ({value}) => { return dayjs(value).format("M/DD/YYYY") }
    }
  ];

  const goBack = (e) => {
    props.history.push("/dashboard");
  }

  const onClick = (e, row) => {
    props.history.push(`/clients/${row.original._id}`);
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10`}>
          <h1 className={classes.Heading}>{props.match.path === "/clients/archive" && "Archived"} Clients</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10  ${classes.TableCol}`}>
          <Table data={clients} columns={clientColumns} onClick={onClick} />
          <p className={classes.error}>{errorMessage}</p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-12 col-sm-4 ${classes.ButtonCol}`}>
          <button
            style={{
              width: "100%",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "20px",
              backgroundColor: `${theme.primary_color}`
            }}
            onClick={goBack}
            className={`${classes.BackButton}`}
          >Back</button>
        </div>
      </div>

    </Layout>
  );
}

export default withRouter(ClientList);

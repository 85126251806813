import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import classes from './modal.module.scss';
import Fade from "../../../../components/layout/fade/fade";

const Options = (props) => {
  const { show, setShow, client, setClient, showArchive, setReload } = props;

  const [showArchiveOptions, setShowArchiveOptions] = useState(true);
  const [showUnarchiveOptions, setShowUnarchiveOptions] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    //console.log(showArchive);
    if (showArchive) {
      setShowArchiveOptions(false);
      setShowUnarchiveOptions(true);
    } else {
      setShowArchiveOptions(true);
      setShowUnarchiveOptions(false);
    }
  }, [showArchive])

  const optionsCancelHandler = (e) => {
    setShow(false);
    setClient("");
    setTimeout(() => {
      if (showArchive) {
        setShowArchiveOptions(false);
        setShowUnarchiveOptions(true);
        setShowDelete(false);
      } else {
        setShowArchiveOptions(true);
        setShowUnarchiveOptions(false);
        setShowDelete(false);
      }
    }, 500);
  }

  const archiveClient = (e, option) => {
    const params = {
      id: client,
      archive: option
    };
    axios.post('/api/general/archive', params)
      .then(response => {
        //console.log(response.data);
        optionsCancelHandler();
        setReload(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const deleteClick = (e) => {
    setShowArchiveOptions(false);
    setShowUnarchiveOptions(false);
    setShowDelete(true);
  }

  const deleteConfirmHandler = (e) => {
    if (client !== "") {
      axios.delete(`/api/general/client/${client}`)
        .then(response => {
          //console.log(response);
          setReload(true);
        })
        .catch(err => {
          //console.log(err)
        });
        optionsCancelHandler();
    }
  }

  return(
    <Modal
        className={classes.Modal}
        show={show}
      >
      <Modal.Body>
        {showArchiveOptions && (
          <div>
            <div className={`row`}>
              <div className={`col-12`}>
                <h2>Options</h2>
                <button onClick={optionsCancelHandler} className={`${classes.CloseButton}`} style={{ backgroundColor: `red`, float: "right"}}>X</button>
              </div>
            </div>
            <button onClick={(e) => archiveClient(e, true)} className={`${classes.OptionsButton}`} style={{ color: "black", border: "2px solid black" }}>Archive</button>
            <button onClick={deleteClick} className={`${classes.OptionsButton}`} style={{ color: "red", border: "2px solid red"}}>Delete</button>
          </div>
        )}
        {showUnarchiveOptions && (
          <div>
            <div className={`row`}>
              <div className={`col-12`}>
                <h2>Options</h2>
                <button onClick={optionsCancelHandler} className={`${classes.CloseButton}`} style={{ backgroundColor: `red`, float: "right"}}>X</button>
              </div>
            </div>
            <button onClick={(e) => archiveClient(e, false)} className={`${classes.OptionsButton}`} style={{ color: "black", border: "2px solid black" }}>Unarchive</button>
            <button onClick={deleteClick} className={`${classes.OptionsButton}`} style={{ color: "red", border: "2px solid red"}}>Delete</button>
          </div>
        )}
        {showDelete && (
          <Fade size={"col-xs-12"} show={true}>
            <h4>Are you sure?</h4>
            <p style={{color: "red"}}>This cannot be undone!</p>
            <div className="row justify-content-center">
              <div className="col-xs-12 col-md-4">
                <button
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    marginRight: "2rem",
                    backgroundColor: `grey`
                  }}
                  onClick={optionsCancelHandler}
                  className={`${classes.Button}`}
                >Cancel</button>
              </div>
              <div className="col-xs-12 col-md-4">
                <button
                  style={{
                    width: "100%",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem",
                    backgroundColor: `grey`
                  }}
                  onClick={deleteConfirmHandler}
                  className={`${classes.Button}`}
                >Confirm</button>
              </div>
            </div>
          </Fade>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default withRouter(Options);

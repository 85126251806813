import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { setTheme } from "../../reducers/themeActions";
import { logoutUser, setCurrentUser } from "../../reducers/authActions";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import classes from "./login.module.scss";

import { SocketContext } from "../../contexts/SocketContext";
import { REGISTER_NOTIFICATION } from "../../Events";

const Login = (props) => {
  const socket = useContext(SocketContext)[0];
  const auth = useSelector(state => state.auth);
  const path = props.match.path;
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const color = "grey";
  const [handleNotification, setHandleNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(REGISTER_NOTIFICATION, auth.user);
          props.history.push("/onboarding/contact");
        }
      }
    }
    if (auth.user.name) {
      props.history.push("/dashboard");
    }
  }, [socket, auth, handleNotification, props.history])

  const onChange = (e) => {
    setErrorMessage("");
    switch (e.target.id) {
      case 'email':
        setEmail(e.target.value);
        setEmailMessage("");
        break;
      case 'password':
        setPassword(e.target.value);
        setPasswordMessage("");
        break;
      default:
        break;
    }
  }

  async function onSubmit(e) {
    e.preventDefault();
    // Logout any previous user
    dispatch(logoutUser());

    if (email === "" || password === "") {
      setErrorMessage("Email and Password Fields Required");
      return;
    }

    const userData = {
      email: email,
      password: password
    };
    try {
      let response = await axios.post("/api/auth/login", userData);
      //console.log(response);
      // Set token to localStorage
      const { token } = response.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decodedPayload = jwt_decode(token);
      dispatch(setCurrentUser(decodedPayload));
      let themeId = decodedPayload.role === "Manager" ? decodedPayload.id : decodedPayload.manager_id;
      let themeResponse = await axios.get(`/api/themes/${themeId}`);
      let newTheme = {
        primary_color: themeResponse.data.primary_color,
        accent_color: themeResponse.data.accent_color,
        primary_text_color: themeResponse.data.primary_text_color,
        accent_text_color: themeResponse.data.accent_text_color,
        thumbnail_logo: {
            filename: themeResponse.data.thumbnail_logo.filename,
            location: themeResponse.data.thumbnail_logo.location
        }
      }
      dispatch(setTheme(newTheme));
      if (decodedPayload.role === "Employee" && path === "/login/x") {
        setHandleNotification(true);
      }
      else props.history.push("/dashboard");
    } catch(err) {
      //console.log(err);
      if (err.response.data === "\"email\" must be a valid email") setErrorMessage("Please Enter a valid Email");
      else setErrorMessage("Your Username or Password is incorrect");
    }
  };

  const goBack = e => {
    e.preventDefault();
    props.history.push("/");
  }

  return (
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            <b>Login</b> below
          </h1>
          <p>
            Forgot your password? <Link to="/reset">Reset Password</Link>
          </p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate onSubmit={onSubmit}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2>Email:</h2>
                <input
                  onChange={onChange}
                  value={email}
                  id="email"
                  type="email"
                  placeholder="Email"
                />
                <p className={`${classes.ErrorMessage}`}>{emailMessage}</p>
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2>Password:</h2>
                <input
                  onChange={onChange}
                  value={password}
                  id="password"
                  type="password"
                  placeholder="Password"
                />
                <p className={`${classes.ErrorMessage}`}>{passwordMessage}</p>
              </div>
            </div>

            <p style={{ color: "red" }}>{errorMessage}</p>

            <div className={`row justify-content-center`}>
                <div className={`col-sm-4 col-md-3 ${classes.Upload}`}>
                  <button type="submit" className={`${classes.Button}`} style={{backgroundColor: `${color}`}} onClick={onSubmit}>Login</button>
                </div>
              </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Login);

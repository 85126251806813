import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import Table from "../../components/table/table";
import classes from './requestlist.module.scss';

const RequestList = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [requests, setRequests] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const requestColumns = [
    {
      Header: 'Name',
      accessor: 'user_name'
    },
    {
      Header: 'Start',
      accessor: 'start'
    },
    {
      Header: 'End',
      accessor: 'end'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: '',
      accessor: '_id',
      Cell: ({value}) => { return <button style={buttonStyle} className={classes.TableButton} onClick={(e) => onClick(e, value)}>Calendar View</button>}
    },
  ];

  useEffect(() => {
    axios.get('/api/vacations/')
      .then(response => {
        setRequests(response.data);
        setLoaded(true);
      })
      .catch(err => {
        if (err.response.status === 404) {
          setErrorMessage("No Vacation Requests Found");
          setLoaded(true);
        } else {
          setErrorMessage("Something went wrong!");
          setLoaded(true);
        }
      })
  }, []);

  const goBack = (e) => {
    e.preventDefault();
    props.history.push("/dashboard");
  }

  const onClick = (e, id) => {
    //console.log(id);
    props.history.push(`/calendar/manage/${id}`);
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10`}>
          <h1 className={classes.Heading}>Vacation Requests</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10 ${classes.TableCol}`}>
          <Table data={requests} columns={requestColumns} onClick={() => {}} theme={theme} />
          <p className={classes.error}>{errorMessage}</p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-xs-12 col-sm-4 ${classes.ButtonCol}`}>
          <button
            style={{
              backgroundColor: `${theme.primary_color}`
            }}
            onClick={goBack}
            className={`${classes.Button}`}
          >Back</button>
        </div>
      </div>

    </Layout>
  );
}

export default withRouter(RequestList);

import React, { useState, useContext } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";
import classes from "./account.module.scss";
import { setCurrentUser } from "../../../reducers/authActions";
import setAuthToken from "../../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { logoutUser } from "../../../reducers/authActions";
import { SocketContext } from "../../../contexts/SocketContext";
import { LOGOUT } from "../../../Events";
import DeleteModal from "./modals/deleteModal";

import Fade from "../../../components/layout/fade/fade";
import Spinner from "../../../components/layout/spinner/spinner";

const Account = (props) => {
  const {
    theme,
    user,
    setUser
  } = props;
  const dispatch = useDispatch();
  const socket = useContext(SocketContext)[0];
  const loaded = true;
  const [showModal, setShowModal] = useState(false);

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [birthday, setBirthday] = useState(user.birthday);
  const [businessName, setBusinessName] = useState(user.business_name ? user.business_name : "");

  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editBirthday, setEditBirthday] = useState(false);
  const [editBusiness, setEditBusiness] = useState(false);

  const onChange = (e) => {
    switch (e.target.id) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'birthday':
        setBirthday(e.target.value);
        break;
      case 'business':
        setBusinessName(e.target.value);
        break;
      default:
        break;
    }
  }

  const updateName = (e) => {
    const params = {
      name: name,
      old_name: user.name
    };
    axios.put("/api/settings/name", params)
      .then(response => {

        const { token } = response.data;
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decodedPayload = jwt_decode(token);
        dispatch(setCurrentUser(decodedPayload));

        setUser(response.data.user);
        setEditName(false);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const updateEmail = (e) => {
    const params = {
      email: email
    };
    axios.put("/api/settings/email", params)
      .then(response => {
        setUser(response.data);
        setEditEmail(false);
      })
      .catch(err => {
        //console.log(err);
      })
  }

  const updatePhone = (e) => {
    const params = {
      phone: phone
    };
    axios.put("/api/settings/phone", params)
      .then(response => {
        setUser(response.data);
        setEditPhone(false);
      })
      .catch(err => {
        //console.log(err);
      })
  }

  const updateBirthday = (e) => {
    const params = {
      birthday: birthday
    };
    axios.put("/api/settings/birthday", params)
      .then(response => {
        setUser(response.data);
        setEditBirthday(false);
      })
      .catch(err => {
        //console.log(err);
      })
  }

  const updateBusiness = (e) => {
    const params = {
      business_name: businessName
    };
    axios.put("/api/settings/business", params)
      .then(response => {
        setUser(response.data);
        setEditBusiness(false);
      })
      .catch(err => {
        //console.log(err);
      })
  }

  async function deleteUser(e) {
    let route = "";
    if (user.role === "Client") route = `/api/general/client/${user._id.toString()}`;
    else if (user.role === "Employee") route = `/api/general/employee/${user._id.toString()}`
    else if (user.role === "Manager") route = `/api/general/manager/`;
    if (user.role === "Client" || user.role === "Employee") {
      axios.delete(route)
        .then(response => {
          if (socket) socket.emit(LOGOUT);
          dispatch(logoutUser());
          props.history.push("/");
        })
        .catch(err => {
          //console.log(err);
        });
    } else if (user.role === "Manager") {
      try {

        try {
          let employees = await axios.get('/api/employees/manager');
          employees.data.forEach(async(employee) => {
            await axios.delete(`/api/general/employee/${employee._id.toString()}`);
          });
        } catch(employeeErr) {
          //console.log(employeeErr);
        }

        try {
          let clients = await axios.get('/api/clients/manager/all');
          clients.data.forEach(async(client) => {
            await axios.delete(`/api/general/client/${client._id.toString()}`);
          });
        } catch(clientErr) {
          //console.log(clientErr);
        }

        axios.delete(route)
          .then(response => {
            //console.log(response.data);
            if (socket) socket.emit(LOGOUT);
            dispatch(logoutUser());
            props.history.push("/");
          })
          .catch(err => {
            //console.log(err);
          });
      } catch(err) {
        //console.log(err);
      }
    }
  }

  const deleteClick = () => {
    setShowModal(true);
  }

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  return (
    <Fade show={true}>
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        deleteUser={deleteUser}
      />
      {!loaded && (
        <div className={classes.spinner}>
          <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
        </div>
      )}
      {loaded && (
        <div className={`row`}>
          <div className={`col ${classes.SettingsContent}`}>

            <div className={`row ${classes.AccountModule}`}>
              <div className={`col-9`}>
                <h1>Your Account</h1>
              </div>
            </div>

            {!editName && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Name</h2>
                  <p>{name}</p>
                </div>
                <div className={`col-3`}>
                  { user.role != "Employee" && <button type="button" style={buttonStyle} onClick={(e) => setEditName(true)}>Edit</button> }
                </div>
              </div>
            )}
            {editName && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Name</h2>
                  <input type="text" id="name" value={name} placeholder="Full Name" onChange={onChange} />
                </div>
                <div className={`col-3`}>
                  <button type="button" style={buttonStyle} onClick={updateName}>Update</button>
                </div>
              </div>
            )}

            {!editEmail && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Email Address</h2>
                  <p>{email}</p>
                </div>
                <div className={`col-3`}>
                  { user.role != "Employee" && <button type="button" style={buttonStyle} onClick={(e) => setEditEmail(true)}>Edit</button> } 
                </div>
              </div>
            )}
            {editEmail && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Email Address</h2>
                  <input type="text" id="email" value={email} placeholder="Email Address" onChange={onChange} />
                </div>
                <div className={`col-3`}>
                  <button type="button" style={buttonStyle} onClick={updateEmail}>Update</button>
                </div>
              </div>
            )}

            {!editPhone && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Phone</h2>
                  <p>({phone.slice(0,3)}){phone.slice(3,6)}-{phone.slice(6,10)}</p>
                </div>
                <div className={`col-3`}>
                  { user.role != "Employee" && <button type="button" style={buttonStyle} onClick={(e) => setEditPhone(true)}>Edit</button> }
                </div>
              </div>
            )}
            {editPhone && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Phone</h2>
                  <input type="text" id="phone" value={phone} placeholder="Phone Number" onChange={onChange} />
                </div>
                <div className={`col-3`}>
                  <button type="button" style={buttonStyle} onClick={updatePhone}>Update</button> 
                </div>
              </div>
            )}

            {!editBirthday && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Birthday</h2>
                  <p>{birthday}</p>
                </div>
                <div className={`col-3`}>
                  { user.role != "Employee" && <button type="button" style={buttonStyle} onClick={(e) => setEditBirthday(true)}>Edit</button> }
                </div>
              </div>
            )}
            {editBirthday && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Birthday</h2>
                  <input type="text" id="birthday" value={birthday} placeholder="Birthday" onChange={onChange} />
                </div>
                <div className={`col-3`}>
                  <button type="button" style={buttonStyle} onClick={updateBirthday}>Update</button>
                </div>
              </div>
            )}

            {user.business_name && (
              <>
                {!editBusiness && (
                  <div className={`row ${classes.AccountModule}`}>
                    <div className={`col-9`}>
                      <h2>Business Name</h2>
                      <p>{user.business_name}</p>
                    </div>
                    <div className={`col-3`}>
                      { user.role != "Employee" && <button type="button" style={buttonStyle} onClick={(e) => setEditBusiness(true)}>Edit</button> }
                    </div>
                  </div>
                )}
                {editBusiness && (
                  <div className={`row ${classes.AccountModule}`}>
                    <div className={`col-9`}>
                      <h2>Business Name</h2>
                      <input type="text" id="business" value={businessName} placeholder="Business Name" onChange={onChange} />
                    </div>
                    <div className={`col-3`}>
                      <button type="button" style={buttonStyle} onClick={updateBusiness}>Update</button>
                    </div>
                  </div>
                )}
              </>
            )}

            {/*
            <div className={`row ${classes.AccountModule}`}>
              <div className={`col-9`}>
                <h2>Language</h2>
                <select>
                  <option>English</option>
                  <option>Espanol</option>
                </select>
              </div>
            </div>
            */}

            {user.role === "Manager" && (
              <div className={`row ${classes.AccountModule}`}>
                <div className={`col-9`}>
                  <h2>Delete Account</h2>
                  <p className={classes.WarningText}>By deleting your account, you&apos;ll no longer be able to access your account or log in to The Honey Pot.</p>
                </div>
                <div className={`col-3`}>
                  <button type="button" style={{ backgroundColor: "red", color: "white" }} onClick={deleteClick}>Delete</button>
                </div>
              </div>
            )}

          </div>
        </div>
      )}
    </Fade>
  );
}

export default withRouter(Account);

import { combineReducers } from "redux";
import authReducer from "./authReducers";
import themeReducer from "./themeReducers";
import onboardingReducer from "./onboardingReducers";

export default combineReducers({
  auth: authReducer,
  themeReducer: themeReducer,
  onboarding: onboardingReducer
});

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import classes from "./employee.module.scss";
import AddClientModal from "./modals/addClient";
import Layout from "../../../components/layout/layout";

const EmployeeView = (props) => {
  //const user = props.user;
  const theme = props.theme;
  const [permissions, setPermissions] = useState({});
  const [showClientModal, setShowClientModal] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [deniedCount, setDeniedCount] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    axios.get("/api/employees/dashboard")
      .then(response => {
        setPermissions(response.data.settings.employee_permissions);
        setPendingCount(response.data.pendingCount);
        setApprovedCount(response.data.approvedCount);
        setDeniedCount(response.data.deniedCount);
        setUnreadCount(response.data.unreadCount);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
        setError(true);
      });
  }, [])

  const clientListClick = (e) => {
    e.preventDefault();
    props.history.push('/clients');
  }

  const archivedClientListClick = (e) => {
    e.preventDefault();
    props.history.push('/clients/archive');
  }

  const messagesClick = (e) => {
    props.history.push('/messages');
  }

  const preferencesClick = (e) => {
    props.history.push("/settings/account");
  }

  const securityClick = (e) => {
    props.history.push("/settings/security");
  }

  const addClientClick = (e) => {
    setShowClientModal(true);
  }

  const vacationClick = (e) => {
    props.history.push("/submit-request");
  }

  const requestClick = (e) => {
    props.history.push("/view-requests");
  }

  return(
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      {error && <p style={{ color: "red" }}>Something went wrong! Please refresh your browser.</p>}
      {!error && (
        <div>
          {/** Add Client Modal **/}
          <AddClientModal
            show={showClientModal}
            setShow={setShowClientModal}
            theme={theme} />

          <div className={`${classes.content}`}>
            <h1>Dashboard Test</h1>

            <div className={`row `}>
              <div className={`col`}>
                <div className={`${classes.half_module}`}>
                  <h2>Messages</h2>
                  <p>You have <b>{unreadCount}</b> unread messages</p>
                  <button style={buttonStyle} onClick={messagesClick}>Inbox</button>
                </div>
              </div>

              <div className={`col`}>
                <div className={`${classes.half_module}`}>
                  <h2>Vacation Requests</h2>
                  {permissions.vacation && (
                    <>
                      <table className={classes.vacation_table}>
                        <tbody>
                          <tr>
                            <th>Pending</th>
                            <th>Approved</th>
                            <th>Declined</th>
                          </tr>
                          <tr>
                            <td>{pendingCount}</td>
                            <td>{approvedCount}</td>
                            <td>{deniedCount}</td>
                          </tr>
                        </tbody>
                      </table>
                      <button style={buttonStyle} onClick={vacationClick}>New Request</button>
                      <button style={buttonStyle} onClick={requestClick}>View Requests</button>
                    </>
                  )}
                  {!permissions.vacation && (<p style={{ color: "red" }}>Your are not currently authorized to submit Vacation Requests</p>)}
                </div>
              </div>
            </div>

            <div className={`row`}>
              <div className={`col`}>
                <div className={`${classes.half_module}`}>
                  <h2>Client List</h2>
                  <p onClick={clientListClick} className={classes.pointer}>View Active List</p>
                  {permissions.add_new_clients && (<p onClick={addClientClick} className={classes.pointer}>Add New Client</p>)}
                  <p onClick={archivedClientListClick} className={classes.pointer}>View Archived List</p>
                </div>
              </div>
              <div className={`col`}>
                <div className={`${classes.half_module}`}>
                  <h2>Account</h2>
                  <p onClick={preferencesClick} className={classes.pointer}>Preferences</p>
                  <p onClick={securityClick} className={classes.pointer}>Security</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      )}
    </Layout>
  );
}

export default withRouter(EmployeeView);

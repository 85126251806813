import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import classes from "./accounts.module.scss";
import { ONBOARDING_NOTIFICATION } from '../../../Events';

const Accounts = (props) => {
  const {
    auth,
    socket,
    dispatch,
    changes,
    resetChanges,
    updateChanges,
    theme,
    onboarding,
    nextRoute,
    setLoaded
  } = props;
  const [newChanges, setNewChanges] = useState(changes);
  const [completed, setCompleted] = useState(false);
  const adminAccount = "ADMIN ACCOUNT";
  const adminName = "ADMIN NAME";
  const [handleNotification, setHandleNotification] = useState(false);

  useEffect(() => {
    if (handleNotification) {
      if (auth.isAuthenticated) {
        if (socket !== null) {
          socket.emit(ONBOARDING_NOTIFICATION, auth.user, newChanges);
          dispatch(resetChanges());
          props.history.push("/dashboard");
        }
      }
    }
  }, [socket, auth, handleNotification, newChanges])

  // Social Accounts
  const [showFacebook, setShowFacebook] = useState(false);
  const [showInstagram, setShowInstagram] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [showPinterest, setShowPinterest] = useState(false);

  const [facebookCompleted, setFacebookCompleted] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState("");
  const [instagramPassword, setInstagramPassword] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [twitterPassword, setTwitterPassword] = useState("");
  const [linkedinCompleted, setLinkedinCompleted] = useState(false);
  const [pinterestUsername, setPinterestUsername] = useState("");
  const [pinterestPassword, setPinterestPassword] = useState("");

  // Web Accounts
  const [showGoogle, setShowGoogle] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const [googleCompleted, setGoogleCompleted] = useState(false);
  const [websiteUsername, setWebsiteUsername] = useState("");
  const [websitePassword, setWebsitePassword] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [emailUsername, setEmailUsername] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [emailName, setEmailName] = useState("");

  // Reputation Management Accounts
  const [showTripadvisor, setShowTripadvisor] = useState(false);
  const [showYelp, setShowYelp] = useState(false);

  const [tripadvisorUsername, setTripadvisorUsername] = useState("");
  const [tripadvisorPassword, setTripadvisorPassword] = useState("");
  const [yelpUsername, setYelpUsername] = useState("");
  const [yelpPassword, setYelpPassword] = useState("");

  useEffect(() => {
    if (onboarding) {
      if (onboarding.accounts_page) setCompleted(onboarding.accounts_page.completed);
      // Social
      if (onboarding.facebook) setFacebookCompleted(onboarding.facebook.completed);
      if (onboarding.instagram) setInstagramUsername(onboarding.instagram.login);
      if (onboarding.instagram) setInstagramPassword(onboarding.instagram.password);
      if (onboarding.twitter) setTwitterUsername(onboarding.twitter.login);
      if (onboarding.twitter) setTwitterPassword(onboarding.twitter.password);
      if (onboarding.linkedin) setLinkedinCompleted(onboarding.linkedin.completed);
      if (onboarding.pinterest) setPinterestUsername(onboarding.pinterest.login);
      if (onboarding.pinterest) setPinterestPassword(onboarding.pinterest.password);

      // Webdev
      if (onboarding.google) setGoogleCompleted(onboarding.google.completed);
      if (onboarding.website) setWebsiteName(onboarding.website.hostingName);
      if (onboarding.website) setWebsiteUsername(onboarding.website.username);
      if (onboarding.website) setWebsitePassword(onboarding.website.password);
      if (onboarding.email) setEmailName(onboarding.email.link);
      if (onboarding.email) setEmailUsername(onboarding.email.username);
      if (onboarding.email) setEmailPassword(onboarding.email.password);

      // Reputation
      if (onboarding.trip) setTripadvisorUsername(onboarding.trip.login);
      if (onboarding.trip) setTripadvisorPassword(onboarding.trip.password);
      if (onboarding.yelp) setYelpUsername(onboarding.yelp.login);
      if (onboarding.yelp) setYelpPassword(onboarding.yelp.password);
    }
  }, [onboarding]);

  useEffect(() => {
    if (onboarding) {
      // Social
      if (onboarding.show_facebook) setShowFacebook(true);
      else setShowFacebook(false);
      if (onboarding.show_instagram) setShowInstagram(true);
      else setShowInstagram(false);
      if (onboarding.show_twitter) setShowTwitter(true);
      else setShowTwitter(false);
      if (onboarding.show_linkedin) setShowLinkedin(true);
      else setShowLinkedin(false);
      if (onboarding.show_pinterest) setShowPinterest(true);
      else setShowPinterest(false);

      // Webdev
      if (onboarding.show_google) setShowGoogle(true);
      else setShowGoogle(false);
      if (onboarding.show_website) setShowWebsite(true);
      else setShowWebsite(false);
      if (onboarding.show_email) setShowEmail(true);
      else setShowEmail(false);

      // Reputation
      if (onboarding.show_trip) setShowTripadvisor(true);
      else setShowTripadvisor(false);
      if (onboarding.show_yelp) setShowYelp(true);
      else setShowYelp(false);
    }
  }, [onboarding]);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`
  };

  const addToChanges = (update) => {
    if (!newChanges.includes(update)) {
      const addChanges = [...newChanges];
      addChanges.push(update);
      setNewChanges(addChanges);
    }
  }

  const onChange = (e) => {
    setCompleted(true);
    switch (e.target.id) {
      // Social
      case 'facebookCompleted':
        setFacebookCompleted(!facebookCompleted);
        addToChanges("Updated Facebook Account")
        break;
      case 'instagramUsername':
        setInstagramUsername(e.target.value);
        addToChanges("Updated Instagram Username")
        break;
      case 'instagramPassword':
        setInstagramPassword(e.target.value);
        addToChanges("Updated Instagram Password");
        break;
      case 'twitterUsername':
        setTwitterUsername(e.target.value);
        addToChanges("Updated Twitter Username")
        break;
      case 'twitterPassword':
        setTwitterPassword(e.target.value);
        addToChanges("Updated Twitter Password");
        break;
      case 'linkedinCompleted':
        setLinkedinCompleted(!linkedinCompleted);
        addToChanges("Updated LinkedIn Account");
        break;
      case 'pinterestUsername':
        setPinterestUsername(e.target.value);
        addToChanges("Updated Pinterest Username")
        break;
      case 'pinterestPassword':
        setPinterestPassword(e.target.value);
        addToChanges("Updated Pinterest Password");
        break;

      // Webdev
      case 'googleCompleted':
        setGoogleCompleted(!googleCompleted);
        addToChanges("Updated Google Account");
        break;
      case 'websiteName':
        setWebsiteName(e.target.value);
        break;
      case 'websiteUsername':
        setWebsiteUsername(e.target.value);
        addToChanges("Updated Website Username")
        break;
      case 'websitePassword':
        setWebsitePassword(e.target.value);
        addToChanges("Updated Website Password");
        break;
      case 'emailName':
        setEmailName(e.target.value);
        break;
      case 'emailUsername':
        setEmailUsername(e.target.value);
        addToChanges("Updated Email Username")
        break;
      case 'emailPassword':
        setEmailPassword(e.target.value);
        addToChanges("Updated Email Password");
        break;

      // Reputation
      case 'tripadvisorUsername':
        setTripadvisorUsername(e.target.value);
        addToChanges("Updated Tripadvisor Username")
        break;
      case 'tripadvisorPassword':
        setTripadvisorPassword(e.target.value);
        addToChanges("Updated Tripadvisor Password");
        break;
      case 'yelpUsername':
        setYelpUsername(e.target.value);
        addToChanges("Updated Yelp Username")
        break;
      case 'yelpPassword':
        setYelpPassword(e.target.value);
        addToChanges("Updated Yelp Password");
        break;
      default:
        break;
    }
  }

  const onSubmit = e => {
    e.preventDefault();
    setLoaded(false);
    dispatch(updateChanges(newChanges));
    const params = {
      completed: completed,
      facebook: {
        completed: facebookCompleted
      },
      instagram: {
        login: instagramUsername,
        password: instagramPassword
      },
      twitter: {
        login: twitterUsername,
        password: twitterPassword
      },
      linkedin: {
        completed: linkedinCompleted
      },
      pinterest: {
        login: pinterestUsername,
        password: pinterestPassword
      },

      google: {
        completed: googleCompleted
      },
      website: {
        hostingName: websiteName,
        login: websiteUsername,
        password: websitePassword
      },
      email: {
        link: emailName,
        login: emailUsername,
        password: emailPassword
      },

      trip: {
        login: tripadvisorUsername,
        password: tripadvisorPassword
      },
      yelp: {
        login: yelpUsername,
        password: yelpPassword
      }
    };
    axios.put('/api/onboarding/accounts', params)
      .then(response => {
        props.history.push(nextRoute);
      })
      .catch(err => {
        //console.log(err)
      });
  };

  const goBack = e => {
    e.preventDefault();
    const params = {
      completed: completed,
      facebook: {
        completed: facebookCompleted
      },
      instagram: {
        login: instagramUsername,
        password: instagramPassword
      },
      twitter: {
        login: twitterUsername,
        password: twitterPassword
      },
      linkedin: {
        completed: linkedinCompleted
      },
      pinterest: {
        login: pinterestUsername,
        password: pinterestPassword
      },

      google: {
        completed: googleCompleted
      },
      website: {
        hostingName: websiteName,
        login: websiteUsername,
        password: websitePassword
      },
      email: {
        link: emailName,
        login: emailUsername,
        password: emailPassword
      },

      trip: {
        login: tripadvisorUsername,
        password: tripadvisorPassword
      },
      yelp: {
        login: yelpUsername,
        password: yelpPassword
      }
    };
    axios.put('/api/onboarding/accounts', params)
      .then(response => {
        setHandleNotification(true);
      })
      .catch(err => {
        //console.log(err)
      });
  }

  return (
    <div>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <h1>
            Provide access to your <b>Accounts</b>
          </h1>
          <p className={`${classes.Security}`}>
            <span className={`material-icons`}>lock</span>
            Account info is protected using AES-256 encryption<
          /p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate>

            {/* Social  */}
            {showFacebook && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Facebook</h2>
                  <h3>How to add us as an Admin to your Facebook Page:</h3>
                  <p>1. Switch into your Facebook Business Page</p>
                  <p>2. Make sure your Business Profile Picture is in the top right corner</p>
                  <p>3. Click your Business Name in the top left corner to ensure you are looking at your Business Page and not your News Feed</p>
                  <p>4. Underneath your Cover Photo, click Manage (Tool Icons)</p>
                  <p>5. On the left Menu Bar, click Page Access</p>
                  <p>6. On the Right, under “People with Page Access” click Add New</p>
                  <p>7. Click Next</p>
                  <p>8. Add the Name or Email of the person you are adding</p>
                  <div className="form-check">
                    <input className={`${classes.Checkbox} form-check-input`} id="facebookCompleted" onChange={onChange} type="checkbox" checked={facebookCompleted}/>
                    <label className="form-check-label" htmlFor="facebookCompleted">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            )}

            {showInstagram && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Instagram</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={instagramUsername}
                    id="instagramUsername"
                    type="text"
                    placeholder="Account Handle"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={instagramPassword}
                    id="instagramPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {showTwitter && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Twitter</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={twitterUsername}
                    id="twitterUsername"
                    type="text"
                    placeholder="Account Handle"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={twitterPassword}
                    id="twitterPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {showLinkedin && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>LinkedIn</h2>
                  <h3>How to add us as an Admin to your LinkedIn Page:</h3>
                  <p>1. Sign in to your Page Admin center</p>
                  <p>2. Click the &apos;Admin Tools&apos; dropdown at the top of the page and select &apos;Page Admins&apos;</p>
                  <p>3. Select the type of admin you want to add on the left side of the Manage admins window</p>
                  <p>4. Type the name of the 1st-degree connection you’d like to add in the Add new admin by name… {adminName}</p>
                  <p>5. Click &apos;Save Changes&apos;</p>
                  <div className="form-check">
                    <input className={`${classes.Checkbox} form-check-input`} id="linkedinCompleted" onChange={onChange} type="checkbox" checked={linkedinCompleted}/>
                    <label className="form-check-label" htmlFor="linkedinCompleted">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            )}

            {showPinterest && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Pinterest</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={pinterestUsername}
                    id="pinterestUsername"
                    type="text"
                    placeholder="Account Login"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={pinterestPassword}
                    id="pinterestPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {/* Web Development  */}
            {showGoogle && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Google</h2>
                  <h3>How to share access on Google My Business:</h3>
                  <p>1. Sign in to Google My Business</p>
                  <p>2. Click on &apos;Users&apos; in the left navigation</p>
                  <p>3. Click the &apos;Add&apos; icon on the upper right side of the box</p>
                  <p>4. Enter {adminAccount} and select &apos;Owner&apos; as the role</p>
                  <p>5. Click &apos;Invite&apos;</p>
                  <div className="form-check">
                    <input className={`${classes.Checkbox} form-check-input`} id="googleCompleted" onChange={onChange} type="checkbox" checked={googleCompleted}/>
                    <label className="form-check-label" htmlFor="googleCompleted">
                      Completed
                    </label>
                  </div>
                </div>
              </div>
            )}

            {showWebsite && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Company Website</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={websiteName}
                    id="websiteName"
                    type="text"
                    placeholder="Hosting Service Provider"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={websiteUsername}
                    id="websiteUsername"
                    type="text"
                    placeholder="Username"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={websitePassword}
                    id="websitePassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {showEmail && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Email Service Provider</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={emailName}
                    id="emailName"
                    type="text"
                    placeholder="Access Link"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={emailUsername}
                    id="emailUsername"
                    type="text"
                    placeholder="Username"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={emailPassword}
                    id="emailPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {/* Reputation  */}
            {showYelp && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Yelp</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={yelpUsername}
                    id="yelpUsername"
                    type="text"
                    placeholder="Username"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={yelpPassword}
                    id="yelpPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            {showTripadvisor && (
              <div className={`row justify-content-center`}>
                <div className={`col-sm-12 col-md-10 col-lg-8 ${classes.FormInput}`}>
                  <h2>Tripadvisor</h2>
                  <h3>Enter your Login Info:</h3>
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={tripadvisorUsername}
                    id="tripadvisorUsername"
                    type="text"
                    placeholder="Username"
                  />
                  <input
                    className={`${classes.TextForm}`}
                    onChange={onChange}
                    value={tripadvisorPassword}
                    id="tripadvisorPassword"
                    type="password"
                    placeholder="Password"
                  />
                </div>
              </div>
            )}

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={buttonStyle} onClick={goBack}>Save & Exit</button>
              </div>
              <div className={`col-sm-6 col-md-5 col-lg-4 ${classes.Upload}`}>
                <button type="button" className={`${classes.Button}`} style={buttonStyle} onClick={onSubmit}>Next</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Accounts);

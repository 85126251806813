import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Layout from "../../../components/layout/layout";
import classes from "./clientpermissions.module.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ClientPermissions = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const employeeId = props.match.params.employee_id;
  const clientId = props.match.params.client_id;
  const [loaded, setLoaded] = useState(false);
  const [employee, setEmployee] = useState("");
  const [client, setClient] = useState("");
  const [permission, setPermission] = useState("");

  const [socialAll, setSocialAll] = useState(false);
  const [instagramUsername, setInstagramUsername] = useState(false);
  const [instagramPassword, setInstagramPassword] = useState(false);
  const [twitterUsername, setTwitterUsername] = useState(false);
  const [twitterPassword, setTwitterPassword] = useState(false);
  const [pinterestUsername, setPinterestUsername] = useState(false);
  const [pinterestPassword, setPinterestPassword] = useState(false);
  const [nationalDays, setNationalDays] = useState(false);
  const [socialQuestions, setSocialQuestions] = useState(false);

  const [webAll, setWebAll] = useState(false);
  const [website, setWebsite] = useState(false);
  const [email, setEmail] = useState(false);
  const [webEmailForms, setWebEmailForms] = useState(false);
  const [socialHandles, setSocialHandles] = useState(false);

  const [brandingQuestions, setBrandingQuestions] = useState(false);
  const [allAssets, setAllAssets] = useState(false);

  const [reputationAll, setReputationAll] = useState(false);
  const [yelpUsername, setYelpUsername] = useState(false);
  const [yelpPassword, setYelpPassword] = useState(false);
  const [tripadvisorUsername, setTripadvisorUsername] = useState(false);
  const [tripadvisorPassword, setTripadvisorPassword] = useState(false);

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    axios.get(`/api/permissions/client-permission-page/${employeeId}/${clientId}`)
      .then(response => {
        //console.log(response.data);
        setEmployee(response.data.employee);
        setClient(response.data.client);
        if (response.data.permission) setPermission(response.data.permission);
      })
      .catch(err => {
        //console.log(err);
      });
  }, [clientId, employeeId])

  useEffect(() => {
    if (permission !== "") {
      setInstagramUsername(permission.instagram_username);
      setInstagramPassword(permission.instagram_password);
      setTwitterUsername(permission.twitter_username);
      setTwitterPassword(permission.twitter_password);
      setPinterestUsername(permission.pinterest_username);
      setPinterestPassword(permission.pinterest_password);
      setNationalDays(permission.national_days);
      setSocialQuestions(permission.social_questions);
      setWebsite(permission.website);
      setEmail(permission.email);
      setWebEmailForms(permission.web_email_forms);
      setSocialHandles(permission.social_handles);
      setBrandingQuestions(permission.branding_questions);
      setAllAssets(permission.assets);
      setYelpUsername(permission.yelp_username);
      setYelpPassword(permission.yelp_password);
      setTripadvisorUsername(permission.tripadvisor_username);
      setTripadvisorPassword(permission.tripadvisor_password);
      setLoaded(true);
    } else setLoaded(true);
  }, [permission])

  useEffect(() => {
    if (
      instagramUsername && instagramPassword && twitterUsername && twitterPassword
      && pinterestUsername && pinterestPassword && nationalDays && socialQuestions
    ) {
      setSocialAll(true);
    } else setSocialAll(false);
    if (website && email && webEmailForms && socialHandles) setWebAll(true);
    else setWebAll(false);
    if (yelpUsername && yelpPassword && tripadvisorUsername && tripadvisorPassword) setReputationAll(true);
    else setReputationAll(false);
  }, [
    socialAll,
    webAll,
    reputationAll,
    instagramUsername,
    instagramPassword,
    twitterUsername,
    twitterPassword,
    pinterestUsername,
    pinterestPassword,
    nationalDays,
    socialQuestions,
    socialHandles,
    website,
    email,
    webEmailForms,
    brandingQuestions,
    allAssets,
    yelpUsername,
    yelpPassword,
    tripadvisorUsername,
    tripadvisorPassword
  ])

  const onChange = (e) => {
    switch (e.target.id) {
      case 'socialAll':
        setSocialAll(!socialAll);
        setInstagramUsername(!socialAll);
        setInstagramPassword(!socialAll);
        setTwitterUsername(!socialAll);
        setTwitterPassword(!socialAll);
        setPinterestUsername(!socialAll);
        setPinterestPassword(!socialAll);
        setNationalDays(!socialAll);
        setSocialQuestions(!socialAll);
        break;
      case 'instagramUsername':
        setInstagramUsername(!instagramUsername);
        break;
      case 'instagramPassword':
        setInstagramPassword(!instagramPassword);
        break;
      case 'twitterUsername':
        setTwitterUsername(!twitterUsername);
        break;
      case 'twitterPassword':
        setTwitterPassword(!twitterPassword);
        break;
      case 'pinterestUsername':
        setPinterestUsername(!pinterestUsername);
        break;
      case 'pinterestPassword':
        setPinterestPassword(!pinterestPassword);
        break;
      case 'nationalDays':
        setNationalDays(!nationalDays);
        break;
      case 'socialQuestions':
        setSocialQuestions(!socialQuestions);
        break;
      case 'socialHandles':
        setSocialHandles(!socialHandles);
        break;

      case 'webAll':
        setWebAll(!webAll);
        setWebsite(!webAll);
        setEmail(!webAll);
        setWebEmailForms(!webAll);
        setSocialHandles(!webAll);
        break;
      case 'website':
        setWebsite(!website);
        break;
      case 'email':
        setEmail(!email);
        break;
      case 'webEmailForms':
        setWebEmailForms(!webEmailForms);
        break;

      case 'brandingQuestions':
        setBrandingQuestions(!brandingQuestions);
        break;
      case 'allAssets':
        setAllAssets(!allAssets);
        break;

      case 'reputationAll':
        setReputationAll(!reputationAll);
        setYelpUsername(!reputationAll);
        setYelpPassword(!reputationAll);
        setTripadvisorUsername(!reputationAll);
        setTripadvisorPassword(!reputationAll);
        break;
      case 'yelpUsername':
        setYelpUsername(!yelpUsername);
        break;
      case 'yelpPassword':
        setYelpPassword(!yelpPassword);
        break;
      case 'tripadvisorUsername':
        setTripadvisorUsername(!tripadvisorUsername);
        break;
      case 'tripadvisorPassword':
        setTripadvisorPassword(!tripadvisorPassword);
        break;
      default:
        break;
    }
  }

  const saveClientPermissions = (e) => {
    if (client === "") {
      // TODO: set Error Message
      return;
    }
    let params = {
      employee_id: employeeId,
      client_id: clientId,
      client_name: client.name,
      client_business: client.business_name,
      instagram_username: instagramUsername,
      instagram_password: instagramPassword,
      twitter_username: twitterUsername,
      twitter_password: twitterPassword,
      pinterest_username: pinterestUsername,
      pinterest_password: pinterestPassword,
      national_days: nationalDays,
      social_questions: socialQuestions,
      website: website,
      email: email,
      web_email_forms: webEmailForms,
      social_handles: socialHandles,
      branding_questions: brandingQuestions,
      assets: allAssets,
      yelp_username: yelpUsername,
      yelp_password: yelpPassword,
      tripadvisor_username: tripadvisorUsername,
      tripadvisor_password: tripadvisorPassword
    };
    axios.post("/api/permissions/", params)
      .then(response => {
        //console.log(response.data);
        setPermission(response.data);
        toast.info("Client Permissions Saved");
      })
      .catch(err => {
        //console.log(err);
        //TODO: Add error toast
      });
  }

  const goBack = () => {
    props.history.goBack();
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10 ${classes.Page}`}>

          <div className={`row`}>
            <div className={`col-6 ${classes.Details}`}>
              <h2>Client: <b>{client.name}</b> | {client.business_name}</h2>
              <h2>Employee: <b>{employee.name}</b> | {employee.department}</h2>
            </div>
          </div>

          {/* Begin Row */}
          <div className={`row`}>
            {/* Social Media Permissions */}
            <div className={`col-6`}>

              <div className={`row`}>
                <div className={`col-12 ${classes.PermissionsBlock}`}>

                  <h2>Social Media Permissions</h2>
                  <hr/>
                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Full Access</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='socialAll' onChange={onChange} type="checkbox" checked={socialAll} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Instagram Handle</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='instagramUsername' onChange={onChange} type="checkbox" checked={instagramUsername} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Instagram Password</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='instagramPassword' onChange={onChange} type="checkbox" checked={instagramPassword} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Twitter Handle</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='twitterUsername' onChange={onChange} type="checkbox" checked={twitterUsername} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Twitter Password</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='twitterPassword' onChange={onChange} type="checkbox" checked={twitterPassword} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Pinterest Username</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='pinterestUsername' onChange={onChange} type="checkbox" checked={pinterestUsername} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Pinterest Password</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='pinterestPassword' onChange={onChange} type="checkbox" checked={pinterestPassword} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>National Days/Events</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='nationalDays' onChange={onChange} type="checkbox" checked={nationalDays} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Social Media Questionnaire</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='socialQuestions' onChange={onChange} type="checkbox" checked={socialQuestions} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className={`row`}>
                <div className={`col-12 ${classes.PermissionsBlock}`}>

                  <h2>Branding Permissions</h2>
                  <hr/>
                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Branding Questionnaire</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='brandingQuestions' onChange={onChange} type="checkbox" checked={brandingQuestions} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Access to Client Assets</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='allAssets' onChange={onChange} type="checkbox" checked={allAssets} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* Web Dev Permissions */}
            <div className={`col-6`}>
              <div className={`row`}>
                <div className={`col-12 ${classes.PermissionsBlock}`}>

                  <h2>Web Development Permissions</h2>
                  <hr/>
                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Full Access</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='webAll' onChange={onChange} type="checkbox" checked={webAll} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Company Website Access</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='website' onChange={onChange} type="checkbox" checked={website} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Email Service Provider Access</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='email' onChange={onChange} type="checkbox" checked={email} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Website Email Forms</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='webEmailForms' onChange={onChange} type="checkbox" checked={webEmailForms} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Social Media Handles</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='socialHandles' onChange={onChange} type="checkbox" checked={socialHandles} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className={`row`}>
                <div className={`col-12 ${classes.PermissionsBlock}`}>

                  <h2>Reputation Management Permissions</h2>
                  <hr/>
                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Full Access</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='reputationAll' onChange={onChange} type="checkbox" checked={reputationAll} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Yelp Username</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='yelpUsername' onChange={onChange} type="checkbox" checked={yelpUsername} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Yelp Password</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='yelpPassword' onChange={onChange} type="checkbox" checked={yelpPassword} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Tripadvisor Username</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='tripadvisorUsername' onChange={onChange} type="checkbox" checked={tripadvisorUsername} />
                      </div>
                    </div>
                  </div>

                  <div className={`row ${classes.PermissionsInput}`}>
                    <div className={`col-9`}>
                      <p>Tripadvisor Password</p>
                    </div>
                    <div className={`col-3`}>
                      <div className={`form-check form-switch`}>
                        <input className={`form-check-input`} id='tripadvisorPassword' onChange={onChange} type="checkbox" checked={tripadvisorPassword} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          {/* End Row */}
          <button style={buttonStyle} className={classes.Button} onClick={goBack}>Back</button>
          <button style={buttonStyle} className={classes.Button} onClick={saveClientPermissions}>Save</button>

          <ToastContainer style={{ top: "60px" }}/>
        </div>
      </div>
    </Layout>
  );
}













export default withRouter(ClientPermissions);

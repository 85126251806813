import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter, Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import classes from "./resetlink.module.scss";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const params = {
      token: props.match.params.token
    };
    axios.put('/api/general/reset/validation', params)
      .then(response => {
        setEmail(response.data);
        setUpdated(false);
        setError(false);
      })
      .catch(err => {
        setUpdated(false);
        setError(true);
        setErrorMessage("Reset link has expired. Please resend link");
      });
  }, [props.match.params.token]);

  const onChange = (e) => {
    switch (e.target.id) {
      case 'password':
        setPassword(e.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  }

  const updatePassword = (e) => {
    e.preventDefault();
    const params = {
      token: props.match.params.token,
      email: email,
      password: password,
      password_confirmation: confirmPassword
    };
    axios.put('/api/general/reset/password', params)
      .then(response => {
        setUpdated(true);
        setError(false);
      })
      .catch(err => {
        let error = err.response.data;
        setUpdated(false);
        setError(true);
        if (error === "\"password_confirmation\" must be [ref:password]") setErrorMessage("Passwords must match");
        else if (error === "\"password\" is not allowed to be empty") setErrorMessage("Please fill both password fields");
        else if (error === "\"password_confirmation\" is not allowed to be empty") setErrorMessage("Please fill both password fields");
        else setErrorMessage("Something went wrong!");
      });
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <Link to="/" className="btn-flat waves-effect">Back to Home</Link>
          <h1>
            Reset Your Password
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate onSubmit={updatePassword}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2>Email: {email}</h2>

                <input
                  onChange={onChange}
                  value={password}
                  id="password"
                  type="password"
                  placeholder="Password"
                />
                <input
                  onChange={onChange}
                  value={confirmPassword}
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                />
                {error && (
                  <div>
                    <p className={`${classes.ErrorMessage}`}>
                      {errorMessage}
                    </p>
                  </div>
                )}
                {updated && (
                  <div>
                    <p style={{ marginTop: "10px", textAlign: "center", color: "green" }}>Password Updated</p>
                  </div>
                )}
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-6 ${classes.Upload}`}>
                <button className={`${classes.Button}`} style={{backgroundColor: `grey`}} type="submit">Update Password</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(ResetPassword);

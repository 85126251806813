import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Fade from "../../../components/layout/fade/fade";
import classes from "./controller.module.scss";
import { SET_CHANGES, RESET_CHANGES } from "../../../reducers/types";
import { SocketContext } from "../../../contexts/SocketContext";

import Services from "./services";
import Accounts from "./accounts";
import NationalDays from "./nationalDays";
import Assets from "./assets";
import EmailForms from "./emailforms";
import SocialHandles from "./socialhandles";
import SocialQuestionnaire from "./socialquestions";
import BrandingQuestionnaire from "./brandingquestions";
import Review from "./review";

const Controller = (props) => {
  const auth = useSelector(state => state.auth);
  const socket = useContext(SocketContext)[0];
  const dispatch = useDispatch();
  const changes = useSelector(state => state.onboarding.changes);
  const theme = useSelector(state => state.themeReducer.theme);
  const [onboarding, setOnboarding] = useState(null);
  const [loaded, setLoaded] = useState(false);
  let path = props.match.path;
  const [nextRoute, setNextRoute] = useState("");
  const links = [
    { text: "Services", link: "/onboarding/services"},
    { text: "Accounts", link: "/onboarding/accounts"},
    { text: "Assets", link: "/onboarding/assets"},
    { text: "Events", link: "/onboarding/events"},
    { text: "Website Email Forms", link: "/onboarding/emails"},
    { text: "Social Media Handles", link: "/onboarding/links/social"},
    { text: "Social Media Questionnaire", link: "/onboarding/questions/social"},
    { text: "Branding Questionnaire", link: "/onboarding/questions/branding"},
    { text: "Review", link: "/onboarding/review"}
  ];

  const linkStyle = {
    color: `${theme.accent_text_color}`
  };
  const currentLinkStyle = {
    color: `${theme.primary_color}`
  };

  const updateChanges = (update) => {
    return {
      type: SET_CHANGES,
      payload: update
    };
  }

  const resetChanges = () => {
    return {
      type: RESET_CHANGES
    };
  }

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      let route = "/api/onboarding";
      if (path === "/onboarding/accounts" || path === "/onboarding/review") route = "/api/onboarding/account";
      axios.get(route)
        .then(response => {
          setOnboarding(response.data);
          setLoaded(true);
        })
        .catch(err => {
          if (err.response.status === 404) {
            axios.post('/api/onboarding')
              .then(newResponse => {
                setOnboarding(newResponse.data);
                setLoaded(true);
              })
              .catch(err => {
                //console.log(err)
              });
          }
        })
    }, 500)
  }, [path])

  // *Get manager settings and edit route path based on onboarding settings*
  useEffect(() => {
    function calcNextRoute() {
      if (onboarding) {
        let {social, webdev, branding} = onboarding;
        if (path === "/onboarding/accounts") {
          if (social || webdev || branding) return "/onboarding/assets";
          else return "/onboarding/review";
        }
        if (path === "/onboarding/assets") {
          if (social) return "/onboarding/events";
          else if (webdev) return "/onboarding/emails";
          else return "/onboarding/questions/branding";
        }
        if (path === "/onboarding/events") {
          if (webdev) return "/onboarding/emails";
          else return "/onboarding/questions/social";
        }
        if (path === "/onboarding/emails") {
          return "/onboarding/links/social";
        }
        if (path === "/onboarding/links/social") {
          if (social) return "/onboarding/questions/social";
          else if (branding) return "/onboarding/questions/branding";
          else return ("/onboarding/review");
        }
        if (path === "/onboarding/questions/social") {
          if (branding) return "/onboarding/questions/branding";
          else return ("/onboarding/review");
        }
        if (path === "/onboarding/questions/branding") {
          return "/onboarding/review";
        }
        if (path === "/onboarding/review") {
          return "/dashboard";
        }
      }
    }

    if (onboarding) {
      setNextRoute(calcNextRoute());
    }
  }, [onboarding, path])

  const includeLink = (link) => {
    if (onboarding) {
      let {social, webdev, branding, reputation} = onboarding;
      if (link === "/onboarding/services") return true;
      if (link === "/onboarding/accounts") {
        if (social || webdev || reputation) return true;
        else return false;
      }
      if (link === "/onboarding/assets") {
        if (social || webdev || branding) return true;
        else return false;
      }
      if (link === "/onboarding/events") {
        if (social) return true;
        else return false;
      }
      if (link === "/onboarding/emails") {
        if (webdev) return true;
        else return false;
      }
      if (link === "/onboarding/links/social") {
        if (webdev) return true;
        else return false;
      }
      if (link === "/onboarding/questions/social") {
        if (social) return true;
        else return false;
      }
      if (link === "/onboarding/questions/branding") {
        if (branding) return true;
        else return false;
      }
      if (link === "/onboarding/review") {
        return true;
      }
    }
  }

  let addToLinkIndex = 1;

  return (
    <Layout loaded={loaded} showSidebar={false} subscription={true}>
      {path !== "/onboarding/services" && (
        <div className={classes.Sidebar}>
          <div className={classes.Links}>
            {links.map((item, index) => {
              if (!includeLink(item.link)) {
                addToLinkIndex = addToLinkIndex-1;
                return <span key={index}></span>;
              }
              else {
                if (path === item.link) {
                  return <p key={index}><Link style={currentLinkStyle} to={item.link}>{index + addToLinkIndex}. {item.text}</Link></p>
                } else {
                  return <p key={index}><Link style={linkStyle} to={item.link}>{index + addToLinkIndex}. {item.text}</Link></p>
                }
              }
            })}
          </div>
        </div>
      )}

      <div className={path === "/onboarding/services" ? "" : classes.Main}>
        <Fade show={true}>
          {path === "/onboarding/services" && (
            <Services
              dispatch={dispatch}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/accounts" && (
            <Accounts
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/assets" && (
            <Assets
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/events" && (
            <NationalDays
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/emails" && (
            <EmailForms
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              //resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/links/social" && (
            <SocialHandles
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/questions/social" && (
            <SocialQuestionnaire
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/questions/branding" && (
            <BrandingQuestionnaire
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              updateChanges={updateChanges}
              setLoaded={setLoaded}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
          {path === "/onboarding/review" && (
            <Review
              auth={auth}
              socket={socket}
              dispatch={dispatch}
              changes={changes}
              resetChanges={resetChanges}
              nextRoute={nextRoute}
              onboarding={onboarding}
              theme={theme} />
          )}
        </Fade>
      </div>
    </Layout>
  );
}

export default withRouter(Controller);

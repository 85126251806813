import React from 'react';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import ManagerView from "./manager";
import EmployeeView from "./employee";

const Clients = (props) => {
  const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);

  return(
    <>
      {user.role === 'Manager' && (
        <ManagerView theme={theme} user={user} />
      )}
      {user.role === 'Employee' && (
        <EmployeeView theme={theme} user={user} />
      )}
    </>
  );
}

export default withRouter(Clients);

import React, { useState } from 'react';
import axios from 'axios';
import { withRouter, Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import classes from "./resetlink.module.scss";

const ResetLink = (props) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setMessage("");
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (email === "") {
      setMessage("Please enter a valid Email");
    } else {
      const params = {
        email: email
      };
      axios.put('/api/general/reset/link', params)
        .then(response => {
          setMessage(response.data);
        })
        .catch(err => {
          setMessage(err.response.data);
        });
    }
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
          <Link to="/" className="btn-flat waves-effect">Back to Home</Link>
          <h1>
            Reset Your Password
          </h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-sm-10 col-md-8`}>
          <form className={classes.Form} noValidate onSubmit={sendEmail}>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-12 col-md-8 ${classes.FormInput}`}>
                <h2><b>Enter</b> your Email below:</h2>
                <input
                  onChange={emailChangeHandler}
                  value={email}
                  id="email"
                  type="email"
                  placeholder="Email"
                />
                {message === 'Recovery Email sent' && (
                  <div>
                    <p style={{ marginTop: "10px", textAlign: "center", color: "green" }}>{message}</p>
                  </div>
                )}
                {(message !== "Recovery Email sent" && message !== "") && (
                  <div>
                    <p className={`${classes.ErrorMessage}`}>
                      {message}
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={`row justify-content-center`}>
              <div className={`col-sm-6 col-md-6 ${classes.Upload}`}>
                <button className={`${classes.Button}`} style={{backgroundColor: `grey`}} type="submit">Send Reset Email</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(ResetLink);

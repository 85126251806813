import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Fade from "../../../components/layout/fade/fade";
import classes from './manager.module.scss';

const ClientDetails = (props) => {
  const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const [client, setClient] = useState("");
  const [onboarding, setOnboarding] = useState("");
  const [permission, setPermission] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(false);

  const [instagramLogin, setInstagramLogin] = useState("");
  const [instagramPassword, setInstagramPassword] = useState("");
  const [twitterLogin, setTwitterLogin] = useState("");
  const [twitterPassword, setTwitterPassword] = useState("");
  const [pinterestLogin, setPinterestLogin] = useState("");
  const [pinterestPassword, setPinterestPassword] = useState("");
  const [websiteHostingProvider, setWebsiteHostingProvider] = useState("");
  const [websiteLogin, setWebsiteLogin] = useState("");
  const [websitePassword, setWebsitePassword] = useState("");
  const [emailServiceLink, setEmailServiceLink] = useState("");
  const [emailServiceLogin, setEmailServiceLogin] = useState("");
  const [emailServicePassword, setEmailServicePassword] = useState("");
  const [tripAdvisorLogin, setTripAdvisorLogin] = useState("");
  const [tripAdvisorPassword, setTripAdvisorPassword] = useState("");
  const [yelpLogin, setYelpLogin] = useState("");
  const [yelpPassword, setYelpPassword] = useState("");

  useEffect(() => {
    let clientId = props.match.params.id;
    let noOnboarding = {
      social: false,
      webdev: false,
      seo: false,
      branding: false,
      reputation: false
    };
    axios.get(`/api/clients/${clientId}`)
      .then(response => {
        setClient(response.data);
        axios.get(`/api/onboarding/employee/${clientId}`)
          .then(onboardingResponse => {
            setOnboarding(onboardingResponse.data.onboarding);
            setPermission(onboardingResponse.data.permission);
            setInstagramLogin(onboardingResponse.data.onboarding.instagram.login);
            setInstagramPassword(onboardingResponse.data.onboarding.instagram.password);
            setTwitterLogin(onboardingResponse.data.onboarding.twitter.login);
            setTwitterPassword(onboardingResponse.data.onboarding.twitter.password);
            setPinterestLogin(onboardingResponse.data.onboarding.pinterest.login);
            setPinterestPassword(onboardingResponse.data.onboarding.pinterest.password);
            setWebsiteHostingProvider(onboardingResponse.data.onboarding.website.hostingName);
            setWebsiteLogin(onboardingResponse.data.onboarding.website.login);
            setWebsitePassword(onboardingResponse.data.onboarding.website.password);
            setEmailServiceLink(onboardingResponse.data.onboarding.email.link);
            setEmailServiceLogin(onboardingResponse.data.onboarding.email.login);
            setEmailServicePassword(onboardingResponse.data.onboarding.email.password);
            setTripAdvisorLogin(onboardingResponse.data.onboarding.trip.login);
            setTripAdvisorPassword(onboardingResponse.data.onboarding.trip.password);
            setYelpLogin(onboardingResponse.data.onboarding.yelp.login);
            setYelpPassword(onboardingResponse.data.onboarding.yelp.password);
            setLoaded(true);
          })
          .catch(err => {
              setErrorMessage("There was an issue loading client data!");
              setOnboarding(noOnboarding);
              setLoaded(true);
          });
      })
      .catch(err => setErrorMessage("There was an issue loading client data!"));

  }, [props.match.params.id, user.id, user.role]);

  useEffect(() => {
    if (
      permission.instagram_username || permission.instagram_password || permission.twitter_username ||
      permission.twitter_password || permission.pinterest_username || permission.pinterest_password ||
      permission.website || permission.email || permission.yelp_username || permission.yelp_password ||
      permission.tripadvisor_username || permission.tripadvisor_password || permission.social || permission.web
    ) {
      setAccountsView(true);
      setEventView(false);
      setWebsiteView(false);
      setAssetsView(false);
      setQuestionView(false);
    } else if (permission.national_days) {
      setAccountsView(false);
      setEventView(true);
      setWebsiteView(false);
      setAssetsView(false);
      setQuestionView(false);
    } else if (permission.web_email_forms || permission.social_handles) {
      setAccountsView(false);
      setEventView(false);
      setWebsiteView(true);
      setAssetsView(false);
      setQuestionView(false);
    } else if (permission.assets) {
      setAccountsView(false);
      setEventView(false);
      setWebsiteView(false);
      setAssetsView(true);
      setQuestionView(false);
    } else if (permission.branding_questions || permission.social_questions){
      setAccountsView(false);
      setEventView(false);
      setWebsiteView(false);
      setAssetsView(false);
      setQuestionView(true);
    } else {
      setAccountsView(false);
      setEventView(false);
      setWebsiteView(false);
      setAssetsView(false);
      setQuestionView(false);
    }
  }, [permission])

  const menuButtonStyle = {
    border: "none",
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  const goBack = e => {
    if (user.role === "Client") props.history.push("/dashboard");
    else {
      if (props.match.path === "/clients/archive/:id") props.history.push('/clients/archive');
      else props.history.push('/clients');
    }
  }

  const onChange = e => {
    setError(false);
    setResponse("");
    switch (e.target.id) {
      case 'instagramLogin':
        setInstagramLogin(e.target.value);
        break;
      case 'instagramPassword':
        setInstagramPassword(e.target.value);
        break;
      case 'twitterLogin':
        setTwitterLogin(e.target.value);
        break;
      case 'twitterPassword':
        setTwitterPassword(e.target.value);
        break;
      case 'pinterestLogin':
        setPinterestLogin(e.target.value);
        break;
      case 'websiteHostingProvider':
        setWebsiteHostingProvider(e.target.value);
        break;
      case 'websiteLogin':
        setWebsiteLogin(e.target.value);
        break;
      case 'websitePassword':
        setWebsitePassword(e.target.value);
        break;
      case 'emailServiceLink':
        setEmailServiceLink(e.target.value);
        break;
      case 'emailServiceLogin':
        setEmailServiceLogin(e.target.value);
        break;
      case 'emailServicePassword':
        setEmailServicePassword(e.target.value);
        break;
      case 'tripAdvisorLogin':
        setTripAdvisorLogin(e.target.value);
        break;
      case 'tripAdvisorPassword':
        setTripAdvisorPassword(e.target.value);
        break;
      case 'yelpLogin':
        setYelpLogin(e.target.value);
        break;
      case 'yelpPassword':
        setYelpPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const [accountsView, setAccountsView] = useState(true);
  const [editAccountsView, setEditAccountsView] = useState(false);
  const [eventView, setEventView] = useState(false);
  const [websiteView, setWebsiteView] = useState(false);
  const [assetsView, setAssetsView] = useState(false);
  const [questionView, setQuestionView] = useState(false);

  const viewController = e => {
    switch(e.target.id) {
      case 'accountsView':
        setAccountsView(true);
        setEditAccountsView(false);
        setEventView(false);
        setWebsiteView(false);
        setAssetsView(false);
        setQuestionView(false);
        break;
      case 'editAccountsView':
        setAccountsView(false);
        setEditAccountsView(true);
        setEventView(false);
        setWebsiteView(false);
        setAssetsView(false);
        setQuestionView(false);
        break;
      case 'eventsView':
        setAccountsView(false);
        setEditAccountsView(false);
        setEventView(true);
        setWebsiteView(false);
        setAssetsView(false);
        setQuestionView(false);
        break;
      case 'websiteView':
        setAccountsView(false);
        setEditAccountsView(false);
        setEventView(false);
        setWebsiteView(true);
        setAssetsView(false);
        setQuestionView(false);
        break;
      case 'assetsView':
        setAccountsView(false);
        setEditAccountsView(false);
        setEventView(false);
        setWebsiteView(false);
        setAssetsView(true);
        setQuestionView(false);
        break;
      case 'questionView':
        setAccountsView(false);
        setEditAccountsView(false);
        setEventView(false);
        setWebsiteView(false);
        setAssetsView(false);
        setQuestionView(true);
        break;
      default:
        break;
    }
  }

  const onSubmitAccount = (e) => {
    let id = props.match.params.id ? props.match.params.id : user.id;
    e.preventDefault();
    let params = {
      instagramLogin: instagramLogin,
      instagramPassword: instagramPassword,
      twitterLogin: twitterLogin,
      twitterPassword: twitterPassword,
      websiteHostingProvider: websiteHostingProvider,
      websiteLogin: websiteLogin,
      websitePassword: websitePassword,
      emailServiceLink: emailServiceLink,
      emailServiceLogin: emailServiceLogin,
      emailServicePassword: emailServicePassword,
      tripAdvisorLogin: tripAdvisorLogin,
      tripAdvisorPassword: tripAdvisorPassword,
      yelpLogin: yelpLogin,
      yelpPassword: yelpPassword
    };
    axios.post(`/api/onboarding/update_accounts/${id}`, params)
      .then(response => {
        setAccountsView(true);
        setEditAccountsView(false);
      })
      .catch(err => {
        setError(true);
        setResponse("Something went wrong! Please refresh and try again.");
      });
  }

  //-----------------------Set Up----------------------------------------------

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <p>{errorMessage}</p>
      {loaded && (
      <div>
        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 ${classes.HeadingCol}`}>
            <h1><span className={`${classes.BoldText}`}>{client.business_name}</span></h1>
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-xs-12 ${classes.ViewButtonsCol}`}>
            {(
              (onboarding.social || onboarding.webdev || onboarding.seo || onboarding.reputation) &&
              (permission.social || permission.web || permission.reputation)
            ) && (
              <button onClick={viewController} id="accountsView" style={menuButtonStyle} className={`${classes.ViewButton}`}>Accounts</button>
            )}
            {(onboarding.social && permission.national_days) && (
              <button onClick={viewController} id="eventsView" style={menuButtonStyle} className={`${classes.ViewButton}`}>Events</button>
            )}
            {((onboarding.webdev || onboarding.seo) && (permission.web_email_forms || permission.social_handles)) && (
              <button onClick={viewController} id="websiteView" style={menuButtonStyle} className={`${classes.ViewButton}`}>Website</button>
            )}
            {(permission.assets) && <button onClick={viewController} id="assetsView" style={menuButtonStyle} className={`${classes.ViewButton}`}>Assets</button>}
            {((onboarding.social || onboarding.branding) && (permission.social_questions || permission.branding_questions)) && (
              <button onClick={viewController} id="questionView" style={menuButtonStyle} className={`${classes.ViewButton}`}>Questionnaires</button>
            )}
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 col-sm-11 col-md-10 col-xl-8 ${classes.ViewCol}`}>
              {accountsView && (
                <Fade size={"col-xs-12"} show={true}>
                  <h2 style={{ display: "inline-block" }}>Accounts</h2>&nbsp;&nbsp;
                  <button className={classes.EditButton} style={menuButtonStyle} onClick={viewController} id="editAccountsView">Edit</button>
                  {(onboarding.social || onboarding.webdev || onboarding.seo || onboarding.reputation) && (
                    <>
                      {(onboarding.social && permission.social) && (
                        <>
                          {onboarding.show_facebook && (<p>Facebook Completed: {onboarding.facebook.completed?<span>Yes</span>:<></>}</p>)}

                          {(onboarding.show_instagram && permission.instagram_username) && (<p>Instagram Handle: {instagramLogin}</p>)}
                          {(onboarding.show_instagram && permission.instagram_password) && (<p>Instagram Password: {instagramPassword}</p>)}

                          {(onboarding.show_twitter && permission.twitter_username) && (<p>Twitter Handle: {twitterLogin}</p>)}
                          {(onboarding.show_twitter && permission.twitter_password) && (<p>Twitter Password: {twitterPassword}</p>)}

                          {onboarding.show_linkedin && (<p>LinkedIn Completed: {onboarding.linkedin.completed?<span>Yes</span>:<></>}</p>)}

                          {(onboarding.show_pinterest && permission.pinterest_username) && (<p>Pinterest Username: {pinterestLogin}</p>)}
                          {(onboarding.show_pinterest && permission.pinterest_password) && (<p>Pinterest Password: {pinterestPassword}</p>)}
                        </>
                      )}
                      {((onboarding.webdev || onboarding.seo) && permission.web) && (
                        <>
                          {onboarding.show_google && (<p>Google Business Completed: {onboarding.google.completed?<span>Yes</span>:<></>}</p>)}

                          {(onboarding.show_website && permission.website) && (<p>Website Hosting Provider: {websiteHostingProvider}</p>)}
                          {(onboarding.show_website && permission.website) && (<p>Website Username: {websiteLogin}</p>)}
                          {(onboarding.show_website && permission.website) && (<p>Website Password: {websitePassword}</p>)}

                          {(onboarding.show_email && permission.email) && (<p>Email Service Link: {emailServiceLink}</p>)}
                          {(onboarding.show_email && permission.email) && (<p>Email Service Username: {emailServiceLogin}</p>)}
                          {(onboarding.show_email && permission.email) && (<p>Email Service Password: {emailServicePassword}</p>)}
                        </>
                      )}
                      {(onboarding.reputation && permission.reputation) && (
                        <>
                          {(onboarding.show_trip && permission.tripadvisor_username) && (<p>TripAdvisor Username: {tripAdvisorLogin}</p>)}
                          {(onboarding.show_trip && permission.tripadvisor_password) && (<p>TripAdvisor Password: {tripAdvisorPassword}</p>)}

                          {(onboarding.show_yelp && permission.yelp_username) && (<p>Yelp Username: {yelpLogin}</p>)}
                          {(onboarding.show_yelp && permission.yelp_password) && (<p>Yelp Password: {yelpPassword}</p>)}
                        </>
                      )}
                    </>
                  )}
                </Fade>
              )}

              {editAccountsView && (
                <Fade size={"col-xs-12"} show={true}>
                  <h2 style={{ display: "inline-block" }}>Accounts</h2>&nbsp;&nbsp;
                  {(onboarding.social || onboarding.webdev || onboarding.seo || onboarding.reputation) && (
                    <>
                      {(onboarding.social && permission.social) && (
                        <>
                          {onboarding.show_instagram && <p>Instagram Handle: <input className={`form-control`} type="text" id="instagramLogin" value={instagramLogin} onChange={onChange}/></p>}
                          {onboarding.show_instagram && <p>Instagram Password: <input className={`form-control`} type="text" id="instagramPassword" value={instagramPassword} onChange={onChange}/></p>}

                          {onboarding.show_twitter && <p>Twitter Handle: <input className={`form-control`} type="text" id="twitterLogin" value={twitterLogin} onChange={onChange}/></p>}
                          {onboarding.show_twitter && <p>Twitter Password: <input className={`form-control`} type="text" id="twitterPassword" value={twitterPassword} onChange={onChange}/></p>}

                          {onboarding.show_pinterest && <p>Pinterest Username: <input className={`form-control`} type="text" id="pinterestLogin" value={pinterestLogin} onChange={onChange}/></p>}
                          {onboarding.show_pinterest && <p>Pinterest Password: <input className={`form-control`} type="text" id="pinterestPassword" value={pinterestPassword} onChange={onChange}/></p>}
                        </>
                      )}
                      {((onboarding.webdev || onboarding.seo) && permission.web) && (
                        <>
                          {onboarding.show_website && <p>Website Hosting Provider: <input className={`form-control`} type="text" id="websiteHostingProvider" value={websiteHostingProvider} onChange={onChange}/></p>}
                          {onboarding.show_website && <p>Website Username: <input className={`form-control`} type="text" id="websiteLogin" value={websiteLogin} onChange={onChange}/></p>}
                          {onboarding.show_website && <p>Website Password: <input className={`form-control`} type="text" id="websitePassword" value={websitePassword} onChange={onChange}/></p>}

                          {onboarding.show_email && <p>Email Service Link: <input className={`form-control`} type="text" id="emailServiceLink" value={emailServiceLink} onChange={onChange}/></p>}
                          {onboarding.show_email && <p>Email Service Username: <input className={`form-control`} type="text" id="emailServiceLogin" value={emailServiceLogin} onChange={onChange}/></p>}
                          {onboarding.show_email && <p>Email Service Password: <input className={`form-control`} type="text" id="emailServicePassword" value={emailServicePassword} onChange={onChange}/></p>}
                        </>
                      )}
                      {(onboarding.reputation && permission.reputation) && (
                        <>
                          {onboarding.show_trip && <p>TripAdvisor Username: <input className={`form-control`} type="text" id="tripAdvisorLogin" value={tripAdvisorLogin} onChange={onChange}/></p>}
                          {onboarding.show_trip && <p>TripAdvisor Password: <input className={`form-control`} type="text" id="tripAdvisorPassword" value={tripAdvisorPassword} onChange={onChange}/></p>}

                          {onboarding.show_yelp && <p>Yelp Username: <input className={`form-control`} type="text" id="yelpLogin" value={yelpLogin} onChange={onChange}/></p>}
                          {onboarding.show_yelp && <p>Yelp Password: <input className={`form-control`} type="text" id="yelpPassword" value={yelpPassword} onChange={onChange}/></p>}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <button type="submit" className={`${classes.ConfigureButton}`} style={menuButtonStyle} onClick={onSubmitAccount}>Save</button>
                </Fade>
              )}

              {eventView && (
                <Fade size={"col-xs-12"} show={true}>
                  <h2>Social Media Events/Holidays</h2>
                  {onboarding.social && (
                    <>
                      {onboarding.january_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.february_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.march_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.april_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.may_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.june_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.july_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.august_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.september_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.october_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.november_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {onboarding.december_dates.map((date, index) => { return <p key={index}>{date}</p>; })}
                      {(onboarding.custom_dates.length > 0 && (onboarding.custom_dates[0].date !== "" && onboarding.custom_dates[0].name !== "")) && (
                        onboarding.custom_dates.map((date, index) => {
                          return <p key={index}>{date.date} - {date.name}</p>;
                        })
                      )}
                    </>
                  )}
                </Fade>
              )}

              {websiteView && (
                <>
                  {(onboarding.form_emails && permission.web_email_forms) && (
                    <>
                      <h2>Website Form Emails</h2>
                      {onboarding.form_emails.general && (<p>General Inquiries: {onboarding.form_emails.general}</p>)}
                      {onboarding.form_emails.webstore && (<p>Shopping Cart: {onboarding.form_emails.webstore}</p>)}
                      {onboarding.form_emails.leads && (<p>Leads: {onboarding.form_emails.leads}</p>)}
                      {onboarding.form_emails.events && (<p>Event RSVP/Tickets: {onboarding.form_emails.events}</p>)}
                    </>
                  )}
                  {(onboarding.social_handles && permission.social_handles) && (
                    <>
                      <h2 style={{ marginTop: "20px" }}>Website Social Handle Links</h2>
                      {onboarding.social_handles.facebook && (<p>Facebook: {onboarding.social_handles.facebook}</p>)}
                      {onboarding.social_handles.instagram && (<p>Instagram: {onboarding.social_handles.instagram}</p>)}
                      {onboarding.social_handles.twitter && (<p>Twitter: {onboarding.social_handles.twitter}</p>)}
                      {onboarding.social_handles.linkedin && (<p>Linkedin: {onboarding.social_handles.linkedin}</p>)}
                      {onboarding.social_handles.pinterest && (<p>Pinterest: {onboarding.social_handles.pinterest}</p>)}
                      {onboarding.social_handles.youtube && (<p>Youtube: {onboarding.social_handles.youtube}</p>)}
                      {onboarding.social_handles.other && (<p>Other: {onboarding.social_handles.other}</p>)}
                    </>
                  )}
                </>
              )}

              {assetsView && (
                <Fade size={"col-xs-12"} show={true}>
                  <h2>Assets</h2>
                  {onboarding.assets && (
                    <div className={classes.Assets}>
                      <p><b>Logo Files:</b> {onboarding.assets.logo.length > 0 ? (
                        <>
                          {onboarding.assets.logo.map((file, index) => {
                            return <a key={index} href={file.location} style={menuButtonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                          })}
                        </>
                      ) : "No Files Uploaded"}</p>

                      <p><b>Mailing List Files:</b> {onboarding.assets.mailing_list.length > 0 ? (
                        <>
                          {onboarding.assets.mailing_list.map((file, index) => {
                            return <a key={index} href={file.location} style={menuButtonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                          })}
                        </>
                      ) : "No Files Uploaded"}</p>

                      <p><b>Branding Guide Files:</b> {onboarding.assets.branding.length > 0 ? (
                        <>
                          {onboarding.assets.branding.map((file, index) => {
                            return <a key={index} href={file.location} style={menuButtonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                          })}
                        </>
                      ) : "No Files Uploaded"}</p>

                      <p><b>Font Files:</b> {onboarding.assets.fonts.length > 0 ? (
                        <>
                          {onboarding.assets.fonts.map((file, index) => {
                            return <a key={index} href={file.location} style={menuButtonStyle} download>{file.filename.length < 12 ? file.filename : `${file.filename.slice(0,12)}...`}</a>;
                          })}
                        </>
                      ) : "No Files Uploaded"}</p>
                    </div>
                  )}
                </Fade>
              )}

              {questionView && (
                <Fade size={"col-xs-12"} show={true}>
                  {(onboarding.questionnaires.social && permission.social_questions) && (
                    <div className={classes.BrandingQuestionnaire}>
                      <p>Social Media Questionnaire:</p>
                      <div className={classes.BrandingQuestionnaireList}>
                      <h6>What accounts do members of your target market follow and what are their top five most visited?</h6>
                      <p>{onboarding.questionnaires.social.one}</p>
                      <h6>Which social networks are they most active on?</h6>
                      <p>{onboarding.questionnaires.social.two}</p>
                      <h6>What emojis do they use?</h6>
                      <p>{onboarding.questionnaires.social.three}</p>
                      <h6>Are they passive scrollers or engaged and involved?</h6>
                      <p>{onboarding.questionnaires.social.four}</p>
                      <h6>
                        Do they <b>want</b> to follow brands that are: Civically engaged? Opinionated and outspoken?
                        Passive and neutral? Engaged in a social impact mission? Champions of the environment?
                      </h6>
                      <p>{onboarding.questionnaires.social.five}</p>
                      <h6>What acronyms do they use?</h6>
                      <p>{onboarding.questionnaires.social.six}</p>
                      <h6>When they&apos;re on the internet what are they doing?</h6>
                      <p>{onboarding.questionnaires.social.seven}</p>
                      <h6>
                        Are they the type that bookmarks blogs to return to weekly or the type that scrolls
                          to unwind at the end of the day??
                      </h6>
                      <p>{onboarding.questionnaires.social.eight}</p>
                      <h6>What is their current point of view on your industry or product?</h6>
                      <p>{onboarding.questionnaires.social.nine}</p>
                      <h6>What hashtags do they follow and engage with?</h6>
                      <p>{onboarding.questionnaires.social.ten}</p>
                      <h6>What are their psychographics?</h6>
                      <p>{onboarding.questionnaires.social.eleven}</p>
                      <h6>What factors do members of your target market have in common?</h6>
                      <p>{onboarding.questionnaires.social.twelve}</p>
                      <h6>What motivates them to take action? (Any action)?</h6>
                      <p>{onboarding.questionnaires.social.thirteen}</p>
                      <h6>What does &quot;fulfillment&quot; look like for them?</h6>
                      <p>{onboarding.questionnaires.social.fourteen}</p>
                      <h6>Do they have any major fears or anxieties that drive their behavior?</h6>
                      <p>{onboarding.questionnaires.social.fifteen}</p>
                      </div>
                    </div>
                  )}
                  {(onboarding.questionnaires.branding && permission.branding_questions) && (
                    <div className={classes.BrandingQuestionnaire}>
                      <p>Branding Questionnaire:</p>
                      <div className={classes.BrandingQuestionnaireList}>
                        <h6>What is the name of your company?</h6>
                        <p>{onboarding.questionnaires.branding.one}</p>
                        <h6>Which words in your company name do you want to emphasize?</h6>
                        <p>{onboarding.questionnaires.branding.two}</p>
                        <h6>Do you want to include your tagline in your logo?</h6>
                        <p>{onboarding.questionnaires.branding.three}</p>
                        <h6>What is your company&apos;s field of business?</h6>
                        <p>{onboarding.questionnaires.branding.four}</p>
                        <h6>What age group of customers are you targeting?</h6>
                        <p>{onboarding.questionnaires.branding.five}</p>
                        <h6>What distinguishes you from other companies in this field?</h6>
                        <p>{onboarding.questionnaires.branding.six}</p>
                        <h6>Who are your competitors and what do you think about their logos?</h6>
                        <p>{onboarding.questionnaires.branding.seven}</p>
                        <h6>What is your overall business aesthetic?</h6>
                        <p>{onboarding.questionnaires.branding.eight}</p>
                        <h6>What colors do you want and not want in your logo?</h6>
                        <p>{onboarding.questionnaires.branding.nine}</p>
                        <h6>What logo layout do you prefer?</h6>
                        <p>{onboarding.questionnaires.branding.ten}</p>
                        <h6>Graphically speaking, what do you want your logo to contain?</h6>
                        <p>{onboarding.questionnaires.branding.eleven}</p>
                        <h6>What do you want to do with your logo?</h6>
                        <p>{onboarding.questionnaires.branding.twelve}</p>
                        <h6>If you have an existing logo, do you want to keep any elements of it?</h6>
                        <p>{onboarding.questionnaires.branding.thirteen}</p>
                        <h6>Which companies have your favorite logos?</h6>
                        <p>{onboarding.questionnaires.branding.fourteen}</p>
                        <h6>Do you have any other ideas of your own logo?</h6>
                        <p>{onboarding.questionnaires.branding.fifteen}</p>
                      </div>
                    </div>
                  )}
                </Fade>
              )}

              {(!onboarding.social && !onboarding.webdev && !onboarding.seo && !onboarding.branding && !onboarding.reputation) && (
                <Fade size={"col-xs-12"} show={true}>
                  <p style={{ color: "red"}}>This Client has not started onboarding.</p>
                </Fade>
              )}
          </div>
        </div>

        <div className={`row`} style={{ margin: "0px"}}>
          <div className={`col-12 ${classes.BackButtonCol}`}>
            <button onClick={goBack} style={menuButtonStyle} className={`${classes.Button}`}>Back</button>
          </div>
        </div>
        </div>
      )}
    </Layout>
  );
}

export default withRouter(ClientDetails);

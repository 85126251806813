import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";
import Layout from "../../components/layout/layout";
import classes from "./billing.module.scss";

const Billing = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);

  const [contactName, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [extraEmails, setExtraEmails] = useState({ val: []});
  const [website, setWebsite] = useState("");

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const onChange = (e) => {
    switch (e.target.id) {
      case 'contactName':
        setContactName(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'website':
        setWebsite(e.target.value);
        break;
      case 'address':
        setAddress(e.target.value);
        break;
      case 'city':
        setCity(e.target.value);
        break;
      case 'state':
        setState(e.target.value);
        break;
      case 'zip':
        setZip(e.target.value);
        break;
      default:
        break;
    }
  }

  function createInputs() {
    return extraEmails.val.map((el, i) =>
      <div key={i} className={`row ${classes.ExtraEmails}`}>

        <div className={`col-10`} style={{ margin: "0px" }}>
          <input
            onChange={handleChange.bind(i)}
            value={el||''}
            type="text"
            placeholder={`Email ${i + 2}`}
            className={`form-control ${classes.textInput}`}
          />
        </div>
        <div className={`col-2 align-self-center`} style={{ margin: "0px" }}>
          <button style={{ backgroundColor: "#8c0000", color: "white" }} onClick={removeClick.bind(i)} className={["btn", "right", classes.RemoveButton].join(" ")}>
            <i className="material-icons align-middle">close</i>
          </button>
        </div>
      </div>
    );
  }

  function handleChange(event) {
    let values = [...extraEmails.val];
    values[this] = event.target.value;
    setExtraEmails({ val: values});
  }

  const addClick = (e) => {
    e.preventDefault();
    setExtraEmails({ val: [...extraEmails.val, '']});
  }

  const removeClick = (e) => {
    e.preventDefault();
    let values = [...extraEmails.val];
    values.splice(this,1);
    setExtraEmails({ val: values });
  }

  async function onSubmit(e) {
    e.preventDefault();
    let billingPhone = phone.replace(/\D/g, "");
    let params = {
      billing_name: contactName,
      billing_phone: billingPhone,
      billing_email: email,
      billing_extra_email: extraEmails,
      billing_website: website,
      billing_address: address,
      billing_city: city,
      billing_state: state,
      billing_zip: zip
    };
    try {
      await axios.put('/api/clients/billing', params);
      props.history.push('/dashboard');
    } catch(err) {
      //console.log(err);
    }
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={true}>
      <div className={`row ${classes.content}`}>
        <div className={`col-7 ${classes.form}`}>
          <h1>Billing Information</h1>
          <form onSubmit={onSubmit}>
            <input type="text" id="contactName" onChange={onChange} value={contactName} placeholder="Contact Name" className={`form-control ${classes.textInput}`}/>
            <input type="text" id="phone" onChange={onChange} value={phone} placeholder="Contact Phone" className={`form-control ${classes.textInput}`}/>

            <div className={`row ${classes.ExtraEmails}`}>
              <div className={`col-10`}>
              <input type="email" id="email" onChange={onChange} value={email} placeholder="Contact Email" className={`form-control ${classes.textInput}`}/>
              </div>
              <div className={`col-2 align-self-center`}>
                <button type="button" style={{ backgroundColor: `${theme.primary_color}`, color: `${theme.primary_text_color}` }} onClick={addClick} className={`${classes.AddButton}`}>
                 <i className="material-icons align-middle">add</i>
                </button>
              </div>
            </div>

            {createInputs()}
            <input type="text" id="website" onChange={onChange} value={website} placeholder="Business Website" className={`form-control ${classes.textInput}`}/>
            <input type="text" id="address" onChange={onChange} value={address} placeholder="Business Address" className={`form-control ${classes.textInput}`}/>
            <div className={`row`}>
              <div className={`col-6`}>
                <input style={{ margin: "0px" }} type="text" id="city" onChange={onChange} value={city} placeholder="City" className={`form-control ${classes.textInput}`}/>
              </div>
              <div className={`col-6`}>
                <input style={{ margin: "0px" }} type="text" id="state" onChange={onChange} value={state} placeholder="State" className={`form-control ${classes.textInput}`}/>
              </div>
            </div>
            <input type="text" id="zip" onChange={onChange} value={zip} placeholder="Zip Code" className={`form-control ${classes.textInput}`}/>
            <button onClick={onSubmit} className={classes.SubmitButton} style={{ backgroundColor: `${theme.primary_color}`, color: `${theme.primary_text_color}` }} type="button">Submit</button>
          </form>
        </div>
        <div className={`col-5 ${classes.media}`}>
          <div className={classes.picture}>Picture or Video</div>
        </div>
      </div>
    </Layout>
  );
}

export default Billing;

import React from 'react';

import classes from './spinner.module.scss';

const spinner = (props) => {
  const {color, width, height} = props;

  return (
    <div style={{ border: `5px solid #f3f3f3`, borderTop: `5px solid ${color}`, width: `${width}`, height: `${height}` }} className={classes.Spinner}></div>
  );
}

export default spinner;

import React from 'react';
import { withRouter, Link } from "react-router-dom";
import classes from "./header.module.scss";

const Header = (props) => {
  const {
    theme,
    showSidebar,
    username,
    logout,
    notificationClick
  } = props;
  let displayName = "";

  const buttonStyle = {
    backgroundColor: `white`,
    color: `black`
  };

  if (username) {
    displayName = <p className={classes.WelcomeText}>Welcome, <b>{username.split(" ")[0]}</b></p>;
  }

  const onClick = () => {
    props.history.push("/trial");
  }

  const homeClick = () => {
    if (!username) props.history.push("/");
    else props.history.push("/dashboard");
  }

  return(
    <div className=''>
      {username && (
        <div className='d-none d-md-block '>
            <div style={{ left: `${showSidebar?"50px":"0px"}`, width: `${showSidebar?"calc(100% - 50px)":"100%"}` }} className={`${classes.header} row`}>
                <div className={`col-md-9 ${classes.logoCol} `}>
                    <img onClick={homeClick} className={`${classes.logo}`} src={theme.thumbnail_logo.location} alt="logo" />
                    <>{displayName}</>
                </div>

                <div className={`col-md-3 ${classes.buttonCol}`}>
                    <Link to="/"><p onClick={logout} className={`${classes.logoutButton}`}>Log Out</p></Link>
                    <button style={buttonStyle} className={`${classes.notificationButton}`} onClick={notificationClick}>
                    <span className="material-icons align-middle">notifications</span>
                    {props.notificationCount > 0 && (
                        <div className={`${classes.notificationBadge}`}>{props.notificationCount}</div>
                    )}
                    </button>
                </div>
            </div>
        </div>
      )}
      {!username && (
        <div style={{ left: `${showSidebar?"50px":"0px"}`, width: `${showSidebar?"calc(100% - 50px)":"100%"}` }} className={`${classes.header} row`}>
          <div className={`col ${classes.logoCol} `}>
            <img onClick={homeClick} className={`${classes.logo}`} src={theme.thumbnail_logo.location} alt="logo" />
          </div>
          <div className={`col ${classes.linksCol}`}>
            <Link to="/login"><p>Log In</p></Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Header);

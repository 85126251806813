import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import days from 'dayjs';
import { withRouter } from "react-router-dom";
import Layout from "../../../components/layout/layout";
import Table from "../../../components/table/table";
import classes from './employees.module.scss';
import OptionsModal from "./modals/optionsModal";

const EmployeeList = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [employees, setEmployees] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showOptions, setShowOptions] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [reload, setReload] = useState(true);

  const employeeColumns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Department',
      accessor: 'department'
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'Phone',
      accessor: 'phone',
      Cell: ({value}) => { return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`}
    },
    {
      Header: 'Hire Date',
      accessor: 'date',
      Cell: ({value}) => { return days(value).format('MM/DD/YYYY')}
    },
    {
      Header: 'Options',
      accessor: '_id',
      Cell: ({value}) => { return <button style={buttonStyle} className={classes.OptionsButton} onClick={(e) => optionsClick(e, value)}><span className={`material-icons align-middle`}>more_horiz</span></button>}
    }
  ];

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  useEffect(() => {
    if (props.match.path === "/employees/archive") setShowArchive(true);
    else setShowArchive(false);
  }, [props.match.path])

  useEffect(() => {
    if (reload) {
      let archive = props.match.path === "/employees/archive" ? "true" : false;
      axios.get(`/api/employees/manager/${archive}`)
        .then(response => {
          setEmployees(response.data);
          setLoaded(true);
          setReload(false);
        })
        .catch(err => {
          if (err.response.status === 404) {
            setErrorMessage("No Employees Found");
            setEmployees([]);
            setLoaded(true);
          } else {
            setErrorMessage("Something went wrong!");
            setLoaded(true);
          }
        })
    }
  }, [reload]);

  const goBack = (e) => {
    e.preventDefault();
    props.history.push("/dashboard");
  }

  const onClick = (e, row) => {
    props.history.push(`/employees/${row.original._id}`);
  }

  const optionsClick = (e, id) => {
    setShowOptions(true);
    setSelectedEmployee(id);
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>

      <OptionsModal
        show={showOptions}
        setShow={setShowOptions}
        employee={selectedEmployee}
        setEmployee={setSelectedEmployee}
        showArchive={showArchive}
        setReload={setReload}
      />

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10`}>
          <h1 className={classes.Heading}>Employees</h1>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-10 ${classes.TableCol}`}>
          <Table data={employees} columns={employeeColumns} onClick={onClick} />
          <p className={classes.error}>{errorMessage}</p>
        </div>
      </div>

      <div className={`row justify-content-center`} style={{ margin: "0px" }}>
        <div className={`col-xs-12 col-sm-4 ${classes.ButtonCol}`}>
          <button
            style={{
              backgroundColor: `${theme.primary_color}`
            }}
            onClick={goBack}
            className={`${classes.Button}`}
          >Back</button>
        </div>
      </div>

    </Layout>
  );
}

export default withRouter(EmployeeList);

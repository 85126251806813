import { SET_THEME } from "./types";

export const setTheme = theme => {
  return {
    type: SET_THEME,
    payload: theme
  };
};

export const resetTheme = () => dispatch => {
  dispatch(setTheme({
    primary_color: "#ddd",
    accent_color: "#ddd",
    thumbnail_logo: {
        filename: "default_logo.jpg",
        location: "https://jusb-honeypot.s3.us-east-2.amazonaws.com/default_toolbar_logo.jpg"
    },
    homepage_logo: {
        filename: "default_logo.jpg",
        location: "https://jusb-honeypot.s3.us-east-2.amazonaws.com/default_homepage_logo.jpg"
    }
  }));
}

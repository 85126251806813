import { SET_CHANGES, RESET_CHANGES } from "./types";

const initialState = {
  changes: []
};

export default function StateFunction(state = initialState, action) {
  switch (action.type) {
    case SET_CHANGES:
      return {
        ...state,
        changes: action.payload
      };
    case RESET_CHANGES:
      return {
        ...state,
        changes: []
      };
    default:
      return state;
  }
}

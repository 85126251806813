import React, { useState, useEffect } from 'react';
import axios from "axios";
import dayjs from 'dayjs';
import { withRouter } from 'react-router-dom';
import classes from "./billing.module.scss";
import Fade from "../../../components/layout/fade/fade";
import Spinner from "../../../components/layout/spinner/spinner";

const Billing = (props) => {
  const {
    theme,
    user
  } = props;
  const [subscription, setSubscription] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const plans = {
    "price_1KcEHnASyB21SJjjTVZmymID": {
      name: "Solo Plan",
      price: "$1/day"
    },
    "price_1KcEHnASyB21SJjjVN7NfDXg": {
      name: "Solo Plan",
      price: "$10/month"
    },
    "price_1KcEHnASyB21SJjjRegOZXHk": {
      name: "Solo Plan",
      price: "$100/year"
    },
    "price_1KjSc6ASyB21SJjjrS7B3Ezj": {
      name: "Agency Plan",
      price: "$20/month"
    },
    "price_1KjSc6ASyB21SJjjM3xd2HoG": {
      name: "Agency Plan",
      price: "$200/year"
    }
  };

  useEffect(() => {
    axios.get("/api/payment/manager")
      .then(response => {
        //console.log(response.data);
        setSubscription(response.data);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
        setSubscription("error");
        setLoaded(true);
      })
  }, [])

  const buttonStyle = {
    backgroundColor: `${theme.primary_color}`,
    color: `${theme.primary_text_color}`
  };

  async function manageBilling(e) {
    try {
      let params = {
        route: "/settings/billing"
      };
      let portalUrl = await axios.post("/api/payment/create-portal-session", params);
      window.location.assign(portalUrl.data);
    } catch(err) {
      //console.log(err);
    }
  }

  let planName = "";
  let planPrice = "";
  let cardBrand = "";
  let nextBillingDate = "";

  if (subscription) {
    planName = plans[subscription.price_id].name;
    planPrice = plans[subscription.price_id].price;
    cardBrand = user.payment_brand.charAt(0).toUpperCase() + user.payment_brand.slice(1);
    nextBillingDate = dayjs.unix(subscription.current_period_end).format("MMMM DD, YYYY");
  }


  return (
    <Fade show={true}>
      {!loaded && (
        <div className={classes.spinner}>
          <Spinner color={theme.primary_color} width={"120px"} height={"120px"} />
        </div>
      )}
      {loaded && (
        <div className={`row`}>
          <div className={`col ${classes.SettingsContent}`}>

            <div className={`row ${classes.BillingModule}`}>
              <div className={`col-9`}>
                <h1>Billing &amp; Subscription</h1>
              </div>
            </div>

            <div className={`row ${classes.BillingModule}`}>
              <div className={`col-7`}>
                <h2>Subscription Details</h2>
                <p>{planName}: <b>{planPrice}</b></p>
                <p>Next Billing Date: <b>{nextBillingDate}</b></p>
                <h2>Payment Method</h2>
                <p>{cardBrand} <i>ending with:</i> {user.payment_last4}</p>
              </div>
              <div className={`col-2`}>
                <button type="button" style={buttonStyle} onClick={manageBilling}>Manage</button>
              </div>
            </div>

          </div>
        </div>
      )}
    </Fade>
  );
}

export default withRouter(Billing);

import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/layout";
import Sidebar from "./sidebar/sidebar";
import ChatHeading from "./conversation/heading";
import Messages from "./conversation/messages";
import MessageInput from "./conversation/input";
import { SocketContext } from "../../contexts/SocketContext";
import { MESSAGE_SENT, MESSAGE_RECIEVED, CONVERSATION_SELECTED } from "../../Events";
import classes from "./messageLayout.module.scss";
import AttachmentModal from './modals/attachment';
import EmployeeViewModal from "./modals/employeeView";
import GroupModal from "./modals/createGroup";
import prices from "../../assets/prices";

const MessageLayout = (props) => {
  //General assets
  const socket = useContext(SocketContext)[0];
  const user = useSelector(state => state.auth.user);
  const theme = useSelector(state => state.themeReducer.theme);
  const [loaded, setLoaded] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [conversationId, setConversationId] = useState(props.match.params.id);
  const [tier, setTier] = useState("solo");

  //Messaging options
  const [directMessageUsers, setDirectMessageUsers] = useState([]);
  const [conversationList, setConversationList] = useState([]);

  //Messages
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [groupCount, setGroupCount] = useState(0);

  //Modal Controls
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [showEmployeeViewModal, setShowEmployeeViewModal] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  //--------------------------General Setup-----------------------------------

  //Get all direct message users and conversations
  useEffect(() => {
    axios.get('/api/conversations/userlist')
      .then(userListResponse => {
        //console.log(userListResponse.data);
        let directMessageUsers = userListResponse.data.users;
        let conversations = userListResponse.data.conversations;

        // Adding role to direct conversations
        let newConversations = [];
        for (let conversation of conversations) {
          if (conversation.type === "Direct") {
            let userId = conversation.user_ids.filter((id) => id !== user.id)[0];
            for (const directUser of directMessageUsers) {
              if (directUser._id === userId) {
                newConversations.push({...conversation, role: directUser.role });
              }
            }
          } else {
            newConversations.push(conversation);
            setGroupCount(groupCount + 1);
          }
        }

        setDirectMessageUsers(directMessageUsers);
        setConversationList(newConversations);
        //console.log(userListResponse.data.conversations);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err)
      });
  }, [user]);

  //Creating Message socket events
  useEffect(() => {
    if (socket !== null && firstLoad) {
      if (conversationList.length > 0) {
        for (let conversation of conversationList) {
          const messageEvent = `${MESSAGE_RECIEVED}-${conversation._id}`;
      		//const typingEvent = `${TYPING}-${conversation._id}`;

      		//socket.on(typingEvent, updateTypingInChat(conversation._id));
      		socket.on(messageEvent, (message) => {
            setNewMessage(message);
          });
        }
      }
    }
  }, [conversationList, socket]);

  //Adding a new message to a conversation
  useEffect(() => {
    if (newMessage !== "" && selectedConversation) {
      if (selectedConversation._id === newMessage.conversation_id) {
        let newMessageList = [...messageList];
        newMessageList.push(newMessage);
        setMessageList(newMessageList);
        setNewMessage("");
      } else {
        //console.log("Here");
        // Increment unread_count for conversation
        let newConversationList = [];
        for (const conversation of conversationList) {
          if (conversation._id === newMessage.conversation_id) {
            //console.log(conversation.unread_count+1);
            newConversationList.push({...conversation, unread_count: conversation.unread_count+1});
          } else newConversationList.push(conversation);
        }
        setConversationList(newConversationList);
        setNewMessage("");
      }
    }
  }, [newMessage, messageList, selectedConversation]);

  //Setting selectedConversation in the backend for notifications
  useEffect(() => {
    if (socket !== null && selectedConversation !== null) {
      socket.emit(CONVERSATION_SELECTED, user.id, selectedConversation);
    }
  }, [selectedConversation, socket, user]);

  //For message notification clicks
  useEffect(() => {
    if (firstLoad || (props.match.params.id !== conversationId)) {
      if (props.match.params.id) {
        conversationList.forEach((conversation) => {
          if (conversation._id === props.match.params.id) {
            setSelectedConversation(conversation);
            getMessages(conversation._id);
            setFirstLoad(false);
            setConversationId(props.match.params.id);
          }
        });
      }
    } else {
      setFirstLoad(false);
    }
  }, [conversationList, props.match.params.id]);

  useEffect(() => {
      axios.get("/api/payment/manager")
        .then(response => {
          if (response.data.price_id == prices.solo_monthly || response.data.price_id == prices.solo_yearly)
              setTier("solo");
          else if (response.data.price_id == prices.agency_monthly || response.data.price_id == prices.agency_yearly)
              setTier("agency");

        })
        .catch(err => {
          //console.log(err);
          setTier("error");
        })
  }, [])

  const goBack = (e) => {
    setSelectedConversation(null);
    socket.emit(CONVERSATION_SELECTED, user.id, null);
    props.history.push("/dashboard");
  }

  const selectConversation = (conversation_id) => {
    conversationList.forEach((conversation) => {
      if (conversation._id === conversation_id) {
        setSelectedConversation(conversation);
        getMessages(conversation._id);
        props.history.push(`/messages/${conversation._id}`);
      }
    });
  }

  const createConversation = (params) => {
    axios.post('/api/conversations/', params)
      .then(response => {
        //console.log(response.data);
        const newConversationList = [...conversationList];
        newConversationList.push(response.data);
        setConversationList(newConversationList);
        return(response.data);
      })
      .catch(err => {
        //console.log(err);
        return null;
      });
  }

  const getMessages = (conversation_id) => {
    axios.get(`/api/messages/${conversation_id}`)
      .then(response => {
        let newConversationList = [];
        for (const conversation of conversationList) {
          if (conversation._id === conversation_id) {
            newConversationList.push({...conversation, unread_count: 0});
          } else newConversationList.push(conversation);
        }
        setConversationList(newConversationList);
        setMessageList(response.data);
        if (window.innerWidth <= 768) toggleSidebar();
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const sendMessage = (e, message, setMessage) => {
    e.preventDefault();
    //console.log("Selected Conversation: ", selectedConversation);
    if (selectedConversation && message !== "") {
      const params = {
        conversation_id: selectedConversation._id,
        message: message,
        type: 'text'
      }
      axios.post('/api/messages/text', params)
        .then(response => {
          //console.log("Response: ", response.data);
          setMessage("");
          socket.emit(MESSAGE_SENT, selectedConversation, response.data);
        })
        .catch(err => {
          //console.log(err);
        });
    } else if (message === "") {
      //console.log("Please type a message");
    } else {
      //console.log("No conversation selected");
    }
  }

  const addGroup = (e) => {
    if (groupCount >= 10 && tier == "solo")
      alert("You can't add more than 10 groups on the Solo tier. Upgrade to Agency to add more.");
    else
      setShowCreateGroup(true);
  }

  //-----------------------Add/Remove user to a group message--------------------
  const [addUserToGroupError, setAddUserToGroupError] = useState("");

  const addUserToGroupClick = (e, newUser, setUser) => {
    if (newUser === "") {
      setAddUserToGroupError("Select a User");
    } else {
      let userToAdd = null;
      directMessageUsers.forEach((user) => {
        if (user.name === newUser) {
          userToAdd = user;
        }
      });
      if (userToAdd) {
        if (!selectedConversation.user_ids.includes(userToAdd._id.toString())) {
          const newGroupIds = [...selectedConversation.user_ids];
          newGroupIds.push(userToAdd._id.toString());
          const newGroupNames = [...selectedConversation.display_names];
          newGroupNames.push(newUser);
          const newReadDates = [...selectedConversation.read_dates];
          newReadDates.push({ id: userToAdd._id.toString(), date: new Date() });
          const params = {
            id: selectedConversation._id,
            user_ids: newGroupIds,
            display_names: newGroupNames,
            read_dates: newReadDates
          };
          //Update conversation
          axios.put('/api/conversations/add/user', params)
            .then(response => {
              setSelectedConversation(response.data);
              let replaceIndex = null;
              conversationList.forEach((conversation, index) => {
                if (conversation._id === response.data._id) {
                  replaceIndex = index;
                }
              });
              if (replaceIndex) {
                let newConversationList = [...conversationList];
                newConversationList[replaceIndex] = response.data;
                setConversationList(newConversationList);
              }
              setUser("");
              setAddUserToGroupError("");
            })
            .catch(err => {
              //console.log(err);
            });
        } else {
          setUser("");
        }
      } else {
        setAddUserToGroupError("User not found");
      }
    }
  }

  const removeGroupMessageUser = (e, userName) => {
    let userToRemove = null;
    directMessageUsers.forEach((user) => {
      if (user.name === userName) {
        userToRemove = user;
      }
    });
    if (userToRemove) {
      let params = {
        id: selectedConversation._id,
        user_id: userToRemove._id,
        name: userName
      };
      axios.put('/api/conversations/remove/user', params)
        .then(response => {
          let newConversations = [...conversationList];
          let replaceIndex = null;
          conversationList.forEach((conversation, index) => {
            if (conversation._id === response.data._id) {
              replaceIndex = index;
            }
          });
          setSelectedConversation(response.data);
          newConversations[replaceIndex] = response.data;
          setConversationList(newConversations);
        })
        .catch(err => {
          //console.log(err);
        });
    }
  }

  //-------------------------Leave Group Message--------------------------
  const leaveGroupMessage = (e) => {
    let params = {
      id: selectedConversation._id,
      user_id: user.id,
      name: user.name
    };
    axios.put('/api/conversations/remove/user', params)
      .then(response => {
        let newConversations = [...conversationList];
        let removeIndex = null;
        conversationList.forEach((conversation, index) => {
          if (conversation._id === selectedConversation._id) {
            removeIndex = index;
          }
        });
        newConversations.splice(removeIndex, 1);
        setConversationList(newConversations);
        setSelectedConversation(null);
        setMessageList([]);
        props.history.push('/messages');
      })
      .catch(err => {
        //console.log(err);
      });
  }

  //-----------------------Delete Group Message----------------------------
  const deleteGroupConversation = (e, id) => {
    axios.delete(`/api/conversations/${id}`)
      .then(response => {
        props.history.push('/messages');
        setSelectedConversation(null);
        setMessageList([]);
        let newConversations = [...conversationList];
        let removeIndex = null;
        conversationList.forEach((conversation, index) => {
          if (conversation._id === id) {
            removeIndex = index;
          }
        });
        newConversations.splice(removeIndex, 1);
        setConversationList(newConversations);
      })
      .catch(err => {
        //console.log(err);
      });
  }

  const showSidebar = (e) => {
    setCollapsed(!collapsed);
  }

  //---------------------------Messages scroll----------------------------

  const messageContainer = useRef(null);

  const scrollToMyRef = () => {
    //console.log("Scroll height: ", messageContainer.current.scrollHeight);
    //console.log("Client height: ", messageContainer.current.clientHeight);
    const scroll = messageContainer.current.scrollHeight - messageContainer.current.clientHeight;
    messageContainer.current.scrollTo(0, scroll);
  }

  useEffect(() => {
    window.scrollTo(0, 1);
    if (loaded) scrollToMyRef();
    document.body.style.overflow = "hidden"
  }, [messageList, messageContainer, loaded]);

  return (
    <Layout loaded={loaded} showSidebar={false} subscription={true}>
      <div className={`row ${classes.Content}`} style={{ margin: "0px" }}>

          {!collapsed && (
        <div className={`${classes.SidebarCol}`}>
          <Sidebar
            user={user}
            theme={theme}
            goBack={goBack}
            selected={selectedConversation}
            select={selectConversation}
            conversations={conversationList}
            createGroupToggle={(e) => addGroup()}
            employeeViewModal={(e) => setShowEmployeeViewModal(true)}
          />
        </div>
          )}

        <div className={`${classes.ConversationCol}`}>
          <div className={` ${collapsed ? classes.ConversationRowExpand : classes.ConversationRow}`}>
            <div className={`${classes.HeadingCol}`}>
              <ChatHeading
                user={user}
                theme={theme}
                conversation={selectedConversation}
                addUser={addUserToGroupClick}
                userList={directMessageUsers}
                addUserToGroupError={addUserToGroupError}
                setAddUserError={setAddUserToGroupError}
                leaveGroupMessage={leaveGroupMessage}
                removeGroupMessageUser={removeGroupMessageUser}
                deleteGroupConversation={deleteGroupConversation}
                showSidebar={showSidebar}
              />
            </div>
            <div ref={messageContainer} className={`${collapsed ? classes.MessagesColExpand : classes.MessagesCol}`}>
              <Messages
                messages={messageList}
                user={user}
                theme={theme}
              />
            </div>
            <div className={`${collapsed ? classes.InputColExpand : classes.InputCol}`}>
              <MessageInput
                theme={theme}
                sendMessage={sendMessage}
                showModal={(e) => setShowAttachmentModal(true)}
              />
            </div>
          </div>
        </div>

        {/* File attachment modal */}
        <AttachmentModal
          show={showAttachmentModal}
          setShow={setShowAttachmentModal}
          user={user}
          theme={theme}
          socket={socket}
          conversation={selectedConversation}
        />

        {/* Create Group Modal */}
        <GroupModal
          show={showCreateGroup}
          setShow={setShowCreateGroup}
          user={user}
          theme={theme}
          createConversation={createConversation}
          messageUsers={directMessageUsers}
        />

        {/* EmployeeView Modal */}
        <EmployeeViewModal
          show={showEmployeeViewModal}
          setShow={setShowEmployeeViewModal}
          theme={theme}
          setConversation={setSelectedConversation}
          messageUsers={directMessageUsers}
        />

      </div>

    </Layout>
  );
}

export default withRouter(MessageLayout);

import React from 'react';
import { withRouter } from "react-router-dom";
import Layout from "../../components/layout/layout";
import Navbar from "../../components/navbar/navbar";
import classes from "./landing.module.scss";

const Landing = (props) => {

  const onClick = () => {
    props.history.push("/trial");
  }

  return(
    <Layout loaded={true} showSidebar={false} subscription={false}>
      <Navbar/>
      <div className={`row ${classes.content}`}>
        <div className={`col-7 ${classes.text}`}>
          <h1>Keep your team<br/> <span>organized</span> all in <br/> one space.</h1>
          <p>The all-in-one must have software to keep your business running smoothly.</p>
          <button onClick={onClick}>Get Started</button>
        </div>
        <div className={`col-5 ${classes.media}`}>
          <div className={classes.picture}>Picture or Video</div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Landing);

import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import classes from "./form.module.scss";
import Layout from "../../components/layout/layout";

const SocialForm = (props) => {
  const theme = useSelector(state => state.themeReducer.theme);
  const [loaded, setLoaded] = useState(false);

  const [question1, setquestion1] = useState("");
  const [question2, setquestion2] = useState("");
  const [question3, setquestion3] = useState("");
  const [question4, setquestion4] = useState("");
  const [question5, setquestion5] = useState("");
  const [question6, setquestion6] = useState("");
  const [question7, setquestion7] = useState("");
  const [question8, setquestion8] = useState("");
  const [question9, setquestion9] = useState("");
  const [question10, setquestion10] = useState("");
  const [question11, setquestion11] = useState("");
  const [question12, setquestion12] = useState("");
  const [question13, setquestion13] = useState("");
  const [question14, setquestion14] = useState("");
  const [question15, setquestion15] = useState("");

  useEffect(() => {
    axios.get("/api/onboarding/forms")
      .then(response => {
        //console.log(response.data);
        let questions = response.data.questionnaires.social;
        setquestion1(questions.one);
        setquestion2(questions.two);
        setquestion3(questions.three);
        setquestion4(questions.four);
        setquestion5(questions.five);
        setquestion6(questions.six);
        setquestion7(questions.seven);
        setquestion8(questions.eight);
        setquestion9(questions.nine);
        setquestion10(questions.ten);
        setquestion11(questions.eleven);
        setquestion12(questions.twelve);
        setquestion13(questions.thirteen);
        setquestion14(questions.fourteen);
        setquestion15(questions.fifteen);
        setLoaded(true);
      })
      .catch(err => {
        //console.log(err);
      });
  }, [])

  const onChange = (e) => {
    switch (e.target.id) {
      case 'question1':
        setquestion1(e.target.value);
        break;
      case 'question2':
        setquestion2(e.target.value);
        break;
      case 'question3':
        setquestion3(e.target.value);
        break;
      case 'question4':
        setquestion4(e.target.value);
        break;
      case 'question5':
        setquestion5(e.target.value);
        break;
      case 'question6':
        setquestion6(e.target.value);
        break;
      case 'question7':
        setquestion7(e.target.value);
        break;
      case 'question8':
        setquestion8(e.target.value);
        break;
      case 'question9':
        setquestion9(e.target.value);
        break;
      case 'question10':
        setquestion10(e.target.value);
        break;
      case 'question11':
        setquestion11(e.target.value);
        break;
      case 'question12':
        setquestion12(e.target.value);
        break;
      case 'question13':
        setquestion13(e.target.value);
        break;
      case 'question14':
        setquestion14(e.target.value);
        break;
      case 'question15':
        setquestion15(e.target.value);
        break;
      default:
        break;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const params = {
      questions: {
        one: question1,
        two: question2,
        three: question3,
        four: question4,
        five: question5,
        six: question6,
        seven: question7,
        eight: question8,
        nine: question9,
        ten: question10,
        eleven: question11,
        twelve: question12,
        thirteen: question13,
        fourteen: question14,
        fifteen: question15
      }
    };
    axios.put('/api/onboarding/social', params)
      .then(response => {
        //console.log(response.data);
        props.history.goBack();
      })
      .catch(err => {
        //console.log(err);
      });
  }

  return (
    <Layout loaded={loaded} showSidebar={true} subscription={true}>
      <div>
        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-sm-10 col-md-8 ${classes.Heading}`}>
            <h1>15 Questions to Help Brands <br/>Develop <b>Personas</b> Built for Social Media</h1>
          </div>
        </div>

        <div className={`row justify-content-center`} style={{ margin: "0px" }}>
          <div className={`col-sm-12 col-md-10 col-lg-9`}>
            <form className={classes.Form} noValidate>

              <div className={`${classes.Question}`}>
                <h2>What accounts do members of your target market follow and what are their top five most visited?</h2>
                <input id="question1" value={question1} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>Which social networks are they most active on?</h2>
                <input id="question2" value={question2} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What emojis do they use?</h2>
                <input id="question3" value={question3} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>Are passive scrollers or engaged and involved?</h2>
                <input id="question4" value={question4} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>
                  Do they <b>want</b> to follow brands that are: Civically engaged? Opinionated and outspoken?
                  Passive and neutral? Engaged in a social impact mission? Champions of the environment?
                </h2>
                <input id="question5" value={question5} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What acronyms do they use?</h2>
                <input id="question6" value={question6} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>When they&apos;re on the internet what are they doing?</h2>
                <input id="question7" value={question7} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>
                  Are they the type that bookmarks blogs to return to weekly or the type that scrolls
                  to unwind at the end of the day?
                </h2>
                <input id="question8" value={question8} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What is their current point of view on your industry or product?</h2>
                <input id="question9" value={question9} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What hashtags do they follow and engage with?</h2>
                <input id="question10" value={question10} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What are their psychographics?</h2>
                <p>(Personality traits, values, attitudes, interests, lifestyles)</p>
                <input id="question11" value={question11} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What factors do members of your target market have in common?</h2>
                <input id="question12" value={question12} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What motivates them to take action? (Any action)</h2>
                <input id="question13" value={question13} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>What does &quot;fulfillment&quot; look like for them?</h2>
                <input id="question14" value={question14} type="text" onChange={onChange} />
              </div>

              <div className={`${classes.Question}`}>
                <h2>Do they have any major fears or anxieties that drive their behavior?</h2>
                <input id="question15" value={question15} type="text" onChange={onChange} />
              </div>

              <div className={`row justify-content-center`}>
                <div className={`col-sm-5 col-md-5 ${classes.Upload}`}>
                  <button type="button" className={`${classes.Button}`} style={{backgroundColor: `${theme.primary_color}`}} onClick={onSubmit}>Save & Exit</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(SocialForm);
